.cms-subs-feature {
  margin-top: 6rem;
  display: flex;
  padding-bottom: 3.68rem;
  padding-right: 4rem;
  flex-direction: column;
  width: 25%;

  &-title {
    display: flex;
    align-items: center;
    font-size: @h3-font-size;
    line-height: 30px;
    color: @primary-color;
    font-weight: 600;
    padding: 0.375rem 0;
    margin-bottom: 12px;
  }
  .cms-feature-list {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    width: 276px;
    background: @white-color;
    box-shadow: 0px -1px 0px 0px @stroke-color inset;
    &-title {
      font-weight: 500;
      font-size: @h5-font-size;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: @primary-color;
      margin-right: 6px;
    }
    &-subtitle {
      display: flex;
      align-items: center;
      font-size: @h6-font-size;
      line-height: 20px;
      color: @content-text-color;
      font-weight: 400;
    }
  }
}
