.create-group-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 32px);
  background-image: url("/assets/images/import-background.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  .create-group-modal {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 450px;
    height: auto;
    background: @white-color;
    box-sizing: border-box;
    justify-content: center;
    margin: 0 auto;
    align-self: center;
    border: 1px solid @stroke-color;
    border-radius: 8px;
    .ant-checkbox-inner {
      border-radius: 0px;
    }
    .group-list-heading {
      color: @primary-color;
      font-weight: 500;
    }
    &-area {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      width: 100%;
      .create-group-button {
        background: @background-color;
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 200px;
        width: 73px;
        height: 56px;
        margin-bottom: 3rem;
        margin-right: 1.5rem;
        cursor: pointer;
        span {
          display: flex;
          justify-content: center;
          padding-top: 1.2rem;
        }
      }
      .create-group-form {
        width: 100%;
        margin-top: 10px;
        .ant-form-item-label > label {
          font-size: 12px;
        }
        .form-checkbox {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 5px 8px 8px 12px;
          height: 44px;
          background: @background-color;
          border-radius: 8px;
          .checkbox-text {
            color: @content-text-color;
            opacity: 0.5;
            padding-left: 0.2rem;
          }
        }
        .group-add-contact {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding: 8px 16px;
          background: @brand-color;
          border-radius: 8px;
          color: @white-color;
          float: right;
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
}
