.cms-reminder-card {
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background-color: @white-color;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
  &-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    width: inherit;
    border-bottom: 1px solid @stroke-color;
    cursor: pointer;
    &-left {
      display: flex;
      align-items: center;
      max-width: 70%;
      &-contact-avatar {
        margin-right: 12px;
      }
      &-contact-info {
        display: flex;
        flex-direction: column;
        max-width: inherit;
      }
    }
    &-right {
      display: flex;
    }
  }
  &-bottom-section {
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    width: inherit;
    align-items: center;
    &-reminder-info {
      .ant-tag {
        display: flex;
        align-items: center;
        border-radius: 0px;
        border: none;
        background-color: @background-color;
        padding: 0.25rem 0.5rem;
        font-size: @h5-font-size;
      }
    }
    &-reminder-action {
      .cms-reminder-button {
        border: 0;
        box-shadow: unset;
        padding: 0;
        height: auto;
        color: @text-dark-theme;
        font-weight: 500;
        &-done {
          color: @brand-color;
        }
      }
    }
  }
}