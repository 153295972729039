.reminders-list-wrapper {
  background: @border-color-theme;
  padding: 0.5rem 0.5rem 0 0.5rem;
  .ant-tag {
    border-radius: 0px;
    border: none;
    background-color: @background-color;
    padding: 4px 8px;
  }
}
