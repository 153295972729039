.group-setting {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 32px);
  &-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .group-setting-back-arrow {
      display: flex;
      align-items: center;
    }
    &-arrow {
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      padding-top: 0.1rem;
      color: @content-text-color;
      line-height: 24px;
    }
    &-delete {
      color: @text-dark-theme;
      display: flex;
      align-items: center;
      &-disabled {
        color: @text-dark-theme;
      }
      &-icon {
        margin-right: 8px;
        fill: @text-dark-theme;
        &-disabled {
          margin-right: 8px;
          fill: @text-dark-theme;
        }
      }
    }
    &-delete :hover {
      color: @danger-color;
      &-icon:hover {
        margin-right: 8px;
        fill: @danger-color;
      }
    }
    &-back {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: @h4-font-size;
      line-height: 24px;
      color: @primary-color;
    }
    .cms-group-cta {
      display: flex;
      align-items: center;
      max-height: 24px;
    }
    .cms-leave-group {
      &-btn {
        display: flex;
        align-items: center;
        color: @content-text-color;
        border: 0;
        font-weight: 500;
        font-size: @h5-font-size;
        line-height: 24px;
        svg path {
          fill: @content-text-color;
        }
        &:hover {
          color: @danger-color;
          svg {
            path {
              fill: @danger-color;
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
          }
        }
        .cms-leave-text {
          padding-left: 0.4rem;
        }
      }
    }
  }
  .cms-delete-group {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    &-btn {
      display: flex;
      align-items: center;
      color: @content-text-color;
      border: 0;
      background: @background-color;
      border-radius: 8px;
      svg path {
        fill: @content-text-color;
      }
      &:hover {
        color: @danger-color;
        svg path {
          fill: @danger-color;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
      .cms-leave-text {
        padding-left: 0.4rem;
      }
      &:hover {
        background: rgba(244, 79, 64, 0.1);
      }
    }
  }

  &-title {
    display: flex;
    border-bottom: 1px solid @stroke-color;
    padding: 1.5rem 0 0 0;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .cms-icon-btn-size {
        height: 32px;
        width: 32px;
      }
      .cms-bell-icon {
        svg {
          fill: @brand-color;
        }
      }
    }
    &-space {
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-input-affix-wrapper {
        border: 0;
        border-radius: 0;
        .ant-input {
          border-bottom-style: solid;
          border-bottom-width: 1px;
          width: fit-content;
          border-radius: 0;
          padding: 0 0 4px 0;
          margin-left: 0.6rem;
          margin: 0 24px 0 9.67px;
          font-size: @h4-font-size;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: @h4-font-size;
      font-weight: bold;
      width: inherit;
    }
    &-text {
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
      max-width: 100%;
      .ant-checkbox-inner {
        border-radius: 0px;
      }
      &-name {
        padding-left: 0.5rem;
      }
      &-input {
        color: @brand-color;
        font-size: @h6-font-size;
      }
      .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
    &-checkbox {
      display: flex;
      align-self: flex-start;
      padding-top: 1.313rem;
      padding-bottom: 0.875rem;
      .ant-checkbox-inner {
        border: 1px solid @content-text-color;
        border-radius: 0;
        width: 13.5px;
        height: 13.5px;
      }
      .ant-checkbox + span {
        color: @content-text-color;
        font-size: @h6-font-size;
      }
    }
  }
  &-total-contacts {
    padding-left: 0.3rem;
  }
  &-share-with {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-between;
    .ant-input {
      &:focus {
        box-shadow: none;
      }
    }
    .ant-input-group-addon {
      border: 0;
    }
    &-input {
      display: flex;
      align-items: center;
      padding: 0.5rem 0 0.5rem 1.25rem;
      width: 100%;
      height: 40px;
      background: @white-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 200px;
      position: relative;
      .ant-select-selector {
        left: -12px;
        border: 0 !important;
      }
      &:hover {
        border-color: @brand-color;
      }
    }
    .ant-select-selection-item:focus-visible,
    .ant-select.select-after.ant-select-single.ant-select-show-arrow:focus-visible {
      outline: none;
    }
    &-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      .group-setting-share-with-add {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 40px;
        background: @brand-color;
        border-radius: 200px;
        flex: none;
        color: @white-color;
        border: 0;
        .group-share-user {
          padding-right: 0.2rem;
          path {
            fill: @white-color;
          }
        }
        & > span {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: @h5-font-size;
          line-height: 24px;
          text-align: center;
        }
        &:hover {
          background-color: @royal-blue;
        }
      }
      .group-setting-share-with-add-disable {
        background-color: @text-dark-theme;
      }
    }
    &-block {
      display: flex;
      position: relative;
      width: 100%;
      margin: 0 12px;
      .cms-select-after {
        position: absolute;
        top: 2px;
        right: 7px;
        .ant-select-selector {
          border: 0;
        }
        .ant-select-arrow {
          display: flex;
          align-items: center;
        }
      }
    }
    .cms-group-right-select {
      display: flex;
      justify-content: center;
      .cms-select-after {
        .ant-select-selector {
          border: 0;
        }
        .ant-select-arrow {
          display: flex;
          align-items: flex-start;
        }
      }
      .ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        background-color: transparent;
      }
    }
    &-text {
      display: flex;
      flex: none;
    }
  }
  &-shared-user-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1rem;
    justify-content: space-between;
    &-data {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.5rem;
    }
    &-cms-name {
      display: flex;
      align-items: center;
      .cms-group-user-avatar {
        font-size: @size-10;
        margin-right: 8px;
        border-radius: 8px;
        height: 24px;
        width: 24px;
      }
    }
    .user-resend-invitation {
      display: flex;
      align-items: center;
      padding: 0px 12px;
      height: 20px;
      background: @background-color;
      border-radius: 300px;
      font-size: 12px;
      margin-left: 0.4rem;
    }
    &-rights-dropdown {
      display: flex;
      align-items: center;
      .user-invite-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 6px;
        height: 20px;
        background: @white-color;
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 12px;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0;
    }
  }
  &-member-title {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: @h5-font-size;
    line-height: 24px;
    color: @primary-color;
    padding: 1.5rem 0 0.5rem 0;
    width: 100%;
    justify-content: space-between;
  }
  .cms-group-share-empty-state-image {
    height: 100%;
    max-width: 97%;
    max-height: 187px;
    bottom: 0;
    position: absolute;
    width: 100%;
    background: @white-color url("/assets/svg/noShareMember.svg") no-repeat bottom;
  }
}
.cms-group-share-empty-state {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  margin-top: 24px;
  &-top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2rem;
  }
  &-bottom-section {
    align-self: center;
    justify-content: center;
    color: @content-text-color;
    line-height: 22px;
    padding: 0 2rem;
    text-align: center;
    & > a {
      text-decoration: underline;
    }
  }
}
#group-space-member, #share-modal {
  .ant-select-dropdown {
    margin-left: -12px;
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      .cms-owner-avatar {
        margin-right: 0.5rem;
        font-size: @size-10;
        border-radius: 8px;
        height: 24px;
        width: 24px;
      }
    }
  }
}
.create-group-update-modal {
  display: flex;
  flex-direction: column;
  height: auto;
  background: @white-color;
  box-sizing: border-box;
  justify-content: center;
  margin: 0 auto;
  align-self: center;
  border-radius: 8px;
  z-index: 1;
  .ant-checkbox-inner {
    border-radius: 0px;
  }
  .group-list-heading {
    color: @primary-color;
    font-weight: 500;
  }
  .create-group-area {
    display: flex;
    align-items: center;
    width: 100%;
    .create-group-button {
      background: @background-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 200px;
      width: 73px;
      height: 56px;
      margin-bottom: 3rem;
      margin-right: 1.5rem;
      cursor: pointer;
      span {
        display: flex;
        justify-content: center;
        padding-top: 1.2rem;
      }
    }
    .create-group-form {
      width: 100%;
      margin-top: 10px;
      .cms-input-field {
        margin-bottom: 0;
      }
      .ant-form-item-label > label {
        font-size: 12px;
      }
      .form-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 8px 8px 12px;
        height: 44px;
        background: @background-color;
        border-radius: 8px;
        .checkbox-text {
          color: @content-text-color;
          opacity: 0.5;
          padding-left: 0.2rem;
        }
      }
      .group-add-contact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 8px 16px;
        background: @brand-color;
        border-radius: 8px;
        color: @white-color;
        float: right;
      }
    }
  }
  &-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .update-disable {
      color: rgba(0, 0, 0, 0.25) !important;
      background: @grey-3 !important;
    }
  }
}