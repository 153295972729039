.cms-notification-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 420px;
    box-shadow: 0px 16px 26px rgb(88 94 117 / 14%) !important;
    .ant-drawer-wrapper-body {
      background: @background-color;
      .ant-drawer-header {
        padding: 1rem 1rem 1rem 1.18rem;
        .cms-notification-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left {
            display: flex;
            align-items: center;
            .cms-noti-icon {
              display: flex;
              align-self: center;
              padding-right: 0.75rem;
              svg {
                height: 16.25px;
                width: 12.64px;
                path {
                  fill: @primary-color;
                }
              }
            }
            .cms-noti-title {
              font-size: @h4-font-size;
              color: @primary-color;
            }
          }
          &-right {
            .cms-noti-clear-btn {
              background: transparent;
              border: transparent;
              color: @content-text-color;
              font-size: @h5-font-size;
              padding: 0
            }
          }
        }
      }
      .ant-drawer-body {
        padding: 0.75rem;
        &::-webkit-scrollbar {
          width: @scrollbar-width;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 8px;
          background: @background-color;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: @stroke-color;
          &:hover {
            background: @scrollbar-hover-color;
          }
        }
      }
    }
  }
}