.plan-skeleton {
  &-wrapper {
    display: flex;
    padding: 1.5rem 1.5rem 0px;
  }
  &-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    &-left {
      display: flex;
      &-1 {
        width: 300px;
        .ant-skeleton-avatar.ant-skeleton-avatar-circle {
          margin-right: 16px;
        }
        .ant-skeleton-input {
          width: 200px;
        }
      }
      &-2 {
        .ant-skeleton-input {
          width: 200px;
        }
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
    }
  }
  &-body {
    display: flex;
    flex-direction: row;
    height: 453px;
    width: 100%;
    .ant-skeleton-paragraph {
      width: 273px;
    }
  }
  &-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    .ant-skeleton-paragraph li {
      height: 32px;
      width: 100% !important;
    }
  }
}
