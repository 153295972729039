.multiple-group-modal-wrapper {
  .cms-no-group-tag {
    padding: 1.75rem;
  }
  .ant-modal-close {
    margin-top: 3px;
    .anticon {
      svg {
        fill: @content-text-color;
      }
    }
  }
  .ant-modal-content {
    max-height: 80vh;
    border-radius: 16px;
  }
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }
  .ant-modal-body {
    padding: 12px 0 0 24px;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
    height: 48px;
    margin: 4px 0;
    border-radius: 8px;
    padding: 12px 14px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-checkbox-inner {
    height: 12px;
    width: 12px;
  }
  .ant-checkbox-inner::after {
    left: 20%;
  }
  .ant-checkbox.ant-checkbox-checked {
    border: 1px solid @brand-color;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
    svg {
      margin-left: 8px;
    }
  }
  .ant-list-item-meta-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .selected-list {
    .ant-list-item-meta-title {
      color: @brand-color;
    }
  }
  .ant-modal-footer {
    border: none;
    padding: 0 16px 16px 16px;
  }
  .cms-apply-btn {
    background: @primary-color;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    min-width: 96px;
    padding: 6px 16px;
    max-width: 96px;
    height: 36px;
    border-radius: 8px;
    margin-top: 16px;
    color: @white-color;
    border: 0;
    &:hover {
      background: @content-text-color;
    }
    &:disabled {
      background: @background-color;
      color: @content-text-color;
    }
  }
  .cms-infinite-scroll {
    max-height: calc(80vh - 145px);
    overflow: auto;
    padding-right: 24px;
    .group-name {
      display: block;
    }
    &::-webkit-scrollbar {
      width: @scrollbar-width;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background: @background-color;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: @stroke-color;
      &:hover {
        background: @scrollbar-hover-color;
      }
    }
  }
}
.export-contact-modal-wrapper {
  .ant-alert-info {
    background-color: #f2f4f7;
    border: none;
    .ant-alert-icon {
      margin-right: 12px;
    }
    .ant-alert-message {
      color: #585e75;
    }
  }
  .export-contact-content {
    padding: 20px 0 0;
    .ant-space-vertical {
      margin: 0 14px;
      .ant-radio-wrapper {
        white-space: unset;
        span:last-child {
          font-family: "Inter";
          font-weight: 500;
          color: @primary-color;
          p {
            margin: 5px 10px 0 24px;
            font-size: 12px;
            color: @content-text-color;
          }
        }
        &.ant-radio-wrapper-checked {
          .ant-radio-checked {
            .ant-radio-inner {
              border-color: @brand-color;
              box-shadow: none;
            }
            .ant-radio-inner::after {
              background-color: @brand-color;
            }
          }
          span:last-child {
            color: @brand-color;
            p {
              color: @content-text-color;
            }
          }
        }
      }
    }
  }
}
