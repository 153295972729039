.space-select-dropdown {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      border: none;
      border-bottom: 1px solid @stroke-color;
      border-radius: 0px;
    }
  }
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-weight: 600;
    &-avatar {
      border-radius: 2px;
      height: 18px;
      width: 18px;
      font-size: @size-10;
      margin-right: 8px;
    }
  }
  .ant-select:hover .ant-select-selector {
    border-color: @stroke-color !important;
    box-shadow: none !important;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: @stroke-color !important;
    box-shadow: none !important;
  }
}

.space-select-dropdown .rc-virtual-list-holder-inner {
  padding: 0.5rem;
}
