.ltd-plan-wrapper {
  align-items: center;
  height: 100vh;
}
.cms-subs-plan-wrapper {
  display: flex;
  padding-bottom: 16px;
  justify-content: space-evenly;
  width: 75%;
  gap: 12px;
  @media (max-width: 1160) {
    width: 70%;
  }
  .width-ltd {
    width: 300px !important;
  }
  .cms-subs-plan {
    border: 1px solid @stroke-color;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 30%;
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      .cms-plan-title-text {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: @h4-font-size;
        line-height: 26px;
        color: @primary-color;
      }
      .cms-plan-price-detail {
        width: 100%;
        display: flex;
        justify-content: center;
        color: @primary-color;
        .cms-plan-price {
          font-weight: 600;
          font-size: 36px;
          line-height: 54px;
        }
        .cms-plan-per-month {
          font-weight: 600;
          font-size: @h4-font-size;
          line-height: 52px;
          margin-top: 4px;
        }
        .cms-plan-per-user {
          font-weight: 500;
          font-size: @h6-font-size;
          line-height: 20px;
        }
      }
    }
    .cms-plan-title-other {
      background: @background-color;
    }
    .per-member-title {
      padding: 2px 16px;
    }
    .cms-subs-pro-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.25rem 1rem;
      background: @brand-color;
      border-radius: 8px;
      .cms-pro-plan-title {
        color: @white-color;
      }
    }
    .cms-feature-list {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      background: @white-color;
      box-shadow: 0px -1px 0px 0px @stroke-color inset;
      &-title {
        font-size: @h5-font-size;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: @primary-color;
        font-weight: 400;
        text-align: center;
      }
      &-subtitle {
        display: flex;
        align-items: center;
        font-size: @h6-font-size;
        line-height: 20px;
        color: @content-text-color;
        font-weight: 400;
      }
    }
    .cms-plan-subs-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      margin-top: 16px;
      color: @white-color;
      font-size: @h6-font-size;
      border: 0;
    }
    .cms-subs-active-plan {
      display: flex;
      align-items: center;
      &-icon {
        display: flex;
        margin-right: 9.67px;
      }
    }
    .cms-plan-subs-select-btn {
      background: @brand-color;
      &:hover {
        background: @royal-blue;
      }
    }
    .cms-plan-subs-update-btn {
      background: @primary-color;
      &:hover {
        background: @content-text-color;
      }
    }
    .cms-btn-activate {
      background: @brand-color;
      &:hover {
        background: @royal-blue;
      }
      &:disabled {
        background: #a2c4ed;
      }
    }

    .cms-plan-subs-active-btn {
      background: @primary-color;
      &:hover {
        background: @content-text-color;
      }
      &:disabled {
        background-color: @content-text-color;
      }
    }
    .cms-subs-plan-feature-list {
      margin-top: 1.5rem;
    }
    .cms-subs-plan-text {
      font-size: @h6-font-size;
      color: @content-text-color;
    }
    .cms-text-mLeft {
      margin-left: 6px;
    }
  }

  .cms-plan-active {
    border: 2px solid @brand-color;
  }
  .cms-subs-pro-plan {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0.75rem;
    position: relative;
    .cms-plan-recommend-wrapper {
      display: flex;
      justify-content: center;
      .recommended-text {
        font-size: 10px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
      }
      .cms-plan-recommend-active {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.75rem;
        position: absolute;
        top: -14px;
        background: @white-color;
        border: 2px solid @brand-color;
        border-radius: 20px;
      }
      .cms-plan-recommend {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.75rem;
        position: absolute;
        top: -14px;
        background: @white-color;
        border: 1px solid @stroke-color;
        border-radius: 20px;
      }
    }
    .cms-plan-subs-active-btn {
      background: @primary-color;
    }
  }
}
.cms-plan-update-popup {
  .ant-modal-header {
    border-bottom: 1px solid @stroke-color;
    padding: 1.5rem 1.5rem 0 1.5rem;
    .ant-modal-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: @primary-color;
      margin-bottom: 16px;
    }
  }
  .cms-additional-members {
    font-size: 10px;
    font-weight: 500;
    line-height: 19px;
    color: @primary-color;
    margin-left: 10px;
  }
  .ant-modal-body {
    padding: 16px 24px 0 24px;
  }
  .ant-modal-footer {
    padding: 22.5px 16px 16px 16px;
  }
  .ant-modal-close-x {
    padding-top: 0.5rem;
  }
  .popup-content {
    display: flex;
    flex-direction: column;

    input[type="number"]::-webkit-inner-spin-button {
      opacity: 1;
      height: 30px;
    }
    input[type="number"] {
      height: 45px;
      max-width: 80px;
    }
    .ant-input-number-handler-wrap {
      opacity: 1;
    }
    .cms-input-number {
      display: flex;
      align-items: flex-end;
    }
    .ant-skeleton {
      width: 30%;
    }
    .cms-input-number-text-danger {
      .ant-input-number-input {
        color: @danger-color;
      }
      .cms-plan-update-popup .popup-content input[type="number"] {
        color: @danger-color;
      }
    }
    .cms-font-cross {
      font-size: 12px;
      font-weight: 500;
    }
    .cms-total-amount {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: @primary-color;
    }
    .cms-bill-tag {
      font-family: Inter;
      font-size: 10px;
      font-weight: 500;
      line-height: 190%;
      height: 40px;
      background-color: @background-color;
      padding: 3px 10px;
      color: @primary-color;
      border-radius: 300px;
      margin-left: 8px;
    }
    .ant-input-number-input {
      height: 44px;
    }
    .ant-input-number-handler-down {
      border-top: unset;
    }
    .ant-divider-vertical {
      height: 44px;
      border: 0.5px solid @stroke-color;
      margin: 0 20px;
    }
    .ant-input-number-handler-wrap {
      border-left: unset;
    }
    .ant-input-number-handler-wrap
      .ant-input-number-handler
      .ant-input-number-handler-up-inner {
      color: @content-text-color;
    }
    .ant-divider-horizontal {
      margin: 16px 0;
      border: 1px solid @stroke-color;
    }
    .cms-warning-text {
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 6px;
      color: @content-text-color;
    }
    .cms-total-amount-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: @content-text-color;
    }
    .cms-plan-price {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-left: 12px;
      color: @content-text-color;
    }
    .cms-plan-year {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: @content-text-color;
    }
    .cms-modal-content-heading {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .cms-plan-title-other {
      background: @background-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .cms-plan-title-text {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: @h4-font-size;
      line-height: 26px;
      color: @primary-color;
      padding-right: 1rem;
    }
    .cms-modal-content-sub-heading {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin: 4px 0 12px 0;
      color: @content-text-color;
    }
    .cms-modal-content-title {
      color: @primary-color;
      font-size: @h5-font-size;
      margin-bottom: 0.75rem;
    }
    .cms-modal-content-desc {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 8px;
      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cms-modal-content-info {
          color: @primary-color;
          font-size: @h5-font-size;
        }
      }
      .cms-pay-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
        .cms-pay-now-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 16px;
          width: 91px;
          height: 32px;
          background: @brand-color;
          border-radius: 8px;
          color: @white-color;
        }
      }
    }
    .cms-user-price {
      font-size: @h2-font-size;
      color: @primary-color;
    }
    .cms-price-block {
      display: flex;
      align-items: center;
      .cms-user-price-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 0.5rem;
        background: @background-color;
        border-radius: 4px;
        font-size: @size-28;
        margin-right: 10px;
        &-per-user {
          font-size: @h5-font-size;
          line-height: 24px;
          color: @content-text-color;
        }
      }
    }
    .cms-user-plan-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      width: 476px;
      height: 64px;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 8px;
      margin-top: 16px;
      .cms-plan-total-charges {
        display: flex;
        align-items: center;
        font-size: @h5-font-size;
        line-height: 24px;
        color: @primary-color;
      }
    }
  }
  .ant-modal-footer {
    font-family: Inter;
    border-top: 0;
    .ant-btn {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background-color: @primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      color: @white-color;
      border: 0;
    }
    .ant-disable {
      background: @text-dark-theme !important;
    }
  }
}
.cms-plan-subs-btn-disable {
  background: @text-dark-theme !important;
}

.cms-plan-confirm-popup {
  .ant-skeleton {
    width: 30%;
  }
  .ant-modal-footer {
    padding: 22.5px 16px 16px 16px;
    .ant-btn-primary {
      display: inline-block;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 1rem;
      background: @primary-color;
      border-radius: 8px;
      color: @white-color;
    }
  }
}
