.space-menu {
  background: @background-color;
  padding: 16px;
  height: 100vh;
  .ant-list-split .ant-list-item {
    border-bottom: none;
    padding: 8px;
  }
  &-title {
    display: flex;
    align-items: center;
    font-size: @font-size-lg;
    color: @primary-color;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin-bottom: 16px;
    &-avatar {
      margin-right: 8px;
      border-radius: 2px;
      height: 20px;
      width: 20px;
      font-size: @size-10;
      font-weight: 600;
    }
    &-ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }
  }
  &-items-box {
    background: @white;
    padding: 16px;
  }
}
.space-menu-list-wrapper {
  .ant-list-items {
    .ant-list-item {
      border-radius: 4px;
      padding: 0.75rem @major-padding 0.5rem;
      border-bottom: none;
      cursor: pointer;
      &-meta {
        align-items: center;
        &-avatar {
          svg {
            path {
              fill: @content-text-color;
            }
          }
        }
        &-content {
          display: flex;
          justify-content: space-between;
          .cms-group-title {
            display: flex;
          }
          .ant-list-item-meta-title {
            color: @content-text-color;
          }
          .cms-contact-tag {
            position: absolute;
            right: 15px;
            align-items: center;
            padding: 1px 5px;
            position: absolute;
            background: @user-tag-color;
            border: 1px solid @white-color;
            box-sizing: border-box;
            border-radius: 200px;
            color: @white-color;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .selected-list {
      .ant-list-item-meta {
        &-avatar {
          svg {
            path {
              fill: @brand-color;
            }
          }
        }
        &-content {
          .ant-list-item-meta-title a {
            color: @brand-color;
            font-weight: 700;
          }
        }
      }
    }
  }
}
