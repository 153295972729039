.upgrade-popup {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid @stroke-color;
    border-radius: 8px;
    margin: 16px 0;
    &-section {
      display: flex;
      flex-direction: column;
    }
  }
  &-title {
    color: @primary-color;
    font-size: @font-size-base;
    line-height: 24px;
    font-weight: 500;
  }
  &-content {
    color: @content-text-color;
    font-size: @font-size-sm;
    line-height: 24px;
  }
  &-button {
    .ant-btn {
      margin-left: 0px;
    }
    max-width: 116px;
  }
}
.upgrade-popup-container::before {
  content: "";
  position: absolute;
  border-left: 1px solid @stroke-color;
  border-bottom: 1px solid @white-color;
  width: 0px;
  height: 10px;
  left: 80%;
  top: -10px;
  background: @white-color;
  margin-left: 5px;
  z-index: 1;
  transform: skewX(-42deg);
}
.upgrade-popup-container::after {
  content: "";
  position: absolute;
  border-right: 1px solid @stroke-color;
  width: 14px;
  height: 10px;
  left: 80%;
  top: -10px;
  background: @white-color;
  transform: skewX(42deg);
}
