.space-selected-list {
  svg {
    path {
      fill: @brand-color;
    }
  }
  span {
    color: @brand-color;
  }
}
.space-selected-list:hover {
  span {
    color: @brand-color;
  }
  svg {
    path {
      fill: @brand-color;
    }
  }
}
.space-selected-all {
  svg {
    fill: @brand-color;
  }
  span {
    color: @brand-color;
  }
}
.cms-default-tag {
  display: flex;
  align-items: center;
  margin-left: 0.4rem;
  background: @background-color;
  height: 16px;
  padding: 0 6px;
  font-size: @size-10;
  border-radius: 100px;
  color: @content-text-color;
}

.cms-space-header-wrapper {
  display: flex;
  width: inherit;
  justify-content: space-between;
}
.cms-space-upgrade {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: @background-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  border-radius: 4px;
  width: inherit;
  margin-top: 12px;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &-section {
    display: flex;
    align-items: center;
    &-text {
      color: @content-text-color;
      font-weight: bold;
      margin-left: 8px;
    }
  }
  &-icon {
    svg path {
      fill: @content-text-color;
    }
  }
  &:hover {
    background-color: @white-color;
    border-color: @brand-color;
    .cms-space-upgrade-section-text {
      color: @brand-color;
    }
  }
}
.wInherit {
  width: inherit;
}
.cms-continue-setup-btn {
  display: flex;
  flex-direction: row-reverse;
  background: @background-color;
  border: 1px solid @stroke-color;
  color: @content-text-color;
  svg {
    path {
      fill: @content-text-color;
    }
  }
  &:hover {
    color: @brand-color;
    border-color: @brand-color;
  }
}
.cms-default-space {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: @size-10;
  line-height: 14px;
  color: @text-dark-theme;
  width: inherit;
  padding-left: 1.8rem;
}
.cms-skeleton-layout {
  display: flex;
  width: 100%;
  height: calc(100vh - 193px);
}
.normal-height {
  &.normal-space-height {
    height: calc(100vh - 276px);
    &.tag-list {
      height: calc(100vh - 235px);
    }
  }
}
.new-height {
  height: calc(100vh - 276px);
}
.google-space-height {
  height: calc(100vh - 316px);
}
.normal-space-height {
  height: calc(100vh - 316px);
}
.react-joyride__tooltip {
  padding: 1rem !important;
  max-width: 276px !important;
  width: 100% !important;
  & > div > div {
    padding: 0 !important;
  }
  .cms-share-tooltip-wrapper {
    display: flex;
    flex-direction: column;
    &-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: @h4-font-size;
      line-height: 26px;
      color: @content-text-color;
    }
    &-content {
      font-weight: 400;
      font-size: @h5-font-size;
      line-height: 22px;
      color: @content-text-color;
    }
    &-next-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.375rem 1.375rem;
      height: 32px;
      background: @stroke-color;
      border-radius: 8px;
      .cms-next-btn {
        font-weight: 500;
        font-size: @h6-font-size;
        line-height: 20px;
        color: @white-color;
      }
      &:disabled:hover {
        background: @stroke-color;
      }
      &:hover {
        border: 0;
      }
    }
    .active-btn {
      background-color: @primary-color;
    }
    .cms-group-share-empty-state {
      padding: 0;
      margin-top: 0;
      &-top-section {
        padding: 0;
      }
    }
  }
}
#react-joyride-step-0 {
  .__floater__arrow {
    span {
      top: 32px !important;
      left: -6px !important;
    }
  }
  .react-joyride__tooltip > div + div {
    display: none !important;
  }
}
#react-joyride-step-1 {
  .__floater__arrow {
    span {
      top: -10px !important;
    }
  }
  .react-joyride__tooltip > div + div {
    display: none !important;
  }
}
.__floater.__floater__open {
  padding: 0 !important;
}
.react-joyride__beacon {
  top: 14px !important;
  left: -80px !important;
  height: 24px !important;
  width: 24px !important;
  span:nth-child(1) {
    background-color: @brand-color !important;
  }
  span:nth-child(2) {
    background-color: rgba(83, 161, 254, 0.2) !important;
    border-color: @brand-color !important;
  }
  &:focus-visible {
    outline: unset;
  }
}
