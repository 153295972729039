@wrapper-margin-top: 32px;
@avatar-size: 56px;

.dashboard-contact-detail-col {
  max-height: 100vh;
  overflow-y: scroll;
  padding: 1rem;
  background-color: @white-color;
}

.ant-input {
  &:focus {
    border-color: @input-focus;
    box-shadow: unset;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: @brand-color;
  font-weight: 600;
  background-color: transparent;
}
.ant-select-item {
  padding: 0;
}
.rights-option {
  padding: 0.5rem 1rem;
  .rights-option-icon {
    margin-right: 0.5rem;
  }
  .remove-text {
    color: @danger-color;
  }
}
.select-action {
  border-top: 1px solid @stroke-color;
  padding-top: 1rem;
}
