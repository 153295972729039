.group-label-wrapper {
  height: auto;
  margin: 1rem;
  background-color: @white-color;
  align-items: flex-end;
  .ant-col {
    &:extend(.basic-flex);
    .folder-svg {
      margin-right: 0.5rem;
      path {
        fill: @primary-color;
      }
    }
    .group-label-name {
      display: block;
      align-items: center;
      font-size: 16px;
      color: @primary-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 50%;
      font-weight: 500;
    }
  }
  .users-col {
    justify-content: flex-end;
    .ant-avatar-group {
      cursor: pointer;
      .ant-avatar {
        &:extend(.flex-all-center);
      }
    }
    .group-circle-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 1rem 0 1rem;
      position: absolute;
      width: 170px;
      height: auto;
      right: 30px;
      top: 39px;
      background: @white-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      box-shadow: 0px 12px 30px rgb(88 94 117 / 12%);
      border-radius: 8px;
      z-index: 1;
    }
    .cms-contact-header-avatar {
      font-size: @h6-font-size !important;
      border: 2px solid @white-color !important;
      border-radius: 8px;
      height: 26px;
      width: 26px;
    }
  }
  .group-label-sub-wrapper {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    width: 100%;
    .cms-icon-btn-wrapper {
      margin: 0 0.5rem;
    }
    .setting-col {
      justify-content: flex-end;
    }
    .cms-icon-btn-size {
      height: 26px;
      width: 26px;
    }
    .cms-space-avatar-mRight {
      svg {
        height: 20px;
        width: 20px;
        path {
          fill: @primary-color;
        }
      }
    }
    .add-contact-button-mobile {
      margin-top: 1rem;
      margin-left: auto;
      width: calc(100% - 30px);
    }
  }
  .cms-group-search-block {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
  }
  .done-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0.5rem 0;
    font-size: @h6-font-size;
    .close-group-edit {
      height: 20px !important;
      width: 20px !important;
      path {
        fill: @danger-color;
      }
    }
  }
  .cms-done-btn-disable {
    pointer-events: none;
    cursor: not-allowed;
    background: @grey-3;
  }
  .group-label-search-section {
    margin-top: 1rem;
    width: 100%;
    &-block {
      display: flex;
      width: inherit;
      justify-content: space-between;
    }
    .search-col {
      display: flex;
      align-items: center;
      width: 100%;
      .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
        border-radius: 20px;
      }
      .ant-input-group-addon {
        display: none;
      }
      &-sorting {
        border: 1px solid @stroke-color;
        padding: 10px 10px 10px 14px;
        border-radius: 300px 0px 0px 300px;
      }
      &-sorting-single {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid @stroke-color;
        padding: 10px;
        border-radius: 300px;
        width: 40px;
        height: 40px;
        .cms-svg-wrapper {
          display: flex;
          text-align: center;
          justify-content: center;
          height: 20px;
          width: 20px;
          svg {
            height: 15px;
            width: 15px;
          }
        }
        &:hover {
          border-color: @brand-color;
        }
      }
      &-sorting-add-person {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 40px;
        flex: none;
        background: @white-color;
        box-sizing: border-box;
        cursor: pointer;
        fill: @content-text-color;
      }
      &-add-person {
        box-shadow: inset -1px 0px 0px @stroke-color,
          inset 0px -1px 0px @stroke-color, inset 0px 1px 0px @stroke-color;
        padding: 10px 14px 10px 10px;
        border-radius: 0px 300px 300px 0px;
      }
      .cms-done-btn {
        padding: 10px 14px 10px 14px;
      }
      .ant-input-affix-wrapper {
        border-radius: 20px;
        border-color: @stroke-color;
        &:not(.ant-input-affix-wrapper-disabled):hover {
          border: 1px solid @brand-color;
        }
        .ant-input-prefix {
          .anticon {
            color: @content-text-color;
          }
        }
      }
      .ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 1px solid @primary-color;
      }
    }
    .cms-contact-sort {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 40px;
      max-height: 40px;
    }
    .d-flex-end {
      display: flex;
      justify-content: flex-end;
    }
  }
  .cms-mobile-logout {
    margin-left: -6px;
    cursor: pointer;
    &-btn {
      height: 18px;
      width: 18px;
    }
  }
  .cms-delete-icon-color {
    margin-right: 8px;
    height: 13px;
    width: 17px;
    path {
      fill: @primary-color;
    }
  }
  .cms-delete-tag-option {
    justify-content: flex-end;
  }
  .cms-add-contact-button {
    background-color: @background-color;
    color: @primary-color;
    border-radius: 8px;
    padding: 0.375rem 1rem;
    border: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    height: 32px;
    min-width: 105px;
    &:hover {
      background-color: @stroke-color;
    }
  }
}
.cms-reminder-dropdown {
  .ant-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 26px;
    .ant-select-selector {
      background-color: unset;
      border: unset;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: @content-text-color;
      }
    }
    .ant-select-arrow .anticon > svg {
      fill: @content-text-color;
    }
  }
}
#reminder {
  .ant-select-dropdown {
    margin-left: -12px;
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      .cms-owner-avatar {
        margin-right: 0.5rem;
        font-size: @size-10;
        border-radius: 8px;
        height: 24px;
        width: 24px;
      }
    }
  }
}
