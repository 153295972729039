.assistant-nav {
  display: flex;
  height: calc(100vh - 1px);
  overflow: auto;
  flex-direction: column;
  &-title {
    display: flex;
    flex-direction: row;
    padding: 1.25rem 1.25rem 1.25rem 0.75rem;
    border-bottom: 1px solid @stroke-color;
    max-height: 60px;
    &-icon {
      margin-right: 6px;
      display: flex;
      align-items: center;
      svg {
        path {
          fill: @primary-color;
        }
      }
    }
    &-text {
      color: @primary-color;
      font-size: @font-size-lg;
      font-family: "Inter", sans-serif;
      font-weight: @font-weight-medium;
    }
  }
  &-space-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 55px);
    overflow-x: hidden;
    padding: 0.75rem;
    &-box {
      &-title {
        color: @content-text-color;
        font-weight: @font-weight-bold;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 12px 0 18px 0;
        &-icon {
          display: flex;
          width: 100%;
          max-width: 80%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          .assistant-space-avatar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.063rem 0.313rem;
            width: 20px;
            height: 20px;
            border: 1px solid @stroke-color;
            box-sizing: border-box;
            border-radius: 2px;
            font-size: @size-10;
          }
          .assistant-space-name {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
