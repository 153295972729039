.cms-settings-header {
  display: flex;
  align-items: center;
  max-height: 60px;
  border-bottom: 1px solid @stroke-color;
  padding: 0.313rem 0 1.063rem 1rem;
  margin: 1px -16px 0 -16px;
  &-icon {
    display: flex;
    align-items: center;
    svg {
      path {
        fill: @primary-color;
      }
    }
  }
  &-title {
    color: @primary-color;
    font-size: @h4-font-size;
    font-weight: 500;
  }
}