.add-note-modal {
  position: absolute;
  width: 280px;
  right: 45px;
  bottom: 1px;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  box-shadow: 0px 16px 26px rgba(88, 94, 117, 0.14);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-title {
    display: flex;
    padding: 1rem;
    font-weight: bold;
    border-bottom: 1px solid @stroke-color;
    font-size: @h6-font-size;
  }
  &-body {
    .ant-input {
      border: 0;
      &:focus {
        box-shadow: unset;
      }
    }
  }
  &-footer {
    border-top: 1px solid @stroke-color;
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-note-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 12px;
      height: 24px;
      background: @brand-color;
      border-radius: 300px;
      border: 0;
      font-size: @h6-font-size;
      color: @white;
    }
    .add-note-private {
      margin-left: -24px;
      font-size: @h6-font-size;
    }
    .ant-switch-checked {
      background-color: @brand-color;
    }
    .add-note-disable {
      color: @text-dark-theme;
    }
  }
  &-upgrade {
    position: relative;
    background: @white;
    padding: 8px 12px 12px;
    border-top: 1px solid @stroke-color;
    margin-top: 8px;
    .ant-btn {
      margin-left: 0px;
      margin-top: 8px;
    }
  }
  &-upgrade::before {
    content: "";
    position: absolute;
    border-left: 1px solid @stroke-color;
    border-bottom: 1px solid @white-color;
    width: 0px;
    height: 10px;
    left: 6%;
    top: -10px;
    background: @white-color;
    margin-left: 5px;
    z-index: 1;
    transform: skewX(-42deg);
  }
  &-upgrade::after {
    content: "";
    position: absolute;
    border-right: 1px solid @stroke-color;
    width: 14px;
    height: 10px;
    left: 6%;
    top: -10px;
    background: @white-color;
    transform: skewX(42deg);
  }
  .add-note-button-disable {
    background: @background-color;
    color: @content-text-color;
  }
}
