.ant-drawer-body {
  .cms-no-notification {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    justify-content: center;
    &-text {
      font-weight: 600;
    }
  }
}