.forgot-password-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 456px;
    width: 100%;
    @media (max-width: 413px) {
      width: 310px;
    }
    @media (max-width: 360px) {
      width: 250px;
    }
  }
  &-subtitle {
    margin-bottom: 16px;
    .ant-typography {
      line-height: 24px;
    }
  }
}
