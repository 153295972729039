.ant-layout-header {
  display: flex;
  align-items: center;
  .app-header-menu {
    display: none;
    margin-right: 1rem;
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-layout-header {
    .app-header-menu {
      display: block;
    }
  }
}
