.cms-reminders-activate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
  height: inherit;
  background: @white-color;
  border-radius: 12px;
  &-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-svg-icon {
      height: 56px;
      width: 56px;
    }
    &-title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: @size-24;
      line-height: 24px;
      color: @notify-success-top;
    }
    &-image {
      margin: 40px 0;
      img {
        width: 100%;
        border-radius: 8px;
        border: 1px solid @stroke-color;
        max-height: 40vh;
      }
    }
    &-content {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: normal;
      font-size: @h3-font-size;
      line-height: 24px;
      color: @content-text-color;
    }
    .dropdown-menu-button-section-text {
      font-size: @h5-font-size;
      line-height: 24px;
    }
  }
}
.cms-request-admin-padding {
  padding: 0.25rem 1rem;
}