.cms-account-item {
  display: flex;
  padding: 0.75rem 0.75rem 0 0.75rem;
  .cms-account-source-icon {
    padding-right: 0.7rem;
    margin-top: 0.25rem;
    img {
      height: 20px;
      width: 20px;
    }
  }
  .cms-account-source-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0.75rem;
    .cms-account-detail {
      flex-direction: column;
      .cms-account-email {
        color: @primary-color;
        line-height: 24px;
      }
      .cms-account-time {
        display: flex;
        .cms-account-fetch-text {
          color: @content-text-color;
          font-weight: bold;
          padding-right: 0.5rem;
        }
      }
    }
    .cms-time-selector {
      .ant-select-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 8px;
        background: @white-color;
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 4px;
        width: 75px;
        height: 24px;
        .ant-select-selection-item {
          font-size: @h6-font-size;
        }
        .cms-time-option {
          padding: 1rem
        }
      }
      .ant-select-arrow {
        display: flex;
        align-items: center;
      }
    }
    .cms-account-fetch-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.125rem 0.75rem;
      background: @primary-dark;
      border-radius: 300px;
      color: @white-color;
      height: 24px;
      width: 85px;
    }
  }
  .cms-list-border {
    border-bottom: 1px solid @stroke-color;
  }
  .cms-account-close-icon {
    cursor: pointer;
    svg path {
      fill: @danger-color;
    }
  }
  .cms-fetching-state {
    display: flex;
    .cms-fetching-loader {
      padding-right: 0.4rem;
    }
    .cms-fetching-text {
      font-size: @h6-font-size;
    }
  }
}