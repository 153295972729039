.cms-add-contact-source-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  .cms-add-contact-source-header {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 2.5rem;
    .cms-add-source-title {
      font-size: @h4-font-size;
      color: @primary-color;
      font-weight: 500;
      line-height: 26px;
    }
    .cms-contact-form-close-arrow {
      display: flex;
      align-items: center;
      cursor: pointer;
      .cms-contact-form-close-back {
        padding-left: 0.5rem;
      }
    }
  }
}