.tell-us-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .cms-email-container {
    .ant-select-arrow {
      color: @content-text-color;
    }
    .ant-form-item {
      margin-bottom: 16px;
      font-size: 2px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      font-size: 14px;
    }
  }
  .form-item-margin {
    .ant-form-item {
      margin-bottom: 4px;
    }
  }
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &-card {
    width: 360px;
    @media (max-width: 413px) {
      width: 310px;
    }
    @media (max-width: 360px) {
      width: 250px;
    }
    @media (max-width: 768px) {
      .ant-btn-primary {
        width: 100%;
      }
    }
    .ant-form-item-label > label {
      color: @content-text-color;
    }
  }
}
