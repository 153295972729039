.success-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}
