.progress-circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-text {
    font-size: 12px;
    margin-right: 16px;
    color: @content-text-color;
  }
}
