.notes-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  &-name-time-info {
    display: flex;
    align-items: center;
    .notes-username {
      color: @primary-color;
      font-weight: bold;
      font-size: @h6-font-size;
    }
    .notes-extra-info {
      font-size: 12px;
    }
    .notes-action {
      color: @danger-color;
      cursor: pointer;
      border: 0;
      padding: 0;
    }
    .notes-disable {
      background: none;
      pointer-events: none;
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    .cms-notes-user-avatar {
      margin-right: 10px;
      border-radius: 8px;
      font-size: @size-10;
      height: 24px;
      width: 24px;
    }
  }
  &-note-info {
    display: flex;
    padding-left: 2.6rem;
  }
  .cms-dot-separator {
    width: 4px;
    height: 4px;
    background: @text-dark-theme;
    margin: 0px 8px;
    border-radius: 200px;
  }
  .notes-private {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    background: @background-color;
    border-radius: 300px;
    order: 2;
    margin: 0px 8px;
    position: absolute;
    right: 0;
    &-lock {
      height: 15px;
      width: 15px;
      fill: @content-text-color;
      margin-right: 0.2rem;
    }
  }
}
.notes-tab-border {
  border-bottom: 1px solid @stroke-color;
}

.is-sticky {
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.add-note {
  display: flex;
  align-items: center;
  padding: 9px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 16px;
  bottom: 16px;
  background: @brand-color;
  box-shadow: 0px 16px 26px rgba(88, 94, 117, 0.14);
  border-radius: 100px;
  cursor: pointer;
  justify-content: center;
  &:hover {
    background: @royal-blue;
  }
}
.close-note {
  background: @content-text-color;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}
.cms-disable-add-note {
  opacity: 0.5;
  cursor: pointer;
  pointer-events: none;
}
