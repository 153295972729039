//! User Meta

//* Do not alter this block of css, otherwise it will break css everywhere

.description-row {
  .designation-col {
    .designation {
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }
  .tags-col {
    .label-tag-wrapper {
      &:extend(.basic-flex);
      .ant-tag {
        border: none;
        background-color: @background-color;
        padding: 6px 12px;
        text-transform: capitalize;
        border-radius: 16px;
        &:extend(.basic-flex);
        .cms-svg-sm {
          margin-right: 0.25rem;
          path {
            fill: @content-text-color;
          }
        }
        .ant-typography {
          max-width: 50px;
          span {
            font-family: "Inter", sans-serif;
          }
        }
      }
      .group-tag {
        padding: 0.125rem 0.75rem;
        font-size: @size-10;
        background: @white;
        border: 1px solid @stroke-color;
        .group-tag-pd-left {
          padding-left: 0.3rem;
        }
      }
      .dashed-tag {
        padding: 0.125rem 0.75rem;
        background-color: transparent;
        border: 1px dashed @text-dark-theme;
        max-height: 24px;
      }
      &-tag-icon {
        padding-right: 0.5rem;
      }
    }
  }
  .with-border {
    .label-tag-wrapper {
      .ant-tag {
        border: 1px solid @stroke-color;
        background-color: @white-color;
      }
      .dashed-tag {
        padding: 0.125rem 0.75rem;
        background-color: transparent;
        border: 1px dashed @text-dark-theme;
      }
      .cms-tag-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.125rem 0.75rem;
        background: @tag-color;
        border-radius: 8px;
        font-size: @size-10;
      }
    }
  }
  .group-tag-pt {
    padding-top: 0.63rem;
  }
}
