.group-list {
  &-row {
    .group-list-col {
      width: 100%;
      .ant-typography {
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 0 0.4rem;
        margin-bottom: auto;
        color: @primary-color;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
      }
      .cms-text-strong {
        max-width: 100%;
        align-items: center;
        cursor: pointer;
      }
      .cms-current-space-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .ant-checkbox.ant-checkbox-checked {
        border: 1px solid @brand-color;
      }
      .ant-checkbox-inner {
        height: 12px;
        width: 12px;
      }
    }
    .lists-data-row {
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;
      .list-divider {
        margin: 1rem 0;
        background-color: @stroke-dark;
      }
    }
    .group-bottom-line {
      border-bottom: 1px solid @stroke-color;
    }
    .all-contacts-tab {
      padding: 12px;
    }
    .group-tags-tab {
      padding: 8px 12px 8px 14px;
      .button-switch {
        display: flex;
        max-height: 36px;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        background: @background-color;
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 300px;
        width: 100%;
        .ant-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: unset;
          flex: none;
          order: 0;
          flex-grow: 1;
          margin: 0px 0px;
          height: 28px;
          color: @content-text-color;
          transition: all 0.3s 0.1s ease-in-out;
        }
        .button-inside-div {
          padding: 0.2rem;
          width: 100%;
          display: flex;
          transition: all 0.3s 0.1s ease-in-out;
          .active {
            display: flex;
            align-items: center;
            background: @primary-color;
            border-radius: 300px;
            color: @white-color;
            width: 100%;
            max-height: 28px;
            &:hover {
              background-color: @content-text-color;
            }
          }
        }
      }
      .create-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 21px 10px 12px;
        width: 100%;
        background: @menu-item-accent;
        border-radius: 8px;
        cursor: pointer;
        justify-content: center;
        &-title {
          margin-left: 5px;
          color: @white-color;
        }
        &-contact-space {
          fill: @white-color;
        }
      }
      .cms-dash-icons {
        display: flex;
      }
      .ant-checkbox {
        display: flex;
        align-items: center;
        top: 0;
      }
    }
    .cms-contact-title {
      padding-left: 0.8rem;
      padding-right: 0.7rem;
      color: @content-text-color;
      line-height: 24px;
      font-weight: bold;
      letter-spacing: 0.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .title-actions {
        display: flex;
        flex-direction: row;
      }
      &-setting {
        fill: @content-text-color;
        margin-right: 10px;
        cursor: pointer;
      }
      &-add-contact {
        fill: @content-text-color;
        cursor: pointer;
      }
      .cms-organization-logo {
        fill: @logo-color;
        margin-right: 5px;
      }
      .cms-icon-btn-group {
        border: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      .cms-space-name-photo {
        display: flex;
        align-items: center;
        width: 80%;
        &-body {
          margin-right: 8px;
          border-radius: 2px;
          height: 20px;
          width: 20px;
          font-size: @size-10;
        }
        .cms-space-user-avatar {
          margin-right: 8px;
          border-radius: 2px;
          height: 20px;
          width: 20px;
        }
      }
    }
    .cms-title-all {
      display: flex;
      align-items: center;
      padding: 0 !important;
      font-size: @font-size-base;
      color: @content-text-color !important;
      font-weight: normal;
      cursor: pointer;
      width: 100%;
      &-left {
        display: flex;
        align-items: center;
        svg path {
          fill: @content-text-color;
        }
        &:hover {
          color: @primary-color;
          svg path {
            fill: @primary-color;
          }
        }
      }
      svg {
        margin-right: 8px;
      }
    }
    .cms-all-cnt-block {
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-height: 100%;
      height: 48px;
    }
    .cms-all-contact-svg {
      display: flex;
      margin-right: 0 !important;
      justify-content: flex-end;
      height: 18px;
      width: 18px;
      fill: @content-text-color;
      &:hover {
        fill: @primary-color;
      }
    }
    .cms-title-all:hover {
      color: @primary-color;
      svg {
        fill: @primary-color;
      }
    }
    .cms-title-all-selected {
      .cms-all {
        path {
          fill: @brand-color;
        }
      }
      svg {
        fill: @brand-color;
      }
      span {
        color: @brand-color;
      }
      .cms-count {
        color: @content-text-color;
      }
    }
    .cms-title-all-selected:hover {
      .cms-all {
        path {
          fill: @brand-color;
        }
      }
      svg {
        fill: @brand-color;
      }
      span {
        color: @brand-color;
      }
      .cms-count {
        color: @content-text-color;
      }
    }

    .cms-expand-more {
      display: flex;
      align-items: center;
      line-height: 20px;
      opacity: 0.8px;
      cursor: pointer;
      &-arrow {
        fill: @content-text-color;
        margin-right: 10px;
      }
    }
    .cms-all-contact-count {
      display: flex;
      align-items: flex-end;
    }

    .cms-select-all-wrapper {
      .ant-checkbox-inner::after {
        left: 20%;
      }
      justify-content: space-between;
      &-checkbox {
        flex-direction: row-reverse;
      }
    }
  }
  .cms-create-group-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem;
    .cms-create-group-hover:hover {
      background-color: @stroke-color;
    }
    .cms-current-space-title-block {
      padding: 0.563rem 2.036rem;
      justify-content: center;
      &-text {
        font-weight: 500;
        color: @space-popover;
        font-size: @h5-font-size;
        line-height: 22px;
        @media (max-width: 1250px) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .cms-current-space-title-block {
    display: flex;
    justify-content: space-between;
    background-color: @background-color;
    padding: 0.5rem;
    border-radius: 8px;
    width: 100%;
    max-height: 100%;
    height: 40px;
    align-items: center;
    border: 0;
    &-text {
      color: @space-popover;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
    &-avatar {
      display: flex;
      border-radius: 2px;
      border: 1px solid @stroke-color;
      font-size: @h6-font-size !important;
    }
    .ant-skeleton {
      display: flex;
      align-items: center;
      .ant-skeleton-avatar {
        height: 24px;
        width: 24px;
        line-height: 24px;
        border-radius: 2px;
      }
      .ant-skeleton-content {
        margin-top: 12px;
        .ant-skeleton-paragraph > li {
          width: 100% !important;
        }
      }
    }
    &-left-section {
      display: flex;
      align-items: center;
      max-width: 80%;
      width: 100%;
      .cms-google-space-logo {
        position: absolute;
        z-index: 4;
        top: 31px;
        left: 35px;
        height: 10px;
        width: 10px;
      }
    }
  }
}
.cms-space-list {
  width: 100%;
  height: calc(100vh - 240px);
  overflow-y: auto;
}
.cms-shared-space-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: @h2-font-size;
  line-height: 32px;
  color: @primary-color;
}

.cms-share-button {
  display: flex;
  align-items: center;
  background-color: @brand-color;
  color: @white-color;
  border-radius: 8px;
  padding: 4px 12px;
  border: 0;
  min-width: 58px;
  height: 24px;
  &:hover {
    background-color: @royal-blue;
    color: @white-color;
  }
}

.cms-group-share-joyride {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom-tooltip {
  max-width: 176px;
  box-shadow: 0px 25px 50px rgba(81, 89, 131, 0.25);
  .ant-tooltip-inner {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    background-color: #101326;
  }
  .ant-tooltip-arrow-content {
    top: 7px;
    border-radius: 2px;
    width: 13px;
    height: 13px;
    background-color: #101326;
  }
}
