.assistant-list-wrapper {
  .ant-list-items {
    .ant-list-item {
      border-radius: 4px;
      border-bottom: none;
      cursor: pointer;
      padding: 0 0 12px 0;
      &-meta {
        align-items: center;
        &-avatar {
          margin-right: 8px;
          svg {
            path {
              fill: @content-text-color;
            }
          }
        }
        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .cms-group-title {
            display: flex;
          }
          .ant-list-item-meta-title {
            a {
              display: flex;
              color: @content-text-color;
            }
            .cms-assistant-ellipsis {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .cms-contact-tag {
            position: absolute;
            right: 15px;
            align-items: center;
            padding: 1px 5px;
            position: absolute;
            background: @user-tag-color;
            border: 1px solid @white-color;
            box-sizing: border-box;
            border-radius: 200px;
            color: @white-color;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .selected-list {
      .ant-list-item-meta {
        &-avatar {
          svg {
            path {
              fill: @brand-color;
            }
          }
        }
        &-content {
          .ant-list-item-meta-title a {
            color: @brand-color;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.space-right {
  margin-left: 27.5px;
  padding-top: 0;
}
.cms-assistant-text-ellipsis {
  .ant-list-item-meta-title {
    width: 100%;
  }
}