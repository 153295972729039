.dropdown-menu {
  background: @white-color;
  border-radius: 8px;
  padding: 1rem;
  min-width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.12);
  max-width: 272px;
  width: 100%;
  .ant-space-vertical {
    width: 100%;
  }
  .ant-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    margin-bottom: 16px;
    margin-right: 0;
    border: 1px solid @stroke-color;
    background: @background-color;
    svg {
      margin-right: 0.5rem;
      path {
        fill: @content-text-color;
      }
    }
    span {
      color: @content-text-color;
      line-height: 26px;
    }
  }
  .ant-divider-horizontal {
    margin: 16px 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: @brand-color;
    border-color: @brand-color;
    z-index: 2;
  }
  .ant-radio-inner, .ant-checkbox-inner {
    border-color: @content-text-color;
  }
  .ant-radio-inner::after {
    background-color: @brand-color;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: @brand-color;
  }
  &-heading {
    display: flex;
    &-title {
      line-height: 26px !important;
      color: @content-text-color !important;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-desc {
      font-size: 14px;
      line-height: 22px;
      color: @content-text-color;
    }
  }
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: @font-size-base;
    font-weight: 500;
    color: @primary-color;
    &-text {
      font-family: "Inter";
    }
    svg {
      cursor: pointer;
    }
  }
  &-text {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: @content-text-color;
    margin-bottom: 8px;
  }
  &-button {
    display: flex;
    justify-content: center;
    &-section {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @brand-color;
      color: @white-color;
      padding: 0.375rem 1rem;
      border: 0;
      &:hover {
        background-color: @white-color;
        color: @brand-color;
        border: 1px solid @brand-color;
      }
      &-text {
        font-weight: 500;
        font-size: @h6-font-size;
        line-height: 20px;
      }
    }
  }
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    span.ant-radio + * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .cms-remove-reminder {
      position: relative;
      left: 8px;
      z-index: 10;
      .cms-remove-stay-connected {
        path {
          fill: red;
        }
      }
    }
  }
}

.dropdown-checkbox {
  .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .ant-checkbox-inner {
    border-radius: 2px;
  }
}
.cms-request-admin {
  background: @background-color;
  border-radius: 200px;
  pointer-events: none;
  padding: 0.25rem 0;
  max-height: 30px;
  &-text {
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 500;
    font-size: @h5-font-size;
    line-height: 22px;
    color: @content-text-color;
  }
}