.cms-my-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  &-header {
    display: flex;
    align-items: center;
    width: inherit;
    max-height: 60px;
    border-bottom: 1px solid @stroke-color;
    padding: 1rem 1.063rem;
    .cms-profile-text {
      font-weight: 500;
    }
    .cms-my-profile-icon {
      display: flex;
    }
  }
  .cms-user-profile {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
  }
}