.all-contacts {
  background: @border-color-theme;
  padding: 0.5rem 0.5rem 0 0.5rem;
  height: calc(100vh - 116px);
  .contacts-col {
    max-height: calc(100vh - 124px);
    overflow-x: hidden;
    border-radius: 8px;
    width: 100%;
    background-color: @border-color-theme;
    .contacts-list {
      background-color: @white-color;
      .ant-list-items {
        .ant-list-item {
          padding: 0.75rem 0rem 0.75rem 0.75rem;
          align-items: start;
          .ant-list-item:not(:last-child) {
            border-bottom: 1px solid @stroke-color;
          }
          &-meta {
            .ant-avatar {
              font-size: 19px !important;
            }
            &-content {
              display: flex;
              justify-content: center;
              flex-direction: column;
              .ant-list-item-meta-title {
                color: @primary-color;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                .username {
                  color: @primary-color;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .description-row .contact-organization {
                position: relative;
                left: 150px;
                top: 10px;
              }
            }
          }
          &-action {
            align-items: center;
            li {
              .ant-list-item-action-split {
                width: 0;
              }
            }
          }
        }
        .cms-no-group {
          align-items: center;
        }
      }
    }
  }
  .cms-contact-list-height {
    max-height: calc(100vh - 160px);
  }
  .new-list-height {
    max-height: calc(100vh - 180px);
  }
  .cms-contact-plan-height {
    max-height: calc(100vh - 220px);
  }
}
.selected-contact {
  box-shadow: inset 4px 0 @brand-color;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  .ant-list-items {
    .ant-list-item::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 4px;
      background: @brand-color;
      bottom: -1px;
      left: 0;
    }
  }
}
.cms-infinite-scroll-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}