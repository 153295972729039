.cms-subscription-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  .cms-subscription-detail {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 1.5rem 0 1.5rem;
  }
  .skeleton-center {
    .ant-skeleton-paragraph {
      margin: auto;
    }
  }
  .cms-plan-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
  }
}
