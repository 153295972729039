.cms-no-label-found {
  padding: 10px 0;
  width: inherit;
  &-title {
    font-weight: 500;
    font-size: @h2-font-size;
    line-height: 20px;
    color: @content-text-color;
    max-width: 391px;
    width: 100%;
    height: 28px;
    margin: auto;
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: @background-color;
    border-radius: 400px;
    height: 96px;
    width: 96px;
    margin: auto;
    svg {
      height: 56px;
      width: 56px;
    }
  }
  &-description {
    font-size: 16px;
    line-height: 25px;
    color: @content-text-color;
    padding: 0 3.125rem;
    text-align: center;
  }
}
.text-bold {
  font-weight: bold;
}