.cms-email-container {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .sigup-form {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 456px;
  max-width: 100%;
  .cms-email-login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background: @primary-dark;
    border-radius: 8px;
    border: 0;
    width: 100%;
    &:hover {
      background: @content-text-color;
    }
    &-text {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: @h5-font-size;
      line-height: 24px;
      color: @white-color;
    }
  }
  .cms-login-title {
    font-weight: 600;
    font-size: @h3-font-size;
    line-height: 28px;
    color: @primary-color;
  }
  .cms-no-account {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: @h5-font-size;
    line-height: 24px;
    color: @content-text-color;
    margin-top: 12px;
  }
}
.cms-login-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  background: @background-color;
  border-radius: 200px;
  border: 0;
  width: 71px;
  height: 28px;
  &:hover {
    background: @stroke-color;
  }
  &-text {
    font-weight: 500;
    font-size: @h5-font-size;
    line-height: 20px;
    color: @primary-color;
    margin-left: 8px;
  }
  svg {
    path {
      fill: @primary-color;
    }
  }
}
