.empty-screen-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  &-img {
    max-width: 375px;
    width: 100%;
  }
  &-text {
    max-width: 400px;
    text-align: center;
    line-height: 24px;
    &-title {
      font-size: 18px;
      font-weight: 500;
      color: @primary-color;
    }
  }
  &-btn {
    margin-top: 1rem;
    .ant-btn-primary {
      display: flex;
      svg {
        margin-right: 8px;
      }
    }
  }
}
