.cms-document-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  position: relative;
  justify-content: center;
  .cms-upload-wrapper {
    padding-right: 1rem;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    .cms-dropzone {
      border-radius: 4px;
      padding: 2rem 1.5rem;
      display: flex;
      justify-content: center;
      text-align-last: center;
      width: 100%;
      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.75rem 0;
        justify-content: center;
        .cms-upload-icon {
          display: flex;
          padding-bottom: 0.625rem;
        }
        .cms-upload-text {
          color: @content-text-color;
          font-size: @h6-font-size;
        }
      }
    }
    .cms-dropzone-block {
      padding: 0.8rem 1rem 0.8rem 0rem;
      position: absolute;
      flex-direction: column;
      justify-content: flex-end;
      bottom: 0;
      &-content {
        display: flex;
        flex-direction: row;
        border: 1px dashed @stroke-color;
        padding: 0.75rem 0;
        justify-content: center;
        position: relative;
        .cms-upload-icon-block {
          margin-right: 0.5rem;
          padding-bottom: 0;
        }
      }
    }
    .cms-document-empty-height {
      height: calc(100vh - 430px);
    }
  }
  .cms-upload-wrapper-block {
    display: flex;
    padding-top: 4rem;
    align-items: baseline;
    left: 0;
    top: 0;
  }
  .cms-document-scroller {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
    height: calc(100vh - 495px);
  }
}
.cms-dropzone-active {
  background: rgba(83, 161, 254, 0.08);
  height: calc(100vh - 430px);
  .cms-upload-icon {
    svg path {
      fill: @brand-color;
    }
  }
  .cms-upload-text {
    color: @brand-color !important;
  }
}
.cms-dropzone-zIndex {
  z-index: 2;
}
