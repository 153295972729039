.dashboard {
  background: @white-color;
  &-row {
    .dashboard-groups-col {
      height: 100vh;
      background-color: @white-color;
      border: 1px solid @stroke-color;
      //!Keeping this knowingly
      // max-width: 260px;
      // min-width: 200px;
    }
  }
}
