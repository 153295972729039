.subscription-detail {
  display: flex;
  justify-content: center;
  width: 100%;
  &-block {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 532px;
    width: 100%;
    padding-top: 1.5rem;
    .cms-current-subs-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-left {
        display: flex;
        align-items: center;
        &-section {
          display: flex;
          flex-direction: column;
          .cms-current-plan-text {
            display: flex;
            color: @content-text-color;
            line-height: 24px;
          }
          .cms-current-plan-name {
            display: flex;
            align-items: center;
            color: @primary-color;
            font-weight: bold;
            line-height: 26px;
            font-size: @h4-font-size;
            &-interval {
              color: @text-dark-theme;
              font-size: 16px;
              font-weight: 700;
              line-height: 26px;
            }
            &-trial {
              color: @danger-color;
            }
          }
        }
      }
    }
  }
  .ant-card-body {
    padding: 16px;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    color: @content-text-color;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 12px;
    font-size: @h4-font-size;
    align-items: center;
    &-cancel {
      color: @text-dark-theme;
    }
    &-cancel-subscription {
      margin-top: 24px;
      &-btn {
        display: flex;
        align-items: center;
        color: @danger-color;
        border: 0;
        background-color: @background-color;
        border-radius: 8px;
        padding: 0.375rem 0.75rem;
        line-height: 20px;
        font-size: @h6-font-size;
        svg path {
          fill: @danger-color;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:hover {
          color: @danger-color;
          background-color: @stroke-color;
          svg path {
            fill: @danger-color;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
        .cms-leave-text {
          padding-left: 0.4rem;
        }
      }
    }
  }
  &-date-amount {
    color: @primary-color;
    font-weight: 700;
    line-height: 24px;
  }
  &-text {
    line-height: 24px;
  }
  &-upgrade-box {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: @background-color;
    padding: 0.5rem 1rem;
    border: 1px solid @stroke-color;
    border-radius: 300px;
    color: @primary-color;
    font-weight: 500;
    &-text {
      color: @content-text-color;
      font-weight: 400;
    }
  }
  .cms-update-btn-block {
    display: flex;
    justify-content: flex-end;
  }
  .cms-payment-method-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-left-section {
      display: flex;
      align-items: center;
    }
    .cms-update-detail-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.375rem 1rem;
      background: @primary-color;
      border-radius: 8px;
      color: @white-color;
      font-size: @h6-font-size;
      font-weight: 500;
      border: 0;
      &:hover {
        background-color: @content-text-color;
      }
    }
  }
  .cms-change-plan-btn {
    display: flex;
    align-items: center;
    background-color: @brand-color;
    color: @white-color;
    max-height: 32px;
    max-width: 105px;
    font-size: @h6-font-size;
    font-weight: 500;
    border: 0;
    &:hover {
      background-color: @royal-blue;
    }
  }
  .cms-border-radius {
    border-radius: 300px;
    padding: 0.25rem 1rem;
    max-width: unset;
    &:hover {
      background: @royal-blue;
    }
    &-text {
      font-size: @h5-font-size;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
