@icon-background-size: 40px;

.ant-layout-sider {
  height: 100vh;
  overflow-y: auto;
  transition: width 0.2s ease-in;
  background-color: @primary-color;
  &-children {
    display: flex;
    flex-direction: column;
    .app-sidebar-content {
      .ant-menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: @primary-color;
        border-right: none;
        &-item {
          transition: width 0.2s ease-in;
          display: flex;
          justify-content: center;
          align-items: center;
          width: @icon-background-size;
          height: @icon-background-size;
          padding: 0 !important;
          margin: 8px 8px 16px 8px;
          border-radius: 50%;
          svg {
            path {
              fill: @text-dark-theme;
            }
          }
          &::after {
            border-right: none;
          }
          &:hover {
            &-icon {
              path {
                fill: @white-color;
              }
            }
          }
        }
        &-item-selected {
          background: rgba(255, 255, 255, 0.12);
          border-radius: 8px;
          .ant-menu-item-icon {
            path {
              fill: @white-color;
            }
          }
        }
        &-item-active {
          transition: all 0.2s ease-in-out;
          background: rgba(255, 255, 255, 0.12);
          border-radius: 8px;
          &:hover {
            .ant-menu-item-icon {
              path {
                fill: @white-color;
              }
            }
          }
        }
      }
    }
    .app-layout-sider-footer {
      background-color: @primary-bg-user-profile;
      .user-profile-icon {
        position: relative;
        cursor: pointer;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        .cms-user-avatar {
          font-size: @h6-font-size !important;
        }
        .cms-space-avatar-sider {
          border: 1px solid @content-text-color;
          border-radius: 4px;
        }
      }
      .space-icon-selected {
        svg {
          path {
            fill: @brand-color;
          }
        }
      }
      .cms-space-selection {
        border-bottom: 1px solid @white-color;
      }
    }
    .app-layout-sider-header {
      margin-bottom: 16px;
    }
  }
}
.limit-reached {
  position: absolute;
  bottom: 56px;
  width: 56px;
  .ant-popover-content {
    border: 1px solid @stroke-color;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
.limit-reached-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  padding: 8px;
  cursor: pointer;
}
.limit-reached-content {
  border-radius: 8px;
  background-color: @white-color;
  position: absolute;
  bottom: 0px;
  left: 72px;
  z-index: 999;
}
.hide-sider {
  display: none;
}

@media only screen and (max-width: 991px) {
  .ant-layout-sider {
    &-children {
      .app-sidebar-content {
        .ant-menu {
          &-item {
            width: 100%;
            height: unset;
            justify-content: flex-start;
            border-radius: 0;
            span {
              color: @white-color;
            }
          }
          &-item-selected {
            span {
              color: @primary-color;
            }
          }
        }
      }
    }
  }
}
.ant-popover {
  .ant-popover-content {
    width: 287px;
  }
  .ant-popover-title {
    font-size: @font-size-base;
    color: @primary-color;
    font-family: "Inter", sans-serif;
    border-bottom: none;
    padding: 16px 16px 0px;
  }
  .ant-popover-inner-content {
    font-size: @font-size-sm;
    color: @content-text-color;
    font-family: "Inter", sans-serif;
    padding: 8px 16px 16px;
    .ant-btn {
      width: 100%;
      margin-top: 8px;
      margin-left: 0px;
    }
  }
}
.cms-space-popover {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  & > a {
    width: inherit;
    height: 40px;
    padding: 0.5rem;
  }
  .cms-popover-content {
    display: flex;
    flex-direction: row;
    width: inherit;
    align-items: center;
    justify-content: space-between;
    &-avatar {
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 13.33px !important;
      font-family: "Inter", sans-serif;
      min-height: 24px;
      min-width: 24px;
    }
    &-block {
      display: flex;
      flex-direction: row;
      width: inherit;
      position: relative;
    }
  }
  .cms-space-name-popover {
    color: @content-text-color;
    font-weight: 500;
    &:hover {
      color: @primary-color !important;
    }
  }
  .cms-create-new-space {
    padding: 0.5rem;
    &-icon {
      display: flex;
      align-items: center;
    }
    .cms-create-space-text {
      color: @content-text-color;
    }
  }
}
.cms-space-popover-active {
  background: @background-color;
  border-radius: 8px;
  .cms-space-name-popover {
    color: @space-popover;
  }
}
.cms-create-new-space-modal {
  display: flex;
  width: 100% !important;
  max-width: 680px;
  .ant-modal-header {
    max-height: 64px;
  }
  .ant-modal-body {
    padding: 1.5rem 2.5rem;
  }
}
.cms-menu-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: inherit;
  .cms-sidebar-bottom {
    .cms-sidebar-icon-bg {
      padding: 4.333px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @primary-bg-user-profile;
      border: 3px solid @primary-dark;
      border-radius: 216.667px;
      .cms-svg-noti {
        path {
          fill: @white-color;
        }
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }
    }
    .cms-sidebar-notification-bg {
      padding: 4.333px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @primary-color;
      border: none;
      border-radius: 216.667px;
      .cms-svg-noti {
        path {
          fill: @white-color;
        }
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }
    }
    &-upper-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
.cms-google-space-sider-logo {
  position: absolute;
  left: 29px;
  z-index: 4;
  top: 27px;
  svg {
    border: 2px solid @primary-bg-user-profile;
    border-radius: 20px;
  }
}
.space-list-logo {
  top: 12px;
  left: 16px;
  z-index: 4;
  svg {
    border: 0;
  }
}
.space-switch-content {
  flex-direction: column;
  .text {
    color: @content-text-color;
    font-size: @h5-font-size;
    line-height: 24px;
  }
}
.cms-google-space-modal {
  width: 100% !important;
  min-height: 560px;
  max-width: 880px;
  .ant-modal-body {
    padding: 4.688rem 6.25rem;
  }
  &-content {
    flex-direction: column;
    &-text {
      text-align: center;
      font-weight: 600;
      font-size: @size-24;
      line-height: 32px;
      color: @primary-color;
    }
  }
  .cms-continue-google-btn {
    .cms-steps-action-button {
      background: @cornflower-blue;
      svg {
        height: 16px;
        width: 16px;
      }
      &:hover {
        background: @brand-color;
      }
    }
  }
}
