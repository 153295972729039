.space-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid @stroke-color;
  padding: 4px;
  height: 32px;
  border-radius: 4px;
  color: @primary-color;
  font-size: @font-size-base;
  min-width: fit-content;
  &-logo {
    margin-right: 8px;
    border-radius: 2px;
    height: 18px;
    width: 18px;
  }
}
.space-tag:not(:last-child) {
  margin-right: 4px;
}
.single-tag {
  margin: 0 8px;
  .space-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 420px;
    display: block;
  }
}
