.cms-import-done-wrapper {
  max-width: 532px;
  width: 100%;
  &-sign {
    display: flex;
    flex-direction: column;
    &-icon {
      margin-top: 29px;
      margin-bottom: 32px;
      svg {
        box-shadow: 0px 50.717px 82.4151px rgba(88, 94, 117, 0.14);
        border-radius: 100%;
      }
    }
    &-text {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: @h4-font-size;
      line-height: 24px;
      text-align: center;
      color: @primary-color;
    }
    &-content {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 400;
      font-size: @h4-font-size;
      line-height: 24px;
      color: @content-text-color;
      margin-bottom: 29px;
    }
  }
}