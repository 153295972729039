.change-password-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-form-item {
    margin-bottom: 0px;
    margin-top: 16px;
  }
  .ant-typography {
    color: @content-text-color;
    font-family: "Inter", sans-serif;
  }
  .change-password-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 16px;
    min-width: 580px;
    max-width: 580px;
    &-back-button {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-back-arrow {
      margin-right: 8px;
    }
    &-title {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 16px;
      font-family: "Inter", sans-serif;
    }
  }
  .change-password-form {
    padding: 24px;
    border-radius: @border-radius-base;
    border: 1px solid @stroke-color;
    min-width: 580px;
    max-width: 580px;
    .ant-form-item-label > label {
      color: @content-text-color;
      font-size: 12px;
    }
  }
  .change-password-button {
    margin-top: 16px;
  }
}
.success-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-message {
    font-size: @h2-font-size;
    font-family: "Inter", sans-serif;
    text-align: center;
    max-width: 360px;
  }
  &-image {
    border-radius: 100%;
    box-shadow: 0px 36.2264px 58.8679px rgba(88, 94, 117, 0.14);
    height: 120px;
    margin-bottom: 24px;
    border-radius: 100%;
  }
}
.cms-submit-change-pwd-btn {
  display: flex;
  justify-content: flex-end;
}
