.contact-user-info-wrapper {
  border-radius: 8px 8px 0 0;
  padding: 1rem;
  margin-top: @wrapper-margin-top;
  background-color: @background-color;
  @media screen and (max-width: 1024px) {
    margin-top: 45px;
  }
  .contact-user {
    &-row {
      .contact-user-avatar-col {
        margin-top: -(@wrapper-margin-top + @avatar-size) / 2;
      }
      .contact-user-more-col {
        display: flex;
        justify-content: flex-end;
        padding-right: 4px;
        svg {
          cursor: pointer;
        }
        .ant-dropdown-open {
          background-color: @white-color;
          border-radius: 200px;
        }
      }
    }
  }
  .contact-user-meta {
    &-row {
      .contact-user-profile-col {
        .ant-row {
          .ant-col {
            .username {
              color: @primary-color;
              font-size: @h4-font-size;
              line-height: 24px;
              margin-right: 10px;
            }
            .social-icon-link {
              color: inherit;
              .svg-wrapper {
                &.contact-data-meta-social-icon {
                  padding: 3px;
                  line-height: 0;
                  svg {
                    width: 16px;
                    height: 16px;
                    path {
                      fill: #192039;
                    }
                    &:hover {
                      path {
                        fill: #585e75;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .contact-user-meta-info-col {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 34px;
        .svg-wrapper {
          padding: 0 12px;
          svg {
            cursor: pointer;
            path {
              fill: @primary-color;
            }
            &:hover {
              path {
                fill: @content-text-color;
              }
            }
          }
        }
        .ant-divider {
          height: 20px;
          border-color: @stroke-color;
        }
      }
    }
  }
  &-skeleton {
    border-radius: 8px 8px 0 0;
    padding: 1rem;
    margin-top: @wrapper-margin-top;
    background-color: @background-color;
    margin-bottom: 16px;
  }
}

.reminder-dropdown {
  margin-right: 8px;
  &-btn {
    border-radius: 300px;
  }
  .ant-dropdown-open {
    background-color: @white-color;
    border-radius: 200px;
  }
  .ant-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 200px;
    max-height: 24px;
    margin-right: 0;
    svg {
      height: 16px;
      width: 16px;
      margin-right: 4px;
      path {
        fill: @primary-color;
      }
    }
    .reminder-text {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: @h6-font-size;
      line-height: 16px;
      color: @content-text-color;
    }
    &:hover {
      background: @stroke-color;
    }
  }
}
.cms-doc-note-count-tag {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: @white-color;
  border-radius: 8px;
  border: 0;
  height: 24px;
  cursor: initial;
  pointer-events: none;
  span {
    color: @brand-color;
  }
  &:first-child {
    margin-right: 8px;
  }
  svg {
    height: 16px;
    width: 16px;
    margin-right: 4px;
    path {
      fill: @content-text-color;
    }
  }
}
.cms-merge-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  z-index: 10;
  position: absolute;
  right: 0;
  &-merge-icon {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    &-text {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: @h5-font-size;
      line-height: 22px;
      color: @content-text-color;
      margin-left: 5px;
    }
  }
  .cms-delete-contact-common-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.367rem 1rem;
    border-radius: 8px;
    border: 0;
  }
  .cms-delete-contact-delete-btn {
    background: @white-color;
    margin-right: 16px;
    &-text {
      font-weight: 500;
      font-size: @h6-font-size;
      line-height: 20px;
      color: @danger-color;
    }
    &:hover {
      background: @delete-hover;
    }
  }
  .cms-delete-contact-restore-btn {
    background: @brand-color;
    &-text {
      font-weight: 500;
      font-size: @h6-font-size;
      line-height: 20px;
      color: @white-color;
    }
    &:hover {
      background: @royal-blue;
    }
  }
}
.cms-active-merge-contact {
  height: 8px;
  width: 8px;
  background: @brand-color;
  border-radius: 200px;
  position: absolute;
  margin-top: 9px;
  margin-left: 8px;
}
.cms-merge-contact-upper-box-border {
  border: 1px solid @stroke-color;
  border-bottom: 0;
}
.cms-username-top {
  margin-top: 12px;
}
.cms-contact-detail-box {
  border-radius: 8px;
}
.cms-value-divider {
  border-left: 1px solid @text-dark-theme;
}
.cms-contact-val-hover:hover {
  color: @brand-color;
}
.flex-start {
  align-items: flex-start !important;
}
