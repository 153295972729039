.progress-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
}
.progress-box {
  background-color: white;
  text-align: center;
  width: 356px;
  box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.12);
  &-image {
    padding: 24px;
    border: 1px solid @stroke-color;
    border-radius: 8px;
  }
  &-title {
    margin-bottom: 24px;
  }
}

.ant-progress-bg {
  background-color: @brand-color;
}
.ant-carousel .slick-dots li.slick-active {
  opacity: 1;
  button {
    background: @brand-color;
    height: 6px;
    width: 16px;
    border-radius: 24px;
  }
}
.ant-carousel .slick-dots li button {
  background: @text-dark-theme;
  opacity: 0.4;
  height: 6px;
  width: 6px;
  border-radius: 24px;
}
.ant-carousel .slick-dots li {
  width: 6px;
}
.file-list {
  height: 179px;

  &-title {
    margin-bottom: 12px;
    position: sticky;
    top: 0px;
  }
  &-item {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 24px;
    &-icon {
      margin: 12px !important;
      &-errored {
        margin: 12px !important;

        path {
          fill: @danger-color;
        }
      }
    }
    &-name {
      color: @content-text-color;
      &-errored {
        color: @danger-color;
      }
    }
    &-errored {
      border: 1px solid @danger-color;
      border-radius: 4px;
      color: @danger-color;
    }
  }
  &-in-app {
    border: 1px solid @stroke-color;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-error {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .ant-row {
      width: 100%;
    }
    .ant-progress-inner {
      svg {
        .ant-progress-circle-path path {
          stroke: @brand-color;
        }
      }
    }
    .ant-progress-circle {
      .ant-progress-text {
        display: none;
      }
    }
    &-icon {
      margin-right: 12px !important;
    }
    &-name {
      color: @content-text-color;
    }
    &-errored {
      border: 1px solid @danger-color;
      border-radius: 4px;
      color: @danger-color;
    }
  }
}
.error-message {
  color: @danger-color;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
}
.info-box-slider {
  background-color: @background-color;
  text-align: center;
  margin-bottom: 24px;
  width: 356px;
  height: 230px;
  padding: 50px;
  @media (max-width: 768px) {
    background-color: white;
    border: 1px solid @stroke-color;
    border-radius: @border-radius-base;
  }
  .h5.ant-typography {
    color: @primary-color;
  }
}
