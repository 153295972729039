.warning-container-yellow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  background: @bg-warning-yellow;
  border: 1px solid @warning-border-btn-yellow;
  color: @content-text-color;
  border-radius: 8px;
  margin: 16px 0;
  &-label {
    display: flex;
    align-items: center;
    background: @warning-border-btn-yellow;
    border-radius: 10px;
    color: @white;
    padding: 2px 10px;
    font-size: @h6-font-size;
  }
}

.warning-container-red {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  background: @bg-warning-red;
  border: 1px solid @warning-border-btn-red;
  border-radius: 8px;
  color: @warning-border-btn-red;
  margin: 16px 0;
  &-label {
    display: flex;
    align-items: center;
    background: @warning-border-btn-red;
    border-radius: 10px;
    color: @white;
    padding: 2px 10px;
    cursor: pointer;
    font-size: @h6-font-size;
    .cms-renew-link {
      display: flex;
      align-items: center;
      color: @white-color;
    }
  }
  .cms-renew-btn {
    margin-right: 4px;
  }
}
