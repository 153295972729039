.app-layout {
  height: 100vh;
  overflow: hidden;
  .ant-layout-content {
    height: 100%;
    .app-content-wrapper {
      height: inherit;
      .dashboard-row {
        flex-flow: nowrap;
        .cms-no-plan {
          width: 100%;
        }
        .cms-no-contact-selected {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          background-image: url("/assets/images/onboard-bg.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 2;
          &-img {
            z-index: 4;
            margin-left: 61px;
          }
        }
        .cms-no-reminder-selected {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
          z-index: 2;
          img {
            height: 375px;
            width: 375px;
            margin-bottom: 30px;
          }
          &-text {
            font-weight: 500;
            font-size: @h3-font-size;
            line-height: 30px;
            text-align: center;
            color: @content-text-color;
          }
        }
        .cms-no-contact-selected:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }
    }
  }
  .cms-trial-plan-msg {
    max-height: 36px;
    box-shadow: 0px -1px 0px @warning-border-btn-yellow;
    border-bottom: 0;
    padding: 0.375rem;
    .cms-subs-header-user-message-text {
      font-weight: 400;
    }
    .cms-bold-text {
      font-weight: bold;
    }
    .cms-plan-upgrade {
      display: flex;
      align-items: center;
      padding: 3px 0.75rem;
      background: @black-color;
      border-radius: 30px;
      max-width: 100px;
      max-height: 24px;
      border: 0;
      &-text {
        display: flex;
        align-items: center;
        line-height: 18px;
        color: @white-color;
        font-size: @h6-font-size;
      }
      &:hover {
        background: @content-text-color;
      }
    }
  }
  .cms-message-error {
    background: @bg-warning-red;
    box-shadow: 0px -1px 0px @warning-border-btn-red;
  }
}