.cms-progress-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 360px;
  &-icon {
    margin-top: 58px;
    margin-bottom: 48px;
  }
  &-progress-bar {
    margin-bottom: 58px;
    width: 100%;
    .ant-progress-line {
      max-width: 312px;
      .ant-progress-inner .ant-progress-bg {
        height: 6px !important;
      }
    }
  }
}