.cms-web-mobile {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem 0 1rem;
  justify-content: space-between;
  overflow: hidden;
  background-color: @white-color;
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .font-primary {
      color: @primary-color;
    }
    .font-heading {
      font-size: 30px;
      line-height: 40px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    p {
      margin-bottom: 24px;
      color: @content-text-color;
    }
    &-image {
      margin-bottom: 21.33px;
    }

    &-back-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      background: @background-color;
      border-radius: 4px;
      margin-top: 24px;
      cursor: pointer;
      border: 0;
      .back-btn-mobile {
        color: @content-text-color;
        font-size: @h4-font-size;
        font-weight: 500;
        line-height: 24px;
      }
      &:hover {
        background-color: @stroke-color;
      }
    }
    .cms-mobile-logout {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      text-decoration: underline;
    }
  }
  .cms-app-download {
    display: flex;
    .cms-apple-btn-padding {
      padding-right: 1.25rem;
    }
    .cms-apple-store {
      display: flex;
      justify-content: center;
      background: @black-color;
      color: @white-color;
      height: 40px;
      align-items: center;
      padding: 1.5rem 1rem;
      .cms-apple-icon {
        height: 25px;
        width: 21px;
        margin-right: 0.5rem;
      }
      .cms-apple-btn-text {
        display: flex;
        flex-direction: column;
        &-p1 {
          font-size: @h6-font-size;
        }
        &-p2 {
          font-size: @h1-font-size;
        }
      }
    }
  }
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    align-items: flex-start;
    margin: 32px 0 38px 0;
    .cms-or-text {
      color: @content-text-color;
      font-size: @h6-font-size;
    }
  }
  .cms-desktop-section {
    display: flex;
    align-self: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 430px);
    .cms-go-back-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .cms-mobile-logout {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        text-decoration: underline;
      }
    }
    .cms-desktop-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        margin-bottom: 0.6rem;
      }
    }
  }
  .cms-contactbook-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    svg {
      margin-right: 0.25rem;
      height: 24px;
      width: 128px;
    }
    .cms-logo-text {
      color: @logo-text;
    }
  }
}
