.contact-info-mobile {
  display: flex;
  flex-direction: row;
  &-phone {
    padding-right: 1.8rem;
    height: 24px;
    width: 24px;
  }
  &-number {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dde1ee;
    width: 100%;
    .contact-info-wrapper {
      display: flex;
      flex-direction: column;
      &-info {
        display: flex;
        padding-bottom: 0.5rem;
        align-items: center;
        &.notes {
          align-items: unset;
        }
      }
      &-add-row {
        cursor: pointer;
        justify-content: start;
        align-items: center;
        .contact-add-row {
          display: flex;
          margin-right: 0.5rem;
        }
      }
      &-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: @h6-font-size;
      }
      .cms-contact-detail-field {
        display: flex;
        align-items: center;
        &.notes {
          word-break: break-all;
          white-space: unset;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  &-border {
    border-bottom: 1px solid @stroke-color;
    padding-top: 1rem;
  }
}
.contact-info-mobile:last-child {
  .contact-info-mobile-number {
    border: none;
    padding: 0;
  }
}
.cms-highlight-text {
  color: @brand-color;
}
