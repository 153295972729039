.space-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  height: 100%;
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid @stroke-color;
    font-size: @font-size-base;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    &-left {
      display: flex;
    }
    &-avatar {
      border-radius: 6px;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      font-size: @size-10;
      font-weight: 600;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin-top: 12px;
    border: 1px solid @stroke-color;
    border-radius: 4px;
    &-head {
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 12px;
    }
    &-items {
      cursor: pointer;
    }
  }
  &-bottom {
    background: @background-color;
    &-content {
      padding: 12px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      &-icon {
        margin-right: 8px;
      }
    }
  }
  .create-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 21px 10px 12px;
    width: 100%;
    background: @menu-item-accent;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    margin-top: 12px;
    &-title {
      margin-left: 5px;
      color: @white-color;
    }
    &-contact-space {
      fill: @white-color;
    }
  }
  .cms-shared-user-space-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 12px;
    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('/assets/images/newSpaceBG.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0.75rem 2rem;
      height: 115px;
      width: 154px;
      &:before {
        content: '';
        position: absolute;
        height: 115px;
        width: 154px;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
    &-info {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: @h5-font-size;
      line-height: 22px;
      text-align: center;
      color: @content-text-color;
    }
    &-create-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75rem;
    }
  }
}
.color-blue {
  color: @brand-color;
}
.icon-color-change {
  fill: @brand-color;
}
