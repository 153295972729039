.cms-account-list {
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  border-radius: 8px;
  .cms-account-list-header {
    &-title {
      display: flex;
      font-size: @h5-font-size;
      line-height: 24px;
      align-items: center;
      color: @primary-color;
      padding: 0.75rem;
      border-bottom: 1px solid @stroke-color;
    }
  }
}