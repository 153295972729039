.tags-list {
  .ant-list-items {
    .ant-list-item {
      border-radius: 4px;
      padding: 0.75rem 0.75rem 0.5rem;
      border-bottom: none;
      cursor: pointer;
      &:hover {
        .ant-list-item-meta-title {
          color: @primary-color;
        }
        .ant-list-item-meta-avatar {
          svg {
            path:first-child {
              fill: @primary-color;
            }
          }
        }
      }
      &-meta {
        align-items: center;
        &-avatar {
          margin-right: 8px;
          svg {
            path:first-child {
              fill: @content-text-color;
            }
          }
        }
        &-content {
          display: flex;
          .cms-tag-title {
            display: flex;
            &-text {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: auto;
            }
          }
        }
        &-title {
          max-width: 85%;
        }
      }
    }
    .selected-list {
      .ant-list-item {
        &-meta {
          &-avatar {
            svg {
              path:first-child {
                fill: @brand-color !important;
              }
            }
          }
          &-content {
            .ant-list-item-meta-title {
              color: @brand-color;
            }
          }
          &:hover {
            .ant-list-item {
              &-meta {
                &-avatar {
                  svg {
                    path:first-child {
                      fill: @accent-color;
                    }
                  }
                }
                &-title {
                  color: @accent-color;
                }
              }
            }
          }
        }
      }
    }
  }
  .cms-list-user-avatar {
    font-size: @size-10 !important;
    border-radius: 2px;
    height: 20px;
    width: 20px;
  }
}
