.cms-space-sharing-modal {
  width: 680px !important;
  max-width: 100%;
  .ant-modal-body {
    padding: 1rem 1.5rem;
  }
  .cms-space-share-section {
    display: flex;
    background: @white-color;
    width: 100%;
    max-width: 632px;
    height: 100%;
    flex-direction: column;
    &-block {
      display: flex;
      width: 100%;
    }
    .upgrade-popup-container::after, .upgrade-popup-container::before {
      content: none;
    }
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 24px;
      width: 24px;
      path {
        fill: @content-text-color;
      }
    }
  }
}
.cms-disable-close-btn {
  .ant-modal-close {
    opacity: 0.5;
    pointer-events: none;
  }
}