.no-group {
  justify-content: center;
  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    background: linear-gradient(
      218.52deg,
      @background-color -10.29%,
      @white-color 103.98%
    );
    box-shadow: 0px 22.3396px 36.3019px rgba(88, 94, 117, 0.14);
    border-radius: 200px;
  }
  &-text {
    color: @text-dark-theme;
    font-size: 16px;
    padding-top: 1.5rem;
  }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
    padding: 2rem 3rem;
  }
  &-create-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2.8rem;
    background: @brand-color;
    border-radius: 8px;
    color: @white-color;
    &:hover {
      background: @royal-blue;
      color: @white-color;
      .no-group-svg {
        svg {
          fill: @white-color;
        }
      }
      span {
        color: @white-color;
      }
    }
    .no-group-svg {
      fill: @white-color;
      margin-right: 0.5rem;
    }
  }
  .no-contact-btn-wrapper {
    flex-direction: column;
    text-align-last: center;
  }
  .no-group-or {
    padding: 1rem 0;
    color: @text-dark-theme;
  }
  .existing-btn {
    background: @primary-color;
    &:hover {
      background: @btn-hover;
      .no-group-svg {
        fill: @primary-color;
      }
      span {
        color: @white-color;
      }
    }
    &:focus {
      span {
        color: @white-color;
      }
    }
    &:active {
      span {
        color: @white-color;
      }
    }
  }
  .existing-btn-disable {
    background: @grey-3;
    &:hover {
      background: @grey-3 !important;
    }
  }
}
