.cms-owner-change-popup {
  width: 306px !important;
  .ant-modal-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    border: 0;
    .ant-modal-title {
      font-size: @h4-font-size;
      color: @primary-color;
      font-family: "Inter", sans-serif;
    }
  }
  .ant-modal-body {
    .popup-content {
      display: flex;
      .ant-radio-group {
        width: 100%;
      }
      .ant-space {
        display: flex;
        flex-direction: column;
        width: 100%;
        .ant-space-item {
          margin-bottom: 17px !important;
        }
        .ant-radio-wrapper {
          display: flex;
          align-items: center;
          span:nth-child(2) {
            display: flex;
            align-items: center;
            color: @content-text-color;
          }
          .ant-radio-checked .ant-radio-inner::after {
            background: @brand-color;
          }
        }
      }
      .cms-change-owner-avatar {
        font-size: @size-10 !important;
        margin-right: 8px;
        border-radius: 8px;
        height: 24px;
        width: 24px;
      }
    }
  }
  .ant-modal-footer {
    border: 0;
    button {
      border: 0;
      color: @content-text-color;
      font-size: @h5-font-size;
      font-family: "Inter", sans-serif;
      &:disabled {
        color: @content-text-color;
      }
    }
    .ant-btn-primary {
      color: @white-color;
    }
  }
}