.basic-flex {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-flex-start {
  display: flex;
  align-items: flex-start;
}

//! Font Family

.font-lato {
  font-family: "Lato", sans-serif;
}

.country-state-dropdown {
  height: 36px;
  width: 100%;
  background-color: @white-color;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  color: @text-dark-theme;
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.font-kalam {
  font-family: "Kalam", cursive;
}
//! Fix Div

.cms-fix-div {
  position: sticky;
  top: 0;
  z-index: 1;
}

//! SVGs

.cms-svg {
  width: 30px;
  height: 30px;
}

.cms-svg-lg {
  width: 48px;
  height: 48px;
}

.cms-svg-md {
  width: 22px;
  height: 22px;
}

.cms-svg-regular {
  width: 24px;
  height: 24px;
}

.cms-svg-sm {
  width: 18px;
  height: 18px;
}

.cms-svg-xl {
  width: 20px;
  height: 20px;
}

.cms-svg-16 {
  width: 16px;
  height: 16px;
}

.cms-common-svg {
  path {
    fill: @content-text-color;
  }
}

.cms-icon-btn {
  &:extend(.flex-all-center);
  background-color: @background-color;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 200px;
  min-width: 0;
  &:hover,
  &:focus {
    background-color: @stroke-color;
  }
}

//! Text Alignment

.cms-text-center {
  text-align: center;
}

//! Font

.cms-text-strong {
  font-weight: 500;
}

.cms-font-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//! Scrollbar
//! sidebar
.cms-scrollbar {
  &::-webkit-scrollbar {
    width: @scrollbar-width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: @scrollbar-track-background;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: @scrollbar-color;
    &:hover {
      background: @scrollbar-hover-color;
    }
  }
}
.cms-contact-scrollbar {
  &::-webkit-scrollbar {
    width: @scrollbar-width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background: @background-color;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: @stroke-color;
    &:hover {
      background: @scrollbar-hover-color;
    }
  }
}

//! Loading & Error

.cms-loading-wrapper,
.cms-error-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cms-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-result-image {
    margin: unset;
  }
}

//! Common Loader

.app-common-loader {
  width: 60px;
  height: 60px;
  height: inherit;
  margin: auto;
  display: flex;
  align-items: center;
}

//! NProgress Customization

#nprogress {
  .bar {
    background: @primary-color;
  }
  .peg {
    box-shadow: 0 0 10px @primary-color, 0 0 5px @primary-color;
  }
  .spinner-icon {
    border-top-color: @primary-color;
    border-left-color: @primary-color;
  }
}

//! Avoid Text Selection

.cms-noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//! Contact Name Initial circle

.cms-contact-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  &-initial {
    color: @white-color;
  }
}

.no-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    background: linear-gradient(218.52deg, #f2f4f7 -10.29%, #ffffff 103.98%);
    box-shadow: 0px 22.3396px 36.3019px rgba(88, 94, 117, 0.14);
    border-radius: 200px;
  }
  &-text {
    color: #a4aabe;
    font-size: 16px;
    padding-top: 1.5rem;
  }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
  }
  &-create-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2.8rem;
    font-weight: bold;
    background: @brand-color;
    border-radius: 8px;
    color: @white-color;
    width: 100%;
    &:hover {
      .no-group-svg {
        fill: @white-color;
      }
      span {
        color: @white-color;
      }
    }
    &:active {
      &:hover {
        .no-group-svg {
          fill: @white-color;
        }
      }
      .no-group-svg {
        fill: @brand-color;
      }
    }
    &:focus {
      &:hover {
        .no-group-svg {
          fill: @white-color;
        }
      }
      .no-group-svg {
        fill: @brand-color;
      }
    }
    .no-group-svg {
      fill: @white-color;
      margin-right: 0.5rem;
    }
  }
  .no-contact-btn-wrapper {
    flex-direction: column;
    text-align-last: center;
  }
  .no-group-or {
    padding: 1rem 0;
    color: @text-dark-theme;
  }
  .existing-btn {
    &:hover {
      background: @btn-hover;
      border: none;
      .no-group-svg {
        fill: @white-color;
      }
      span {
        color: @white-color;
      }
    }
  }
}
.no-contact {
  padding: 8rem 5rem 16rem 5rem;
  background: @background-color;
  height: calc(100vh - 116px);
}
.cms-no-contact-list-height {
  height: calc(100vh - 60px);
}
.no-existing-contact {
  padding: 8rem 5rem;
  height: calc(100vh - 116px);
}

.ant-select-dropdown {
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  box-shadow: 0px 12px 30px rgb(88 94 117 / 12%);
  border-radius: 8px;
  width: 160px !important;
}

.hide {
  display: none;
}
.cb-loading-wrapper {
  display: flex;
  justify-content: center;
  .anticon.anticon-loading.anticon-spin.ant-spin-dot {
    color: @brand-color;
  }
}
.cms-center-loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.cms-menu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 128px;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.12) !important;
  border-radius: 8px;
  &-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem !important;
    margin: 0 !important;
    &-svg {
      margin-right: 8px;
      display: flex;
      align-items: center;
      path {
        fill: @content-text-color;
      }
    }
    .cms-delete {
      svg path {
        fill: @danger-color;
      }
    }
  }
  .cms-delete-text {
    color: @danger-color;
  }
  .ant-menu-item-active {
    background: @background-color;
  }
  .ant-menu-item-selected {
    background-color: @background-color !important;
  }
}
.width-percent-50 {
  width: 50% !important;
}
.op-0 {
  opacity: 0;
}
.op-05 {
  opacity: 0.5;
}
.cms-overlay {
  opacity: 0.5;
  pointer-events: none;
}
.cms-notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  min-width: 320px;
  width: min-content;
  left: 0px;
  top: 4px;
  box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.28);
  border-radius: 4px;
  .ant-notification-notice-icon {
    svg {
      fill: @notification-icon;
    }
  }
  .ant-notification-notice-message {
    font-size: @h5-font-size;
    font-family: "Inter", sans-serif;
    color: @content-text-color;
    margin-left: 33px;
    margin-top: 2px;
    line-height: 20px;
  }
  .ant-notification-notice-close {
    top: 18px;
  }
}
.cms-notification-success {
  background: linear-gradient(
    @notify-success-top 8%,
    @notify-success-background 0
  );
}
.cms-notification-error {
  background: linear-gradient(@notify-error-top 8%, @bg-warning-red 0);
}
.cms-notification-warning {
  background: linear-gradient(@notify-warning-top 8%, @bg-warning-yellow 0);
}
.ant-tabs-content {
  height: 100%;
}

.position-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.ant-tabs-tabpane {
  position: relative;
}

.full-height {
  height: 100%;
}
.height-100-vh {
  height: 100vh;
}
.font-ellipsis {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: @text-dark-theme;
}
.ant-input:focus {
  border-color: @brand-color;
  outline: 0;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @text-dark-theme;
  border-right-width: unset;
}
.ant-input-affix-wrapper:focus {
  border-color: @brand-color !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:focus-visible {
  border-color: @brand-color !important;
}
.ant-input-affix-wrapper-focused {
  border-color: @brand-color !important;
  box-shadow: unset;
  border-right-width: unset;
}

.ant-picker:hover {
  border-color: @text-dark-theme;
}

.ant-picker-focused {
  border-color: @brand-color;
  box-shadow: none !important;
}

.ant-select:hover .ant-select-selector {
  border-color: @text-dark-theme !important;
  box-shadow: none !important;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: @brand-color !important;
  box-shadow: none !important;
}
.cms-pageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
}
.ant-picker {
  padding: 0 0.5rem 0 0;
  & > .ant-picker-input input {
    padding: 0.494rem 0.688rem;
  }
}
.ant-input-affix-wrapper {
  padding: 0 0.688rem 0 0;
  & > input.ant-input {
    padding: 0.5rem 1rem 0.5rem 0.25rem;
    &::placeholder {
      font-size: @h5-font-size;
    }
  }
  .ant-input-lg {
    padding: 8.6px 11px 8.6px 4px;
    font-size: @h5-font-size;
  }
}
.ant-input-group {
  .ant-input-affix-wrapper {
    padding: 0 0.688rem;
  }
}
.ant-form-item-explain {
  font-size: @h6-font-size;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.mr-2 {
  margin-right: 2px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-24 {
  margin-right: 24px;
}
.username {
  color: @primary-color;
  font-size: @h4-font-size;
  line-height: 24px;
  font-weight: 500;
}
.mb-16 {
  margin-bottom: 16px;
}
.import-card-button {
  &:hover {
    background-color: @brand-color;
  }
}
.cursor-initial {
  cursor: initial;
}
.cms-remove-profile-btn {
  position: absolute;
  top: 0px;
  left: 38px;
  z-index: 2;
}
.ml-4 {
  margin-left: 4px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-24 {
  margin-left: 24px;
}

.m-auto {
  margin: auto;
}
.d-flex-center {
  display: flex;
  align-items: center;
}

.w-100-px {
  width: 100px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: @content-text-color;
}

.selected-list {
  .ant-list-item-meta {
    &-avatar {
      svg {
        path {
          fill: @brand-color;
        }
      }
    }
    &-content {
      color: @brand-color;
      .ant-list-item-meta-title a {
        color: @brand-color;
        font-weight: 700;
      }
    }
  }
}
.cms-skeleton-outer {
  border: 1px solid @stroke-color;
  border-radius: 8px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-34 {
  margin-bottom: 34px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
.ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}
.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}
.gap-8{
  gap: 8px
}
.noBorder {
  border: 0;
}
.no-margin {
  margin: 0;
}
.after-banner-height {
  height: @dynamic-height !important;
}
.h-inherit {
  height: inherit;
}
.d-flex {
  display: flex;
}
.op-1 {
  opacity: 1;
}
.active-bg {
  background: @active-bg;
}
.cms-dash-border {
  border: 1px dashed @stroke-color;
  width: 100%;
  max-width: 40px;
  margin: 10px;
}
.position-absolute {
  position: absolute;
}
.color-blue {
  color: @cornflower-blue;
}
.color-green {
  color: @green-color;
}
.color-orange {
  color: @orange-color;
}
.color-red {
  color: @red-color;
}
.overflow-auto {
  overflow: auto;
}
.text-decoration-underline {
  text-decoration: underline;
}
.pointer-none {
  pointer-events: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}
.display-flex-center {
  display: flex;
  align-items: center;
}
.scroll-y {
  height: calc(100vh - 69px);
  overflow-y: scroll;
}
.bb-feedback-button {
  // giving important to override package's css
  bottom: 10px !important;
}
