.cms-contact-note-list {
  overflow: auto;
  max-height: 475px;
  padding-top: 1rem;
  padding-left: 1rem;
  height: 100%;
}
.cms-no-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 500px);
}
