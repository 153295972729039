.cms-share-wrapper {
  .cms-space-sharing-box-block-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .cms-share-with-text {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: @h5-font-size;
      line-height: 24px;
      color: @content-text-color;
    }
    .cms-member-count {
      display: flex;
      align-items: center;
      .group-setting-total-contacts {
        display: flex;
        align-items: center;
        text-align: right;
        font-weight: 500;
        font-size: @h5-font-size;
        line-height: 24px;
        color: @content-text-color;
      }
    }
  }
  .cms-space-sharing-box-block-middle-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25rem !important;
    .cms-add-email-btn {
      background: @primary-color !important;
      border: 0 !important;
      padding: 0.375rem 1.5rem;
      &:hover {
        background-color: @content-text-color !important;
      }
      @media (max-width: 612px) {
        padding: 0.375rem !important;
        height: 28px !important;
        max-width: 28px !important;
        span {
          display: none !important;
        }
      }
      svg {
        height: 16px;
        width: 16px;
        path {
          fill: @white-color;
        }
      }
      .cms-upload-text {
        max-width: 100%;
        width: 97px;
        height: 32px;
        font-weight: 500;
        font-size: @h6-font-size;
        line-height: 20px;
        color: @white-color;
      }
    }
  }
}