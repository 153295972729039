.thankyou-page-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100vh;
  padding: 12px;
  .text-heading {
    font-size: x-large;
    font-weight: 700;
  }
  .text-sub-heading {
    font-size: large;
  }
  .download {
    margin-top: 20px;
  }
  .ant-card {
    width: 300px;
    .share-powered-by {
      p {
        text-align: center;
        margin-top: 24px;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        svg {
          height: 32px;
          width: auto;
        }
      }
    }
  }
}
