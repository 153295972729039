.cms-subs-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .space-details-card {
    max-width: 272px;
    padding: 12px;
    border-radius: 12px;
    background-color: @background-color;
    .ant-divider-horizontal {
      margin: 12px 0;
    }
    .member-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }
    .info-text {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
    }
    .member-count-text {
      font-weight: 700;
    }
    .member-count-red {
      color: @danger-color;
    }
    .member-count-blue {
      color: @brand-color;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0 1.5rem;
    &-left-section {
      display: flex;
      align-items: center;
      .cms-slider-text {
        display: flex;
        font-size: @h6-font-size;
        line-height: 16px;
        align-items: center;
        color: @primary-color;
      }
      .cms-user-slider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.125rem 0.125rem 0.125rem 0.5rem;
        width: 276px;
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 8px;
        position: relative;
        .ant-slider {
          width: 160px;
          .ant-slider-rail {
            background-color: @stroke-color;
            border-radius: 10px;
          }
          .ant-slider-track {
            background-color: @brand-color;
          }
          .ant-slider-handle {
            border: 3px solid @brand-color;
          }
        }
        .cms-slider-input {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 32px;
          background: @background-color;
          border: 1px solid @stroke-color;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 0.375rem;
          .ant-input-number-handler-wrap {
            display: none;
          }
          .ant-input-number-input-wrap {
            display: flex;
            text-align-last: center;
            .ant-input-number-input {
              height: auto;
              padding: 0;
              color: @content-text-color;
            }
          }
        }
        .ant-tooltip-arrow {
          display: none;
        }
        .ant-tooltip-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: @white-color;
          border: 1px solid @stroke-color;
          box-sizing: border-box;
          border-radius: 4px;
          min-height: 0;
          padding: 0px 0.313rem;
          font-size: @size-11;
          color: @black-color;
          position: relative;
          top: 8px;
          box-shadow: unset;
        }
        .ant-tooltip {
          left: 40px;
          top: -17px;
          position: absolute;
        }
      }
      .cms-subs-space-name {
        display: flex;
        align-items: center;
        padding: 0.125rem 0;
        width: 300px;
        border-radius: 8px;
        padding-right: 1.5rem;
        position: relative;
        .cms-subs-space-avatar {
          margin-right: 8px;
          border: 1.46667px solid @stroke-color;
          box-sizing: border-box;
          border-radius: 2px;
        }
        .cms-subs-space-text {
          align-items: center;
          font-weight: 500;
          font-size: @h3-font-size;
          line-height: 30px;
          color: @primary-color;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 75%;
          white-space: nowrap;
        }
        .ant-skeleton {
          display: flex;
          align-items: center;
          .ant-skeleton-avatar {
            height: 32px;
            width: 32px;
            line-height: 32px;
            border-radius: 2px;
          }
          .ant-skeleton-content {
            margin-top: 12px;
            .ant-skeleton-paragraph > li {
              width: 100% !important;
            }
          }
        }
        .space-list-logo {
          position: absolute;
          top: 22px;
          left: 18px;
          z-index: 4;
        }
      }
    }
    &-right-section {
      display: flex;
      flex-direction: column;
      padding: 16px 0 40px 0;
      .font-brand-color {
        color: @brand-color;
      }
      .annual-subs-text {
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        font-style: italic;
        color: @primary-color;
        margin-top: 6px;
      }
      .button-switch {
        display: flex;
        align-items: center;
        padding: 4px;
        width: 252px;
        height: 36px;
        background: @background-color;
        border: 1px solid @stroke-color;
        border-radius: 300px;
        .ant-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background-color: unset;
          flex: none;
          order: 0;
          flex-grow: 1;
          margin: 0px 0px;
          height: 28px;
          color: @content-text-color;
        }
        .button-inside-div {
          max-width: 100%;
          display: flex;
          flex-grow: 1;
          .active {
            background: @primary-color;
            border-radius: 300px;
            color: @white-color;
          }
        }
      }
    }
  }
  .cms-message-error {
    background: @bg-warning-red;
    border-bottom: 1px solid @warning-border-btn-red;
    &-text {
      color: @warning-border-btn-red;
    }
  }
}
.cms-subs-header-user-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.32rem 0.75rem;
  background: @bg-warning-yellow;
  border-bottom: 1px solid @warning-border-btn-yellow;
  z-index: 1;
  &-text {
    display: flex;
    align-items: center;
    font-size: @h5-font-size;
    line-height: 24px;
    color: @content-text-color;
  }
  .cms-subs-update-payment-btn {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
    padding: 0.125rem 0.5rem;
    background: @warning-border-btn-yellow;
    border-radius: 30px;
    color: @white-color;
    height: auto;
    font-size: @h6-font-size;
    border: 0;
    margin-left: 8px;
    &-text {
      margin-left: 0;
      margin-right: 6px;
    }
    &:hover {
      background: @white-color;
      color: @warning-border-btn-yellow;
    }
  }
}
