.space-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  .ant-picker {
    width: -webkit-fill-available;
  }
  .ant-input[disabled] {
    background: @background-color;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-form-item-label > label {
    color: @content-text-color;
  }
  .ant-typography {
    color: @content-text-color;
    font-family: "Inter", sans-serif;
  }
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    .ant-radio-inner {
      border-color: @content-text-color;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: @brand-color;
      &:after {
        background-color: @brand-color;
      }
    }
  }
  .ant-radio-group {
    display: flex;
    .ant-radio-inner::after {
      background: @brand-color;
    }
  }
  &-title {
    font-size: @font-size-lg;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin-bottom: 16px;
    min-width: 593px;
  }
  &-profile-form {
    min-width: 532px;
    max-width: 532px;
    height: inherit;
    .email-password-section {
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
    .radio-button {
      label {
        width: auto;
      }
    }
    &-social-login-text {
      display: flex;
      flex-direction: row;
      background: @background-color;
      border-radius: 8px;
      padding: 6px 12px;
      border: 1px solid @stroke-color;
      margin: 16px 0px;
      font-size: @font-size-sm;
      &-email {
        color: @primary-color;
        font-weight: 500;
      }
    }
    &-head {
      margin-bottom: 0px;
      &-avatar {
        margin-right: 8px;
      }
    }
    &-button span {
      font-family: "Inter", sans-serif;
    }
    .user-contact-card-option {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 232.94px;
      height: 65px;
      background: @white-color;
      border: 0.588235px solid @stroke-color;
      box-sizing: border-box;
      box-shadow: 0px 17.2549px 28.0392px rgba(88, 94, 117, 0.14);
      border-radius: 4.70588px;
      padding: 0.5rem;
      .user-contact-wrapper {
        display: flex;
        justify-content: space-between;
        .user-contact-card-div {
          display: flex;
          align-items: center;
          .user-avatar-circle {
            width: 28.24px;
            height: 28.24px;
            background: @stroke-color;
            border-radius: 117.647px;
          }
          .user-info-div {
            padding-left: 0.5rem;
            .user-name-div {
              width: 78.24px;
              height: 10.59px;
              background: @stroke-color;
              border-radius: 117.647px;
              margin-bottom: 0.3rem;
            }
            .user-post-div {
              width: 54.71px;
              height: 7.06px;
              background: @stroke-color;
              border-radius: 117.647px;
            }
          }
        }
        .user-select-option {
          display: flex;
          .user-mRight {
            margin-right: 0.5rem;
          }
          .user-phone-option {
            display: flex;
            align-items: flex-start;
            padding: 4.70588px;
            width: 19.41px;
            height: 19.41px;
            background: @brand-color;
            border-radius: 117.647px;
          }
        }
      }
      .user-contact-tag-wrapper {
        display: flex;
        padding-left: 2.3rem;
        .user-contact-tag {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px 7.05882px;
          width: 39.41px;
          height: 10.59px;
          background: @background-color;
          border: 0.588235px solid @stroke-color;
          box-sizing: border-box;
          border-radius: 117.647px;
        }
        .tag-mRight {
          margin-right: 0.2rem;
        }
      }
    }
    .user-set-contact-option {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      &-title {
        font-family: Inter;
        font-weight: 500;
        font-size: @h4-font-size;
        line-height: 26px;
        color: @primary-color;
      }
      &sub-title {
        font-size: @h5-font-size;
        color: @content-text-color;
      }
      .user-select-option {
        display: flex;
        align-self: baseline;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        .ant-radio-wrapper {
          display: flex;
          align-items: center;
          .ant-radio-inner {
            border-color: @content-text-color;
          }
          .ant-radio-checked .ant-radio-inner {
            border-color: @brand-color;
            &:after {
              background-color: @brand-color;
            }
          }
        }
        .user-option-radio {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 5px 0px 6px;
          background: @background-color;
          border-radius: 4px;
          color: @content-text-color;
          border: 0;
          .user-option-name {
            font-size: @h5-font-size;
            margin-left: 0.375rem;
          }
        }
      }
      .user-choice-save-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        background: @brand-color;
        border-radius: 8px;
        color: @white-color;
        height: 32px;
        width: 60px;
      }
    }
    .cms-profile-action-btn {
      display: flex;
      justify-content: flex-end;
      .cms-logout-btn {
        display: flex;
        align-items: center;
        color: @content-text-color;
        border: 0;
        background-color: @background-color;
        svg path {
          fill: @content-text-color;
        }
        &:hover {
          color: @danger-color;
          svg path {
            fill: @danger-color;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
        .cms-logout-text {
          padding-left: 0.4rem;
          font-size: @h6-font-size;
          font-weight: 400;
          line-height: 12px;
        }
      }
    }
    .cms-provider-text {
      font-weight: 600;
    }
    .cms-change-pwd-btn {
      color: @brand-color;
      &:hover {
        color: @royal-blue;
      }
    }
  }
  &-form-text {
    padding: 0 1rem;
    margin-bottom: 16px;
  }
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(244, 79, 64, 0.1);
  color: @danger-color;
  border: none;
  margin-bottom: 12px;
  &-icon {
    margin-right: 4px;
    fill: @danger-color;
    path {
      fill: @danger-color;
    }
  }
  &:hover {
    background-color: @delete-hover;
    color: @danger-color;
  }
  &:active,
  &:focus {
    background: rgba(244, 79, 64, 0.1);
    color: @danger-color;
  }
  .cms-delete-btn {
    font-size: @h6-font-size;
    line-height: 12px;
    font-weight: 400;
  }
}
.warning {
  background: @floral-white;
  border-radius: 8px;
  border: 1px solid @half-pearl;
  &-box {
    padding: 8px;
  }
  &-icon {
    margin-right: 8px;
  }
  &-message {
    font-size: @font-size-sm;
  }
}
.dark-text {
  color: @primary-color;
}
.divider {
  border: 1px solid @stroke-color;
}
.space-tags-container {
  display: flex;
  .space-tag-box:not(:last-child) {
    margin-right: 4px;
  }
}
#cms-country-list {
  .ant-select-item {
    padding-left: 0.5rem;
  }
}
