.cms-source-card {
  display: flex;
  justify-content: center;
  .ant-form {
    width: 100%;
    .ant-space-item {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  .cms-card-title {
    color: @primary-color;
    font-weight: 500;
    line-height: 22px;
  }
  .cms-space-wrap {
    display: flex;
    align-items: center;
    .cms-radio-btn-wrap {
      display: flex;
      flex-direction: column;
      text-align: center;
      .ant-form-item {
        margin-bottom: 0.5rem;
      }
      .cms-card-radio-btn {
        width: 120px;
        height: 120px;
        padding: 0;
        &:hover {
          border-color: @brand-color;
        }
        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: inherit;
        }
        .cms-radio-btn-checked {
          display: flex;
          margin-left: -1.3rem;
          margin-top: 0.5rem;
          align-self: flex-start;
        }
      }
      .cms-active-card {
        background: @white-color;
        border-color: @brand-color;
      }
      .cms-card-service-name {
        margin: 0;
        .cms-right-margin {
          margin-right: 12px;
        }
        .cms-left-margin {
          margin-left: 20px;
        }
      }
    }
    &-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border: 1px solid @stroke-color;
      padding: 1rem 4rem;
      border-radius: 8px;
    }
  }
  .import-card-button:hover {
    background-color: @royal-blue;
  }
}