.cms-upgrade-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 430px);
  align-items: center;
  .cms-upgrade-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.12);
    border-radius: 8px;
    max-width: 410px;
    width: 100%;
    &-text {
      padding-bottom: 1rem;
      font-size: @h6-font-size;
      line-height: 20px;
      color: @content-text-color;
    }
  }
}

.cms-upgrade-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.group-upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.55rem 1rem;
  background: @primary-dark;
  border-radius: 300px;
  color: @white-color;
  cursor: pointer;
  height: 32px;
  width: inherit;
  border: 0;
  &-icon {
    margin-right: 0.4rem;
  }
  span {
    font-family: "Inter", sans-serif;
  }
  &:hover {
    color: @brand-color;
    border-color: @brand-color;
    border: 1px solid @brand-color;
  }
}