.cms-general-setting {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  .space-setting {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 100%;
    justify-content: center;
    max-width: 532px;
    align-self: center;
    &-title {
      font-size: @font-size-lg;
      color: @primary-color;
      font-weight: 500;
      line-height: 26px;
    }
    &-photo {
      margin-bottom: 16px;
    }
    &-avatar {
      margin-right: 8px;
    }
    .cms-space-setting-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
    }
    .storage-info-container {
      display: flex;
      padding: 24px;
      border: 1px solid @stroke-color;
      border-radius: 8px;
      margin-top: 12px;
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: @background-color;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        margin-right: 12px;
        padding: 13px;
        svg {
          height: 24px;
          width: 24px;
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        &-details {
          display: flex;
          justify-content: space-between;
          .ant-tag {
            padding: 1px 10px;
            border-radius: 100px;
          }
          &-title {
            display: flex;
            flex-direction: column;
            &-head {
              font-size: 16px;
              color: @primary-color;
            }
            &-storage {
              font-size: 14px;
              color: @content-text-color;
              line-height: 24px;
            }
          }
          &-skeleton {
            width: 25%;
          }
        }
      }
    }
    .upgrade-bar {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 19px;
      border-radius: 8px;
      padding: 16px;
      border: 1px solid @stroke-color;
      color: @primary-color;
    }
    .upgrade-bar::before {
      content: "";
      position: absolute;
      border-left: 1px solid @stroke-color;
      border-bottom: 1px solid @white-color;
      width: 0px;
      height: 10px;
      left: 80%;
      top: -10px;
      background: @stroke-color;
      margin-left: 5px;
      z-index: 1;
      transform: skewX(-42deg);
    }
    .upgrade-bar::after {
      content: "";
      position: absolute;
      border-right: 1px solid @stroke-color;
      width: 14px;
      height: 10px;
      left: 80%;
      top: -10px;
      background: @white-color;
      transform: skewX(42deg);
    }
    .cms-disable-delete-btn-icon {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
  .cms-general-setting-form {
    display: flex;
    width: 100%;
    &-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: inherit;
      .cms-space-name-field {
        width: 100%;
        margin-right: 12px;
      }
    }
  }
  .cms-general-setting-cta {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 31px;
    .cms-leave-space-btn {
      display: flex;
      align-items: center;
      color: @text-dark-theme;
      border: 0;
      background: @background-color;
      &:hover {
        color: @danger-color;
        svg path {
          fill: @danger-color;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
      .cms-leave-text {
        padding-left: 0.4rem;
      }
    }
    .delete-button {
      margin-left: 16px;
    }
  }
}