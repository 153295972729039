.cms-sync-log-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 420px;
    box-shadow: 0px 16px 26px rgb(88 94 117 / 14%) !important;
    .ant-drawer-wrapper-body {
      background: @background-color;
      .ant-drawer-header {
        padding: 1rem 1rem 1rem 1.18rem;
        .cms-sync-log-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left {
            display: flex;
            align-items: center;
            .cms-sync-log-icon {
              display: flex;
              align-self: center;
              padding-right: 0.5rem;
              svg {
                height: 20px;
                width: 20px;
                path {
                  fill: @primary-color;
                }
              }
            }
            .cms-sync-log-title {
              font-size: @h4-font-size;
              color: @primary-color;
            }
          }
          &-right {
            .cms-sync-log-btn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0.25rem 0.75rem;
              background: @brand-color;
              border-radius: 8px;
              max-height: 26px;
              max-width: 82px;
              border: 0;
              &:hover {
                background: @royal-blue;
              }
              &-text {
                font-weight: 500;
                font-size: @h6-font-size;
                line-height: 18px;
                color: @white-color;
              }
            }
          }
        }
      }
      .ant-drawer-body {
        padding: 1rem;
        &::-webkit-scrollbar {
          width: @scrollbar-width;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 8px;
          background: @background-color;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: @stroke-color;
          &:hover {
            background: @scrollbar-hover-color;
          }
        }
      }
    }
  }
}