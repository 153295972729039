@minor-padding: 0.4rem;
@major-padding: calc(1rem - @minor-padding);

.list-wrapper {
  overflow: hidden;
  .group-name {
    width: auto;
  }
  .hovered-list {
    &-selected {
      &:hover {
        .cms-count {
          color: @primary-color;
        }
      }
    }
    &:hover {
      .ant-list-item-meta-title {
        .cms-group-title {
          color: @primary-color;
        }
        .cms-count {
          color: @primary-color;
        }
      }
      .ant-list-item-meta-avatar {
        svg {
          path:first-child {
            fill: @primary-color;
          }
        }
      }
    }
  }
  .ant-list-items {
    .ant-list-item {
      border-radius: 4px;
      padding: 0.75rem 0.75rem 0.5rem 0.875rem;
      border-bottom: none;
      cursor: pointer;
      &-meta {
        align-items: center;
        &-avatar {
          margin-right: 8px;
          svg {
            path:first-child {
              fill: @content-text-color;
            }
          }
        }
        &:hover {
          .group-checkbox {
            opacity: 1;
          }
          .svg-margin-left {
            margin-left: unset;
          }
        }

        &-content {
          display: flex;
          justify-content: space-between;
          .cms-group-title {
            display: flex;
            width: 100%;
          }
          .ant-list-item-meta-title {
            color: @content-text-color;
            max-width: 85%;
            margin-bottom: 0;
          }
          .cms-contact-tag {
            position: absolute;
            right: 15px;
            align-items: center;
            padding: 1px 5px;
            position: absolute;
            background: @user-tag-color;
            border: 1px solid @white-color;
            box-sizing: border-box;
            border-radius: 200px;
            color: @white-color;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .selected-list {
      .ant-list-item {
        &-meta {
          .group-name {
            color: @brand-color;
          }
          &-avatar {
            svg {
              path:first-child {
                fill: @brand-color;
              }
              path:nth-child(2) {
                fill: @white-color;
              }
            }
          }
          &-content {
            .ant-list-item-meta-title {
              color: @brand-color;
            }
          }
          &:hover {
            .ant-list-item {
              &-meta {
                &-avatar {
                  svg {
                    path:first-child {
                      fill: @accent-color;
                    }
                  }
                }
                &-title {
                  color: @accent-color;
                }
              }
            }
          }
        }
      }
    }
  }
  .cms-list-user-avatar {
    font-size: @size-10 !important;
    border-radius: 8px;
    height: 20px;
    width: 20px;
  }
}
.no-group-contact-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 0.75rem 0.5rem;
  color: @content-text-color;
  height: 46.19px;
  svg {
    margin-right: 8px;
    path {
      fill: @content-text-color;
    }
  }
  &:hover {
    color: @primary-color;
    svg {
      path {
        fill: @primary-color;
      }
    }
  }
  &-selected {
    color: @brand-color;
    .cms-count {
      color: @content-text-color;
    }
    svg {
      path {
        fill: @brand-color;
      }
    }
    &:hover {
      color: @brand-color;
      svg {
        path {
          fill: @brand-color;
        }
      }
    }
  }
}
.list-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      color: @text-dark-theme;
      padding-left: 43.5px;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
        .list-wrapper {
          .ant-list-items {
            .last-list-item {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
  .ant-collapse-item-active {
    display: flex;
    flex-direction: column-reverse;
  }
}
.cms-count {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  background: @background-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  border-radius: 300px;
  margin: 0px 8px;
  font-size: 10px;
  height: 18px;
}
.ant-list-items {
  .selected-list {
    .ant-list-item {
      &-meta {
        &-content {
          .ant-list-item-meta-title {
            .cms-count {
              color: @content-text-color;
            }
          }
        }
      }
    }
  }
}
.cms-group-checkbox {
  .ant-checkbox-inner::after {
    left: 20%;
  }
  .ant-checkbox-wrapper {
    margin-right: 5px;
  }
  .cms-svg-md {
    max-width: 22px;
  }
  .svg-margin-left {
    margin-left: -24px;
  }
  .group-checkbox {
    opacity: 0;
  }
  .ant-checkbox.ant-checkbox-checked {
    opacity: 1;
    border: 1px solid @brand-color;
  }
}
