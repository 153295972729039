.duplicates-wrapper {
  background: @border-color-theme;
  padding: 0.5rem 0.5rem 0 0.5rem;
  height: calc(100vh - 53px);
  overflow: auto;
  .duplicate-card {
    background-color: @white-color;
    border-radius: @border-radius-base;
    margin-bottom: 12px;
    overflow: hidden;
    max-height: unset;
    .ant-divider {
      margin: 12px 0;
    }
  }
}
.cms-no-duplicate-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: @background-color;
  height: calc(100vh - 53px);
}
.cms-cal-height {
  height: calc(100vh - 85px);
}