.cms-notification-card {
  display: flex;
  padding: 0.875rem 0.875rem 0.875rem 0;
  position: relative;
  .cms-notification-detail {
    display: flex;
    flex-direction: column;
    padding-left: 1.625rem;
    width: 100%;
  }
  .cms-recent-notification {
    height: 6px;
    width: 6px;
    background: @brand-color;
    border-radius: 200px;
    margin-top: 10px;
    position: absolute;
    left: 10px;
  }
  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cms-user-info {
      display: flex;
      align-items: center;
      padding-bottom: 0.75rem;
      flex-flow: wrap;
      line-height: 24px;
      .ant-tag {
        display: flex;
        background: @white-color;
        border: 0;
        border-radius: 4px;
        padding: 2px 4px 2px 0;
        align-items: center;
        margin-right: 0;
        .cms-group-folder {
          path {
            fill: @primary-color;
          }
        }
      }
      .cms-user-name {
        display: flex;
        align-self: center;
        color: @primary-color;
        font-size: @h5-font-size;
        padding-left: 0.25rem;
        font-weight: 600;
      }
      .ant-tag.cms-group-tag {
        border: 0;
      }
      .cms-service-img {
        width: 24px;
        height: 24px;
      }
      .cms-text-space {
        padding-right: 0.2rem;
      }
    }
    .cms-user-detail-dropdown {
      cursor: pointer;
      fill: @brand-color;
    }
    .cms-notification-avatar {
      height: 18px;
      width: 18px;
      border-radius: 100px;
      font-size: @size-10;
    }
  }
  &-meta-info {
    display: flex;
    justify-content: space-between;
    color: @content-text-color;
    .cms-notification-time {
      font-size: @size-10;
    }
    .cms-organization {
      font-size: @h6-font-size;
      display: flex;
      align-items: center;
      &-svg {
        padding-right: 0.3rem;
        .cms-space-avatar {
          height: 18px;
          width: 18px;
          border-radius: 2px;
          font-size: @size-10;
        }
      }
    }
  }
  .cms-space-right {
    padding-right: 0.175rem;
  }
  .cms-space-top {
    margin-top: 0;
  }
  .cms-long-name-ellipsis {
    max-width: 345px;
    display: block !important;
  }
}
.ant-divider-horizontal.cms-divider {
  display: flex;
  clear: both;
  width: auto;
  min-width: 0;
  margin: 0 8px;
  border-top: 1px solid @stroke-color;
}
.cms-user-info-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  box-shadow: 0px 16px 26px rgba(88, 94, 117, 0.14);
  border-radius: 8px;
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
  }
  .cms-user-change-info {
    padding-right: 0.5rem;
  }
  .ant-tag.cms-group-tag {
    border: 0;
    background: @background-color;
    color: @primary-color;
    font-size: @h5-font-size;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#contactUpdate {
  .ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    .cms-order1 {
      order: 1;
    }
    .cms-order-1 {
      order: -1
    }
  }
}
.cms-space-user-name {
  font-weight: 400 !important;
  padding-left: 0 !important;
  margin-right: 3px;
}