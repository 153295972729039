.contact-card-button-modal {
  min-width: 300px !important;
  width: fit-content !important;
  max-width: 500px !important;
  .ant-modal-footer {
    border-top: none;
    text-align: center;
  }
  .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 0px 24px;
    .ant-modal-title {
      color: @primary-color;
      font-weight: 500;
      .font-inter;
    }
  }
  .ant-modal-body {
    padding: 16px 24px;
  }
  .modal-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid @stroke-color;
    padding: 8px;
    &-number-box {
      display: flex;
      flex-direction: column;
      &-text {
        font-size: 12px;
        color: @content-text-color;
      }
      &-number {
        color: @primary-color;
      }
    }
  }
  .modal-body:not(:last-child) {
    margin-bottom: 16px;
  }
}
