.no-contact-connect-source {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
  background: @background-color;
  width: 100%;
  .cms-connect-source-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    max-width: 384px;
    min-height: 380px;
    width: inherit;
    background: @white-color;
    border-radius: 16px;
    &-title {
      color: @content-text-color;
      text-align: center;
    }
    &-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: inherit;
      .ant-form {
        flex-direction: column;
        width: inherit;
      }
    }
  }
  .connect-service-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @primary-color;
    border-radius: 8px;
    padding: 0.375rem 1rem;
    border: 0;
    max-width: 216px;
    width: 100%;
    margin-top: 32px;
    &:hover {
      background-color: @btn-hover;
    }
    &:disabled {
      color: @white-color;
    }
  }
}