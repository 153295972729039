.cms-shared-user-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 1rem 0 1rem;
  justify-content: space-between;
  margin-top: 1rem;
  z-index: 4;
  overflow: hidden auto;
  &-data {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    &:hover {
      .cms-delete-shared-user {
        display: flex;
      }
    }
  }
  &-cms-name {
    display: flex;
    align-items: center;
    .cms-owner-avatar {
      font-size: @size-10 !important;
      margin-right: 10px;
      border-radius: 8px;
      height: 24px;
      width: 24px;
    }
    &.email {
      display: flex;
      align-items: initial;
      .cms-name-email {
        display: flex;
        flex-direction: column;
        .cms-name-tag {
          display: flex;
        }
        .cms-font-ellipsis.email {
          display: block;
          max-width: 220px;
          width: 100%;
          font-size: 10px;
        }
      }
    }
  }
  .user-resend-invitation {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    height: 20px;
    background: @background-color;
    border-radius: 300px;
    font-size: 12px;
    margin-left: 0.4rem;
  }
  &-rights-dropdown {
    display: flex;
    align-items: center;
    max-height: 24px;
    .user-invite-tag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 0.375rem;
      height: 20px;
      background: @white-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: @h6-font-size;
    }
    .cms-owner-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0 0.75rem;
      background: @primary-color;
      border-radius: 200px;
      color: @white-color;
      height: 20px;
      width: 110px;
      border: 0;
      & > span {
        display: flex;
        align-items: center;
        font-size: @h6-font-size;
        line-height: 20px;
        font-weight: 500;
      }
      &:hover {
        background-color: @content-text-color;
      }
    }
    .cms-owner-text-disable {
      background: @grey-3;
      color: rgba(0, 0, 0, 0.25);
    }
    .cms-invited-tag {
      display: flex;
      align-items: center;
      background: @white-color;
      height: 20px;
      padding: 0 0.75rem;
      font-size: @h6-font-size;
      border-radius: 100px;
      border: 1px solid @stroke-color;
      margin: 0 0.4rem;
    }
    .ant-tooltip {
      max-width: 160px;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    max-height: inherit;
    display: flex;
    align-items: center;
    border: 0;
  }
  .cms-reinvite-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0.75rem;
    background: @brand-color;
    border-radius: 4px;
    color: @white-color;
    font-size: @h6-font-size;
    height: 20px;
    width: 76px;
    border: 0;
    & > span {
      display: flex;
      align-items: center;
      font-size: @h6-font-size;
      line-height: 20px;
    }
  }
  .cms-select-after {
    max-height: inherit;
    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
    .ant-select-disabled .ant-select-selector {
      background: transparent;
    }
  }
  .neg-mr-12 {
    margin-right: -12px;
  }
  .cms-delete-shared-user {
    display: none;
    padding-left: 0.75rem;
    cursor: pointer;
    border: 0;
    background: transparent;
    height: 24px;
    align-items: center;
  }
  .cms-delete-shared-user.ant-btn[disabled],
  .cms-delete-shared-user.ant-btn[disabled]:hover,
  .cms-delete-shared-user.ant-btn[disabled]:focus,
  .cms-delete-shared-user.ant-btn[disabled]:active {
    cursor: not-allowed;
    background: transparent;
  }
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
}
.cms-space-member {
  max-height: calc(100vh - 260px);
}
.cms-group-member {
  max-height: calc(100vh - 330px);
}
.cms-shared-member-skeleton {
  padding: 0;
}
.cms-owner-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.75rem;
  max-width: 62px;
  width: 100%;
  height: 20px;
  background: @background-color;
  border-radius: 200px;
}
.cms-shared-tag {
  display: flex;
  align-items: center;
  margin-left: 0.4rem;
  background: @background-color;
  height: 16px;
  padding: 0 6px;
  font-size: @size-10;
  border-radius: 100px;
  color: @content-text-color;
  line-height: 16px;
}
.cms-shared-tag-ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 60px;
  width: 100%;
}
.font-12 {
  font-size: @h6-font-size;
}
.cms-no-padding-margin {
  padding: 0;
  margin-top: 20px;
  overflow: hidden auto;
  .cms-shared-user-list-data {
    padding-bottom: 0;
  }
}
