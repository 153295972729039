.app-layout-sider-header {
  .app-icon {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      cursor: pointer;
    }
  }
}
