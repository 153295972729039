.import-card {
  max-width: 496px;
  width: 100%;
  min-height: 218px;
  @media (max-width: 480px) {
    width: 343px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: 360px) {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 480px) {
    .ant-card-body {
      padding: 16px;
      .ant-btn-primary {
        width: 100%;
      }
    }
  }
  text-align: center;
  margin: 24px 12px 10px 12px;
  .ant-card {
    max-height: 218px;
    margin: 0;
  }
  .import-card-contact {
    display: flex;
    width: inherit;
    flex-direction: column;
    border: 1px dashed @stroke-color;
    border-radius: 8px;
  }
  @media (max-width: 768px) {
    min-height: 218px;
    max-height: 100%;
  }
  &-service-name {
    margin-top: 8px;
  }

  &-title {
    margin-right: 8px;
    color: @primary-color;
    font-size: @h5-font-size;
    font-weight: 500;
    line-height: 22px;
    &-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;
      border-radius: 24px;
      &-icon {
        margin-right: 4px;
      }
      &-text {
        color: @content-text-color;
        font-size: @h6-font-size;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
  :last-child {
    margin-right: unset;
  }
  :first-child {
    margin-left: unset;
  }
  &-button {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &-button[disabled] {
    background: @text-dark-theme;
    color: @white-color;
  }
  .ant-card-body {
    padding: 1.5rem 4rem;
    @media (max-width: 768px) {
      padding: 1rem 0 0 0;
    }
  }
  .ant-upload-list {
    display: none;
  }
}
.guide-button {
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  &-icon {
    margin-left: 4px;
  }
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 12px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: @font-size-sm;
  color: @content-text-color;
  line-height: 20px;
}
.ant-upload {
  background-color: @white !important;
  @media (max-width: 768px) {
    width: 100%;
  }
  &-btn {
    border-radius: 8px;
  }
}
.ant-upload-drag {
  background-color: @white !important;
  padding: 0.75rem;
  @media (max-width: 768px) {
    padding: 0 0.75rem;
  }
}
.cms-onboarding-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: @content-text-color;
}
.cms-source-card-block {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: auto;
  }
}
.cms-service-name {
  font-weight: 500;
  line-height: 20px;
  font-size: @h6-font-size;
  color: @content-text-color;
}
.cms-import-contact-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-or-text {
    color: @content-text-color;
    font-weight: 400;
    font-size: @h6-font-size;
    margin-top: 6px;
    margin-bottom: 8px;
  }
  &-import-text {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: @h6-font-size;
    line-height: 20px;
    text-decoration-line: underline;
    color: @brand-color;
  }
}
.cms-upload-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    max-height: 24px;
    height: 100%;
    padding: 0.75rem;
    position: relative;
    &:before {
      content: "";
      border: 1px solid @stroke-color;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
    }
  }
}
.cms-text-border {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 0.75rem;
  background-color: @white-color;
}
.import-card-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: @primary-color;
  border: 0;
  padding: 0.375rem 1rem;
  max-width: 97px;
  width: 100%;
  max-height: 32px;
  .cms-upload-text {
    max-width: 100%;
    font-weight: 500;
    font-size: @h6-font-size;
    line-height: 20px;
    color: @white-color;
  }
}
.ant-upload.ant-upload-drag {
  border: 0;
}
.cms-source-wrapper {
  @media (max-width: 768px) {
    flex-direction: column;
  }
}