.cms-contact-wrapper {
  display: flex;
  flex-direction: column;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid @stroke-color;
    padding: 0 1rem 1rem;
    margin: 0 -16px;
    @media screen and (max-width: 1024px) {
      &.mobile {
        height: 49px;
      }
      .cms-contact-form-close.mobile-close {
        .cms-edit-back {
          display: flex;
          align-items: center;
          gap: 5px;
          height: 32px;
          .cms-contact-form-close-arrow {
            line-height: 0;
          }
        }
      }
    }
  }
  .cms-contact-form-close {
    display: flex;
    align-items: center;
    cursor: pointer;
    &-arrow {
      padding-right: 5px;
      line-height: 0;
    }
    .cms-edit-back {
      display: flex;
      align-items: center;
    }
  }
  .cms-add-photo {
    display: flex;
    padding-bottom: 1rem;
  }
  .cms-upload-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    &-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: @border-color-theme;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 200px;
      width: 56px;
      height: 56px;
      cursor: pointer;
      .cms-user-profile {
        height: inherit;
        width: inherit;
        border-radius: 200px;
        display: flex;
        flex: none;
        cursor: pointer;
        object-fit: cover;
      }
      .cms-input-file {
        position: absolute;
        opacity: 0;
        height: 80px;
        width: inherit;
        cursor: pointer;
      }
    }
    &-photo-text {
      color: @brand-color;
      font-size: @h5-font-size;
      font-weight: 500;
      line-height: 22px;
      pointer-events: none;
    }
  }
  .ant-upload-list {
    display: none;
  }
  .cms-add-edit-contact-form {
    opacity: 0.5;
    pointer-events: none;
    overflow: hidden;
  }
  .cms-contact-form {
    padding-top: 1rem;
    overflow-y: scroll;
    height: calc(100vh - 117px);
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .cms-contact-form-height {
    height: calc(100vh - 117px);
  }
  .ant-row.cms-form-row {
    justify-content: space-between;
  }
  .ant-form-item {
    flex-direction: column;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .anticon.anticon-close {
    width: 18px;
    height: 18px;
    border: 1px solid @stroke-color;
    border-radius: 200px;
    background: @white-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cms-add-group {
    .ant-select-selection-item {
      background: @white-color;
      height: 24px;
      align-items: center;
      border-radius: 200px;
    }
  }
  .cms-disable-form-save-btn {
    background: @background-color;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
  }
  .cb-loading-wrapper .anticon.anticon-loading.anticon-spin.ant-spin-dot {
    position: sticky;
  }
}
.cms-form-save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: @h5-font-size;
  color: @white-color;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  background: #192039;
  border-radius: 8px;
  padding: 0.313rem 1.5rem;
  max-height: 32px;
  span {
    line-height: 22px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
  &:hover:enabled {
    background-color: @content-text-color;
    color: @white-color;
  }
}
