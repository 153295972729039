.cms-subs-footer-wrapper {
  padding: 0 1.5rem 0 1.5rem;
  margin-bottom: 40px;
  .cms-subs-footer {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid @stroke-color;
    border-radius: 8px;
    .cms-subs-feature-coming-soon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 0px;
      width: 252px;
      height: 40px;
      background: @background-color;
      border-radius: 4px;
      margin-right: 1.5rem;
    }
    .cms-coming-soon-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: @primary-color;
    }
    .cms-feature-list {
      display: flex;
      align-items: center;
      margin-right: 2.75rem;
    }
    .cms-feature-text {
      margin-right: 6px;
    }
    .cms-subFeature {
      font-size: @h6-font-size;
      color: @content-text-color;
    }
  }
}