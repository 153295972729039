.import-contacts-wrapper {
  display: flex;
  flex-direction: column;
  &-block {
    display: flex;
    flex-direction: column;
    max-width: 532px;
    width: 100%;
    align-self: center;
    padding-top: 1.5rem;
    .import-card {
      max-width: 532px;
      width: 100%;
    }
  }
  .import-contacts-header {
    display: flex;
    justify-content: flex-start;
    padding-bottom: .75rem;
    .cms-add-source-title {
      font-size: @h4-font-size;
      color: @content-text-color;
      font-weight: 500;
      line-height: 26px;
    }
    .contact-form-close-arrow {
      display: flex;
      align-items: center;
      cursor: pointer;
      .contact-form-close-back {
        padding-left: 0.5rem;
      }
    }
  }
}
.import-contacts-upload-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  .import-card-button {
    border: 0;
    background: @brand-color;
    &:hover {
      background-color: @royal-blue;
    }
  }
  .ant-upload {
    padding-bottom: 0 !important;
  }
}
.import-contacts-upload-or {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dragger {
  .ant-upload-list {
    display: none;
  }
}
