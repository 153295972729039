.cms-remove-icon-btn {
  border: 0;
  margin-left: -10px;
  &:hover {
    svg path {
      fill: red;
    }
  }
}
.cms-contact-card-avatar {
  border-radius: 200px;
}
.cms-contact-actions {
  display: flex;
  flex-direction: column;
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 84px;
    .cms-icon-btn {
      margin-right: 8px;
    }
    .cms-icon-btn:last-child {
      margin-right: 0px;
    }
  }

  &-avatar {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    &-body {
      border-radius: 2px;
      height: 22px;
      width: 22px;
      font-size: @size-11;
      border: 1.1px solid @stroke-color;
    }
  }
}
.cms-duplicate-card-pd {
  padding: 0.75rem 1rem;
  position: relative;
}
.contact-card-hover {
  .ant-list-item-meta-avatar {
    position: relative;
  }
  &:hover {
    .ant-avatar {
      opacity: 0;
      display: none;
      @media screen and (max-width: 1024px) {
        opacity: 1;
        display: block;
      }
    }
    .cms-contact-checkbox {
      display: flex;
      opacity: 1;
    }
  }
}
.cms-contact-checkbox {
  display: none;
  align-items: center;
  justify-content: center;
  background: @background-color;
  border-radius: 40px;
  width: 48px;
  height: 48px;
  opacity: 0;
  &-icon {
    text-align: center;
    .ant-checkbox {
      border: 1px solid @content-text-color;
      &-inner {
        border: 0;
        border-radius: 0;
        height: 15px;
        width: 15px;
      }
    }
    .ant-checkbox.ant-checkbox-checked {
      border: 1px solid @brand-color;
      .ant-checkbox-inner {
        background: @brand-color;
      }
    }
    .ant-checkbox-checked::after {
      border: 0;
      border-radius: unset;
    }
  }
}
.ant-checkbox {
  border: 1px solid @content-text-color;
  &-inner {
    border: 0;
    border-radius: 0;
    height: 15px;
    width: 15px;
  }
}
.ant-checkbox.ant-checkbox-checked {
  border: 0;
  .ant-checkbox-inner {
    background: @brand-color;
  }
}
.ant-checkbox-checked::after {
  border: 0;
  border-radius: unset;
}
