.cms-reminder-config {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: inherit;
    border-bottom: 1px solid @stroke-color;
    &-save {
      box-shadow: none;
      background: transparent;
      border: 0;
      align-items: flex-end !important;
      &-text {
        font-weight: 500;
        font-size: @h4-font-size;
        line-height: 26px;
        color: @brand-color;
      }
      &:hover, &:active, &:focus, &:focus-visible, &:focus-within {
        background: transparent;
        border: 0;
        color: @royal-blue;
      }
      &:disabled {
        background-color: transparent;
        .cms-reminder-config-header-save-text {
          color: @stroke-color;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  &-email {
    display: flex;
    align-items: center;
    margin-top: 25px;
    &-text {
      font-size: @h5-font-size;
      color: @content-text-color;
    }
    &-select {
      .ant-select {
        display: flex;
      }
      .ant-select-selector {
        display: flex;
        align-items: center;
        border: 1px solid @stroke-color !important;
        border-radius: 4px !important;
        padding: 0.125rem 0.5rem !important;
        height: 24px !important;
      }
      .ant-select-arrow {
        top: 25%;
      }
    }
  }
  &-deactivate {
    display: flex;
    justify-content: flex-end;
    width: inherit;
    &-btn {
      padding: 0.375rem 0.75rem;
      height: 32px;
      background: @background-color;
      border-radius: 8px;
      border: 0;
      &-text {
        font-size: @h6-font-size;
        line-height: 20px;
        color: @content-text-color;
      }
      &:hover {
        background: @stroke-color;
      }
      &:focus {
        background: @background-color;
      }
    }
  }
}