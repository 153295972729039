.signup-container {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-card {
    width: 360px;
    @media (max-width: 413px) {
      width: 310px;
    }
    @media (max-width: 360px) {
      width: 250px;
    }
    @media (max-width: 768px) {
      .ant-col {
        width: 100%;
        .ant-btn-primary {
          width: 100%;
        }
      }
    }
    .ant-form-item-label > label {
      color: @content-text-color;
    }
  }
}
