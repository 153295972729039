.cms-tag-empty-state {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: center;
  &-title {
    font-weight: 500;
    line-height: 32px;
    color: @primary-color;
    font-size: @size-24;
  }
  &-image {
    margin: 40px 0;
    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid @stroke-color;
    }
  }
  &-content {
    color: @content-text-color;
    font-size: @h4-font-size;
    line-height: 24px;
    text-align: center;
    & > a {
      text-decoration: underline;
    }
  }
}
.ft-18 {
  font-size: @h3-font-size;
}