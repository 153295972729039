.contact-user-more-info-wrapper {
  border-radius: 0 0 8px 8px;
  background-color: @tag-color;
  margin-bottom: 24px;
  .contact-user-more-info-col {
    .ant-collapse-content-box {
      padding-top: 1.5rem;
    }
    .contact-info-collapse {
      &-panel {
        font-size: @h6-font-size;
        .ant-collapse-header {
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;
          align-items: center;
          color: @content-text-color;
          .anticon {
            margin-left: 4px;
            margin-top: 2px;
            display: flex;
            position: unset;
            line-height: unset;
            vertical-align: unset;
            top: unset;
            left: unset;
            padding: 0;
          }
          .ant-collapse-arrow {
            position: unset;
            margin-left: 7px;
            padding: 0px;
            fill: @content-text-color;
          }
        }
        .ant-collapse-content {
          background-color: @white-color;
          border: 1px solid @stroke-color;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 1.5rem 0 0 1.5rem;
        }
      }
      .no-contact-detail {
        .ant-collapse-content {
          background-color: @border-color-theme;
        }
      }
    }
  }
}
.cms-merge-contact-meta-detail {
  background-color: @white-color;
  border: 1px solid @stroke-color;
  padding: 1.5rem 0 0 1.5rem;
  border-radius: 0 0 8px 8px;
}
.remove-merge-contact-field {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  align-items: center;
  svg {
    cursor: pointer;
  }
}
.cms-user-info-meta-box {
  background-color: unset;
}
.ant-collapse-item-active {
  .ant-collapse-arrow {
    transform: rotate(180deg);
  }
}