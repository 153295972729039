.cms-confirm-popup {
  .ant-modal-body {
    padding: 1.5rem 1.5rem 1.25rem 1.5rem;
    line-height: 24px;
    .popup-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .popup-text {
        font-family: Inter;
        display: flex;
        align-items: center;
        justify-content: center;
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: @primary-color;
          font-size: @h3-font-size;
          line-height: 30px;
        }
        &-title-info {
          font-size: @h5-font-size;
          text-align: center;
        }
        &-content {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          border: 1px solid @stroke-color;
          padding: 8px 12px;
          margin-top: 8px;
          &-body {
            font-family: "Inter", sans-serif;
            color: @primary-color;
            font-weight: 400;
            &-text {
              margin-bottom: 8px;
            }
            &-tags-container {
              display: flex;
              flex-direction: row;
            }
            .ant-tag-has-color {
              display: flex;
              align-items: center;
              padding: 4px 8px;
              color: @content-text-color;
              font-family: "Inter";
              font-size: 14px;
              border-radius: @border-radius-sm;
            }
          }
          .margin-right {
            margin-right: 8px;
          }
          &-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 4px 0;
          }
          &-checkbox {
            margin-top: 16px;
            .ant-checkbox-inner {
              border-radius: 0px;
            }
          }
          .delete-contact-content {
            display: flex;
            align-items: center;
            .group-tag {
              display: flex;
              align-items: center;
              margin: 0;
            }
          }
        }
      }
      .merge-modal {
        .popup-text-title {
          justify-content: flex-start;
        }
        .merge-popup-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0.75rem;
          background: @white-color;
          border: 1px solid @stroke-color;
          box-sizing: border-box;
          border-radius: 8px;
          font-size: 14px;
          line-height: 24px;
          color: @primary-color;
          font-size: @h5-font-size;
          margin-top: 16px;
        }
      }
    }
    .group-tag {
      padding: 0.125rem 0.75rem;
      font-size: @size-10;
      background: @white;
      border: 1px solid @stroke-color;
      border-radius: 16px;
      margin-bottom: 8px;
      .group-tag-pd-left {
        padding-left: 0.3rem;
      }
      .ant-tag {
        border-radius: 16px;
      }
    }
    .groups-tags-wrapper {
      display: inline;
    }
  }
  .ant-modal-footer {
    font-family: Inter;
    border-top: 0;
    .ant-btn {
      border: 0;
      color: @content-text-color;
      font-weight: 500;
      min-width: 86px;
    }
    :nth-child(2) {
      background: @danger-color;
      border-radius: 8px;
      color: @white-color;
    }
  }
  .cms-title-wrap {
    justify-content: flex-start !important;
    flex-wrap: wrap;
  }
}
.cms-merge-contact-modal {
  .ant-modal-footer {
    :nth-child(1) {
      font-family: "Inter", sans-serif;
      span {
        color: @content-text-color;
        font-size: @h5-font-size;
      }
    }
    :nth-child(2) {
      background: @brand-color;
      border-radius: 8px;
      color: @white-color;
      font-size: @h5-font-size;
    }
  }
}
