.cms-merge-save-view-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 1rem;
  border-radius: 8px;
  border: 0;
  &:hover {
    color: @white-color;
    background-color: @brand-color;
  }
  &-text {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: @h6-font-size;
    line-height: 20px;
  }
}
.cms-merge-contact-save-btn {
  background: @brand-color;
  color: @white-color;
  &:hover {
    color: @brand-color;
    background: @white-color;
  }
}
.cms-merge-contact-view-btn {
  background: @white-color;
  color: @brand-color;
}
.merge-contact-skeleton {
  border: 1px solid @stroke-color;
  border-radius: 8px 8px 0 0;
  background-color: @background-color;
  padding: 1rem;
  border-bottom: 0;
}
.cms-meta-skeleton {
  padding: 0;
}