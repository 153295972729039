.cms-more-features {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 680px;
  width: 100%;
  height: 282px;
  margin-top: 24px;
  margin-bottom: 32px;
  justify-content: space-between;
  @media (max-width: 612px) {
    height: auto;
    flex-direction: column;
  }
  &-left {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1.375rem 1rem;
    max-width: 328px;
    width: inherit;
    height: 266px;
    align-self: start;
    @media (max-width: 612px) {
      height: auto;
    }
    &-title {
      display: flex;
    }
    &-features {
      display: flex;
      flex-direction: column;
      &-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        .cms-features-desc {
          display: flex;
          flex-direction: column;
          &-title {
            font-size: @h5-font-size;
          }
          &-subtitle {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: @h6-font-size;
            line-height: 20px;
            color: @content-text-color;
          }
        }
      }
    }
  }
  .cms-more-features-section-title {
    font-weight: 600;
    color: @primary-color;
    line-height: 24px;
    font-size: @h4-font-size;
  }
  &-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: inherit;
    height: inherit;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    background-color: @background-color;
    background-image: url("/assets/svg/scannerBG.png");
    border-radius: 12px;
  }
}