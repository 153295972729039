.add-photo {
  display: flex;
  padding-bottom: 1rem;
}
.upload-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  .image-label {
    width: min-content;
  }
  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: @border-color-theme;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 200px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    margin-right: 12px;
  }
  .user-profile {
    height: inherit;
    width: inherit;
    border-radius: 200px;
    display: flex;
    flex: none;
    cursor: pointer;
    object-fit: cover;
  }
  .input-file {
    position: absolute;
    opacity: 0;
    height: 100px;
    width: 100px;
    cursor: pointer;
    font-size: 0;
    border-radius: 200px;
  }
  &-photo-text {
    color: @brand-color;
    font-size: 12px;
    .input-file {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      max-width: 110px;
      width: 100%;
      font-size: 0;
      margin-top: 5px;
    }
    input::-webkit-file-upload-button {
      cursor: pointer;
    }
    &-item {
      cursor: pointer;
    }
    .space-setting-width {
      max-width: 55px;
    }
  }
  .cms-upload-btn-avatar {
    margin-right: 16px;
    border-radius: 200px;
  }
  .cms-disable-field {
    cursor: not-allowed;
  }
  .cms-space-img-upload-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: @border-color-theme;
    padding: 2.25rem;
    cursor: pointer;
    margin-right: 12px;
    border: 2.14286px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 428.571px;
    width: 120px;
    height: 120px;
    svg {
      height: 28px;
      width: 28px;
    }
  }
  .cms-space-profile-img {
    width: 120px;
    height: 120px;
    font-size: @size-26;
  }
  .cms-space-remove-profile-btn {
    left: unset;
    right: 28px;
  }
}
