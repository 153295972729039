.cms-document-list-wrapper {
  display: flex;
  max-width: 100%;
  padding-right: 1rem;
  padding-bottom: 1rem;
  max-height: calc(100vh - 500px);
  .cms-document-list-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }
  .cms-document-progress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1rem;
    border-bottom: 1px solid @stroke-color;
    padding-bottom: 1rem;
    .cms-progress-text {
      margin-right: 0.5rem;
      margin-left: 0.8rem;
      font-size: @size-10;
    }
  }
  .ant-progress-line {
    width: 20%;
  }
}