.cms-space-sharing-box {
  display: flex;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 1.5rem;
  width: 680px;
  max-width: 100%;
  height: 100%;
  margin: 24px 0;
  flex-direction: column;
  min-height: 290px;
  @media (max-width: 612px) {
    height: auto;
    padding: 0;
    border: 0;
  }
  &-block {
    display: flex;
    width: 100%;
    height: 100%;
    max-height:290px;
    overflow: hidden;
    .onboard-space-members {
      display: flex;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      .cms-shared-user-list-data {
        margin-bottom: 0;
        line-height: 24px;
        padding-bottom: 1rem;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .cms-share-wrapper {
      flex-direction: column;
      width: 100%;
      .cms-space-sharing-box-block-top-section,
      .cms-space-sharing-box-block-middle-section {
        width: inherit;
      }
    }
    &-label {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 226px;
      .cms-label-divider {
        margin: 16px 0;
        border-top: 1px solid @stroke-color;
      }
      &-checkbox-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: inherit;
        overflow: hidden auto;
        height: 100%;
        .ant-checkbox-wrapper {
          margin-bottom: 16px;
          span {
            font-weight: 500;
            line-height: 24px;
            color: @content-text-color;
          }
        }
      }
      .cms-label-select-all {
        span {
          color: @primary-color;
          font-weight: 500;
          font-size: @h5-font-size;
          line-height: 24px;
        }
      }
    }
    .cms-section-divider {
      margin: 0 24px;
      border-left: 1px solid @stroke-color;
      height: 100vh;
    }
  }
}
.cms-shared-user-remove {
  color: @danger-color;
  padding: 0.5rem 0 0.5rem 1rem;
  &:hover {
    background-color: @delete-hover;
  }
}
.cms-shared-divider {
  margin: 0;
}