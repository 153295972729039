.cms-sync-detail {
  padding-left: 1.25rem !important;
}
.cms-sync-card {
  justify-content: space-between;
  width: 100%;
  .cms-sync-text {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: @h5-font-size;
    line-height: 28px;
    color: @content-text-color;
    margin-left: 6px;
  }
  .cms-sync-card-link {
    text-decoration: underline;
    font-weight: 500;
    font-size: @h6-font-size;
    line-height: 28px;
    color: @brand-color;
  }
}