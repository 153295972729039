.contact-social-update-wrapper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .contact-social-update {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    width: 100%;
    background: @background-color;
    border: 1px solid @stroke-color;
    border-radius: 300px;
    justify-content: space-between;
    &-user-detail {
      display: flex;
      align-items: center;
    }
    &-icon {
      padding-right: 0.7rem;
      padding-top: 0.2rem;
    }
  }
}