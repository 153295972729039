.cms-existing-contact-wrapper {
  display: flex;
  flex-direction: column;
  &-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .cms-contact-form-close {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding-bottom: 1rem;
    }
    .cms-search-box {
      padding-top: 0.8rem;
      padding-bottom: 1.5rem;
      .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
        border-radius: 20px;
      }
      .ant-input-group-addon {
        display: none;
      }
      .ant-input-affix-wrapper {
        border-radius: 20px;
        border-color: @stroke-color;
        &:not(.ant-input-affix-wrapper-disabled):hover {
          border: 1px solid @brand-color;
        }
        .ant-input-prefix {
          .anticon {
            color: @content-text-color;
          }
        }
      }
    }
  }
  .ant-input-group-addon {
    display: none;
  }
  .cms-loader-wrap {
    display: flex;
    align-items: center;
    height: calc(100vh - 126px);
    justify-content: center;
  }
  .cms-existing-contact-list {
    height: calc(100vh - 126px);
    overflow: auto;
    .cms-contacts-card-list {
      .ant-list-item-meta-title {
        font-size: 16px;
        color: @primary-color;
      }
      .ant-list-item {
        padding: 0.5rem 0.75rem;
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 12px;
        margin-bottom: 1rem;
        height: 72px;
        .ant-list-item-meta {
          align-items: center;
        }
        .ant-list-item-action {
          .ant-list-item-action-split {
            display: none;
          }
          .cms-icon-btn-remove {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            width: 10px;
            height: 24px;
            width: 24px;
            svg {
              height: 20px;
              width: 20px;
            }
            &:hover {
              svg path {
                fill: red;
              }
            }
          }
        }
        .ant-list-item-meta-content {
          display: flex;
          flex-direction: column;
          padding-top: 0.7rem;
        }
      }
      .cms-no-group {
        align-items: center;
        .ant-list-item-meta-content {
          padding-top: 0;
        }
      }
    }
  }
  &-select-contact-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .cms-contact-slider {
      width: inherit;
      overflow: auto;
      &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 28px;
      }
      &-text {
        font-weight: 500;
        line-height: 24px;
        color: @content-text-color;
      }
    }
    .cms-contact-scrollbar {
      &::-webkit-scrollbar {
        width: @scrollbar-width;
        height: 3px;
      }
    }
    .cms-contact-remove-icon {
      position: absolute;
      top: 0;
      left: 32px;
      z-index: 2;
    }
  }
}