.auth-layout-container {
  display: flex;
  flex-direction: column;
  @media (max-width: 320px) {
    height: 100%;
  }
  @media (max-width: 768px) {
    .auth-layout-container {
      &-split {
        &-left {
          width: 100%;
          left: 0;
          z-index: 1;
          justify-content: unset;
          &-logo {
            position: absolute;
            top: 0;
            height: auto;
            left: 0;
            right: 0;
            padding-top: 32px;
            justify-content: center;
          }
          &-copyright {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
          }
        }
        &-right {
          position: relative;
          z-index: 2;
          @media (max-width: 768px) {
            align-items: center;
          }
        }
      }
    }
  }
  width: 100%;
  height: 100vh;
  background-image: url("../../../assets/images/contactbook-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  min-height: 768px;
  @media (max-width: 768px) {
    padding: 6.25rem 1rem;
  }
  &-split {
    display: flex;
    align-items: center;
    height: 100%;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 0.5rem 0 0.5rem 1.5rem;
      @media (max-width: 768px) {
        padding: 0;
      }
      &-logo {
        display: flex;
        align-items: center;
      }
      &-copyright {
        padding-right: 48px;
        font-size: 12px;
        color: @text-dark-theme;
      }
      &-onboardImage {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4.25rem 0 4.25rem 3.5rem;
        margin-right: -16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          min-height: 488px;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: 12px;
      background-color: @white-color;
      height: 100%;
      padding: 6.25rem 4rem;
      @media (max-width: 768px) {
        padding: 1rem;
        height: min-content;
      }
      .card-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
    .padding-ltd-plan {
      padding: 3rem 1rem;
    }
  }
  .ant-form-item-label > label {
    color: @content-text-color;
    font-size: @font-size-sm;
  }
  .ant-input::placeholder {
    font-size: @font-size-sm;
  }
  .ant-typography {
    color: @content-text-color;
  }
  .dark-text {
    color: @primary-color;
  }
  .app-icon {
    margin-right: 8px;
  }
  .need-help-text {
    cursor: pointer;
  }
  .email-underline {
    color: @brand-color;
    text-decoration: underline;
  }
}

.auth-or-divider {
  margin: 24px 0;
  @media (max-width: 480px) {
    margin: 16px 0;
  }
  text-align: center;
  color: @content-text-color;
  font-size: @font-size-sm;
}
.social-auth-buttons {
  display: flex;
  flex-direction: column;
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 360px;
    border-radius: 2px;
    padding: 0;
    @media (max-width: 413px) {
      max-width: 310px;
    }
    @media (max-width: 360px) {
      max-width: 250px;
    }
    margin-bottom: 12px;
    max-height: 40px;
    height: auto;
  }
  &-img {
    height: inherit;
    width: inherit;
    @media (max-width: 413px) {
      width: inherit;
    }
    @media (max-width: 360px) {
      width: inherit;
    }
  }
  &-email {
    background-color: @white;
    border: @stroke-color;
    color: @content-text-color;
  }
  &-email:hover {
    background-color: @white;
    border: @stroke-color;
    color: @content-text-color;
  }
  &-email:focus {
    background-color: @white;
    border: @stroke-color;
    color: @content-text-color;
  }
}

.align-text-center {
  text-align: center;
}
.margin-top {
  margin-top: 60px;
}
.typography-margin-bottom {
  margin-bottom: 32px;
  margin-top: 12px;
  &-terms {
    font-size: @font-size-sm;
  }
}
.auth-title {
  margin-bottom: 12px;
}
.auth-terms {
  font-size: @font-size-sm;
}

label {
  width: 100%;
}

.back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  &-text {
    margin-left: 4px;
    color: @content-text-color;
  }
}

.divider {
  .ant-divider.horizontal {
    margin: 32px 0px;
  }
}

.space-y {
  margin: 8px auto;
}
