.cms-add-member-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  &-block {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    justify-content: center;
    max-width: 532px;
    width: 100%;
    align-self: center;
  }
  .cms-add-member-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 0.75rem;
    .cms-add-member-title {
      font-size: @h4-font-size;
      color: @content-text-color;
      font-weight: 500;
      line-height: 26px;
      &-skeleton {
        width: 20%;
      }
    }
    .cms-member-count-section {
      display: flex;
      align-items: center;
      .cms-member-icon {
        padding-right: 0.3rem;
      }
    }
  }
  .cms-plan-upgrade-block {
    height: 124px;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 1.688rem;
    padding-top: 1rem;
    padding-left: 1rem;
    position: relative;
    &-content {
      .cms-plan-upgrade-text {
        display: flex;
        align-items: center;
        font-size: @h5-font-size;
        line-height: 24px;
        color: @primary-color;
      }
      .cms-plan-access-info {
        font-size: @h6-font-size;
        color: @content-text-color;
      }
      .cms-plan-upgrade-btn-wrapper {
        padding-top: 1rem;
        .cms-plan-upgrade-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem 1rem;
          background: @primary-dark;
          border-radius: 300px;
          color: @white-color;
          span {
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
  .cms-plan-upgrade-block:after {
    content: "";
    position: absolute;
    border-right: 1px solid @stroke-color;
    width: 14px;
    height: 10px;
    left: 80%;
    top: -10px;
    background: @white-color;
    transform: skewx(42deg);
  }
  .cms-plan-upgrade-block:before {
    content: "";
    position: absolute;
    border-left: 1px solid @stroke-color;
    width: 0px;
    height: 10px;
    left: 80%;
    top: -10px;
    background: @white-color;
    transform: skewx(-42deg);
    z-index: 1;
    margin-left: 5px;
  }
  .cms-no-plan {
    background: @background-color;
  }
  .cms-no-member-section {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 148px);
    justify-content: flex-end;
    .cms-placeholder-wrapper {
      padding-bottom: 10rem;
    }
    .cms-no-member-image {
      height: 177px;
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 50%),
        linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 50%),
        linear-gradient(180deg, #ffffff 7.81%, rgba(255, 255, 255, 0) 329.33%),
        url("/assets/svg/common/Group.png");
    }
  }
}
.cms-add-member-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  .cms-email-block {
    position: relative;
    width: 100%;
    z-index: 5;
    margin-right: 12px;
  }
  .ant-input {
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-group-addon {
    border: 0;
  }
  &-input {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0.5rem 1.25rem;
    width: 100%;
    height: 40px;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 200px;
    position: relative;
    @media (max-width: 612px) {
      height: 28px;
      padding: 0.5rem 0.75rem;
    }
    .ant-select-selector {
      left: -12px;
      border: 0 !important;
    }
    &:hover {
      border-color: @brand-color !important;
    }
    &:focus,
    &:active {
      border-color: @brand-color !important;
    }
  }
  .ant-select-selection-item:focus-visible,
  .ant-select.select-after.ant-select-single.ant-select-show-arrow:focus-visible {
    outline: none;
  }
  .cms-select-after {
    position: absolute;
    top: 3px;
    right: 10px;
    @media (max-width: 612px) {
      height: 28px;
      top: -2px;
      right: 2px;
    }
    .ant-select-selector {
      border: 0;
      @media (max-width: 612px) {
        background-color: transparent;
      }
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
  }
  .cms-right-select {
    display: flex;
    justify-content: center;
    margin-left: -0.5rem;
    margin-right: 1rem;
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: transparent;
    }
  }
  .cms-add-btn {
    display: flex;
    justify-content: flex-end;
    .cms-add-email-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 76px;
      max-height: 40px;
      background: @brand-color;
      border-radius: 200px;
      flex: none;
      color: @white-color;
      padding: 0.25rem 1.5rem;
      border: 0;
      .group-share-user {
        padding-right: 0.2rem;
        path {
          fill: @white-color;
        }
      }
      & > span {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: @h5-font-size;
        line-height: 24px;
        text-align: center;
      }
      &:hover {
        background-color: @royal-blue;
      }
    }
    .cms-add-email-btn-disable {
      background-color: @text-dark-theme;
    }
  }
}
.cms-members-activate-state-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: @size-24;
  line-height: 24px;
  color: @brand-color;
}
