.cms-add-ons-list {
  display: flex;
  justify-content: center;
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-listing {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
      background: @white-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 8px;
      margin-bottom: 12px;
      width: inherit;
      max-height: 40px;
      &-icon {
        display: flex;
      }
      &-text {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: @h5-font-size;
        line-height: 24px;
        color: @primary-color;
        margin-left: 8px;
      }
    }
    .cms-active-reminders {
      background: @notify-success-top;
      .cms-add-ons-list-wrapper-listing-icon {
        svg rect {
          fill: @app-svg;
        }
      }
      .cms-add-ons-list-wrapper-listing-text {
        color: @white-color;
      }
    }
    .cms-active-enrichments {
      background: @purple-studio;
      .cms-add-ons-list-wrapper-listing-icon {
        svg rect {
          fill: @purple-studio;
        }
      }
      .cms-add-ons-list-wrapper-listing-text {
        color: @white-color;
      }
    }
    .cms-active-visitor-sign-up {
      background: @endeavour;
      .cms-add-ons-list-wrapper-listing-icon {
        svg rect {
          fill: @endeavour;
        }
      }
      .cms-add-ons-list-wrapper-listing-text {
        color: @white-color;
      }
    }
    .cms-active-digital-tickets {
      background: @notify-error-top;
      .cms-add-ons-list-wrapper-listing-icon {
        svg rect {
          fill: @notify-error-top;
        }
      }
      .cms-add-ons-list-wrapper-listing-text {
        color: @white-color;
      }
    }
  }
}