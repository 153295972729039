.coming-soon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.coming-soon-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-tag {
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: space-between;
    color: @primary-color;
    padding: 8px 16px;
    background: @background-color;
    border: 1px solid @stroke-color;
    margin-bottom: 12px;
    margin-right: 0;
    svg {
      margin-right: 8px;
      path {
        fill: @primary-color;
      }
    }
  }
  &-title {
    font-size: 32px;
    font-weight: 600;
    margin: 12px 0;
  }
  &-description {
    max-width: 416px;
    text-align: center;
  }
  &-button {
    margin-top: 16px;
    background-color: @brand-color;
    &-section {
      background-color: @white-color;
      color: @brand-color;
    }
  }
}
