.cms-contact-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 1.5rem;
  width: 100%;
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    &-span {
      display: flex;
      width: 100%;
      justify-content: center;
      color: @content-text-color;
      font-size: @h3-font-size;
      font-weight: 500;
    }
    &:nth-child(2) {
      justify-content: flex-end;
    }
    .cms-empty-state-dismiss {
      display: flex;
      svg path {
        fill: @content-text-color;
      }
    }
  }
  &-progress {
    display: flex;
    flex-direction: column;
    width: inherit;
    .cms-progress-percent {
      display: flex;
      align-items: center;
      justify-content: center;
      color: @brand-color;
      line-height: 24px;
      font-size: @h4-font-size;
      font-weight: bold;
      margin-bottom: 6px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    margin-top: 2rem;
    padding: 1.5rem 1.25rem;
    &-block {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
      width: 100%;
      max-height: 40px;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      .cms-feature-icon {
        display: flex;
        svg {
          height: 40px;
          width: 40px;
          rect {
            fill: @background-color;
          }
          path {
            fill: @content-text-color;
          }
        }
      }
      .cms-redirect-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        background: @background-color;
        padding: 0.125rem 0.75rem;
        font-size: @size-10;
        border-radius: 200px;
        max-width: 44px;
        width: 100%;
        max-height: 24px;
        &:hover {
          background: @stroke-color;
        }
        svg > path:nth-child(2) {
          fill: @content-text-color;
        }
      }
      .cms-merged-icon {
        svg path {
          fill: @notification-icon
        }
      }
    }
  }
  &-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background: @white-color;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    .cms-card-slider {
      transform-origin: top left;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background: @white-color;
      z-index: 5;
      border-radius: 8px;
      min-height: 182px;
      border: 1px solid @stroke-color;
      transition: all .3s ease-in;
      &-single {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &-left-section {
          display: flex;
          align-items: flex-start;
          &-icon svg {
            height: 32px;
            width: 32px;
          }
          &-title {
            font-size: @h3-font-size;
          }
          .cms-card-slider-content {
            font-size: @h5-font-size;
            line-height: 24px;
            color: @content-text-color;
          }
          .cms-redirect-btn {
            background: @brand-color;
            border-radius: 8px;
            border: 0;
            &-text {
              font-size: @h6-font-size;
              line-height: 20px;
              color: @white-color;
            }
            &:hover {
              background: @royal-blue;
            }
          }
        }
        &-right-section {
          svg {
            path {
              fill: @text-dark-theme;
            }
          }
        }
      }
    }
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      border: 1px solid @stroke-color;
      border-radius: 8px;
      z-index: 1;
      right: -8px;
      bottom: -8px;
    }
  }
}
.close-card-effect {
  transform: rotate(-2deg);
  transform-origin: top left;
  opacity: 0;

}