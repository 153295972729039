.cms-onboarding-modal {
  display: flex;
  max-height: 560px;
  max-width: 880px;
  width: 100% !important;
  @media (max-width: 768px) {
    height: auto;
    max-height: 100%;
  }
  .ant-modal-body {
    @media (max-width: 768px) {
      padding: 1rem;
    }
  }
  .cms-boarding-steps {
    display: flex;
    align-items: center;
    flex-direction: column;
    .cms-welcome-page {
      display: flex;
      &-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-subtitle {
          display: flex;
          align-items: center;
          color: @content-text-color;
          font-size: @h2-font-size;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
          justify-content: center;
        }
        .cms-domain-name {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 1rem;
          background: @background-color;
          border-radius: 8px;
          .cms-avatar-wrapper {
            position: relative;
          }
          &-image {
            border: 1px solid @stroke-color;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: @h5-font-size !important;
          }
          &-google-logo {
            position: absolute;
            z-index: 4;
            right: 6px;
            top: 13px;
          }
        }
      }
    }
    .cms-welcome-page-top-title {
      display: flex;
      align-items: center;
      font-size: @size-36;
      line-height: 48px;
      font-weight: bold;
      color: @primary-color;
      white-space: break-spaces;
    }
    .cms-current-steps-title {
      font-size: @size-24;
      line-height: 32px;
    }
    .cms-step-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      &-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .cms-stepper {
      display: flex;
      width: 100%;
      padding: 0 4.75rem;
      @media (max-width: 768px) {
        padding: 0;
        margin-top: 20px;
      }
      .cms-step-default {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:after {
          content: "";
          background-color: @brand-color;
          height: 2px;
          width: 50%;
          position: absolute;
          top: 11px;
          left: 0;
          opacity: 0;
          z-index: 1;
        }
        &:before {
          content: "";
          background-color: @progress-color;
          height: 2px;
          width: 100%;
          position: absolute;
          top: 11px;
          left: 0;
          z-index: 1;
        }
        .cms-step-text {
          display: flex;
          align-items: center;
          text-align: center;
          color: @content-text-color;
          font-size: @h6-font-size;
          font-weight: 500;
        }
        .cms-step-circle {
          background: @white-color;
          border: 1.5px solid @text-dark-theme;
          box-sizing: border-box;
          border-radius: 12px;
          height: 24px;
          width: 24px;
          z-index: 2;
        }
      }
      .cms-step-completed {
        .cms-step-circle {
          display: flex;
          align-items: center;
          justify-content: center;
          background: @brand-color;
          border: 0;
        }
        &:after {
          background-color: @brand-color;
        }
        &:before {
          background-color: @brand-color;
        }
        .cms-step-text {
          color: @brand-color;
        }
      }
      .cms-step-progress {
        position: relative;
        width: 100%;
        .cms-step-circle {
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgb(200 226 255);
          box-sizing: border-box;
          border-radius: 12px;
          height: 24px;
          width: 24px;
          border: 0;
          .cms-inner-circle {
            background-color: @brand-color;
            height: 12px;
            width: 12px;
            border-radius: 12px;
          }
        }
        .cms-step-text {
          font-weight: 600;
          color: @brand-color;
        }
        &:after {
          opacity: 1;
        }
        &:before {
          background-color: @progress-color;
        }
      }
    }
    .cms-step-skip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 12px;
      max-width: 52px;
      width: 100%;
      height: 26px;
      background: @background-color;
      border-radius: 240px;
      position: absolute;
      right: 16px;
      top: 16px;
      @media (max-width: 768px) {
        right: 8px;
        top: 8px;
      }
      &:hover {
        background-color: @stroke-color;
      }
    }
  }
  .cms-welcome-content-google {
    font-size: 16px;
    line-height: 28px;
    color: @content-text-color;
    text-align: center;
  }
  .cms-welcome-content {
    font-size: @h2-font-size;
    line-height: 28px;
    color: @content-text-color;
    text-align: center;
  }
  .mt-53 {
    margin-top: 53px;
  }
  .cms-max-width {
    max-width: 680px;
  }
  .cms-domain-space-google-logo {
    position: absolute;
    z-index: 4;
    right: 0px;
    top: 32px;
  }
  .cms-step-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(560px - 135px);
  }
}
.ant-tooltip {
  max-width: 400px;
  .ant-tooltip-inner {
    .tooltip-text {
      font-size: @h6-font-size;
      line-height: 20px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }
  }
}
.cms-import-help-space {
  display: flex;
  justify-content: flex-end;
  .import-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.75rem 1rem;
    position: absolute;
    max-width: 306px;
    width: 100%;
    max-height: 62px;
    height: 100%;
    bottom: 86px;
    right: 24px;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.28);
    border-radius: 8px;
    z-index: 4;
    &-top {
      display: flex;
      justify-content: space-between;
      width: inherit;
      .cms-progress {
        font-weight: 500;
        line-height: 22px;
      }
      &-title {
        font-size: @h5-font-size;
        color: @primary-color;
      }
      &-count {
        font-size: @h6-font-size;
        color: @content-text-color;
      }
    }
    &-bottom {
      display: flex;
      width: inherit;
      .ant-progress-bg {
        height: 4px !important;
      }
    }
  }
  .cms-help-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 4;
    svg {
      rect:hover {
        fill: @content-text-color;
      }
    }
  }
}
.cms-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.cms-image-wrapper {
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    h2 {
      font-size: 19px;
    }
    .small-svg {
      height: 80px;
      width: 95px;
    }
    .cms-welcome-content-google {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.cms-connect-section {
  justify-content: center;
  width: 100%;
  margin-top: 34px;
  flex-direction: column;
  max-width: 532px;
  .cms-connect-wrapper {
    justify-content: center;
    width: inherit;
    .cms-domain-space-logo {
      border: 0;
    }
    .cms-cb-logo {
      justify-content: center;
      background-color: @background-color;
      border: 0;
      height: 48px;
      width: 48px;
      border-radius: 200px;
      pointer-events: none;
      padding: 0;
      svg {
        height: 24px;
        width: 17.45px;
      }
    }
    .cms-dash-width {
      max-width: 80px;
    }
    .cms-cb-large-logo {
      height: 98px;
      width: 98px;
      svg {
        height: 48px;
        width: 34.91px;
      }
      img {
        height: 48px;
        width: 48px;
      }
    }
  }
  .cms-domain-admin-content {
    align-items: center;
    text-align: center;
    font-size: @h4-font-size;
    line-height: 24px;
    color: @content-text-color;
  }
  .cms-allow-access-btn {
    padding: 0.25rem 0.75rem;
    border-radius: 200px;
    margin-bottom: 34px;
    margin-top: 24px;
  }
  .cms-user-gmail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 532px;
    &-logo {
      height: 16px;
      width: 16px;
    }
    &-text {
      font-weight: 500;
      font-size: @h5-font-size;
      line-height: 24px;
      color: @primary-color;
    }
    &-tag {
      height: 24px;
      color: @primary-color;
      border-radius: 300px;
      padding: 2px 12px;
      font-weight: 500;
      font-size: @h6-font-size;
      line-height: 20px;
    }
  }
  .cms-content-margin {
    margin-bottom: 53px;
  }
}
.cms-contactbook {
  color: @brand-color;
}
.cms-steps-action {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  &-button {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0.5rem 1.313rem;
    border: 0;
    max-height: 40px;
    height: 100%;
    justify-content: center;
    @media (max-width: 375px) {
      width: 100%;
    }
    &-text {
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }
    &:disabled {
      color: @white-color;
      background: @stroke-color;
    }
  }
  &-button-google {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0.5rem 1.313rem;
    max-height: 40px;
    height: 100%;
    justify-content: center;
    @media (max-width: 375px) {
      width: 100%;
    }
  }
}
.cms-progress-loading-wrapper {
  height: 300px;
  justify-content: center;
}

.halloween-modal {
  .ant-modal-content {
    overflow: hidden;
    .ant-modal-close {
      .ant-modal-close-x {
        .anticon-close {
          svg {
            fill: @white-color;
          }
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      img {
        width: 600px;
        height: auto;
      }
    }
  }
}
