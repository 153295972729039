.contact-tabs {
  display: flex;
  width: 100%;
  height: calc(100vh - 381px);
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  border-radius: 8px;
  .ant-tabs {
    width: 100%;
    overflow: unset;
    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: space-around;
      .ant-tabs-nav-list {
        display: contents;
      }
    }
    .cms-tab-social-icons {
      path {
        fill: @text-dark-theme;
      }
      &:hover {
        path {
          fill: @brand-color;
        }
      }
    }
    .cms-tab-icon path {
      fill: @active-icon;
    }
    .cms-tab-social-icons {
      height: 20px;
      width: 20px;
    }
    .ant-tabs-tab-active {
      .cms-tab-social-icons path {
        fill: @active-icon;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
  }
  .twitter-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 476px);
    &-icon {
      margin-bottom: 16px;
      svg {
        height: 25px;
        path {
          fill: @stroke-color;
        }
      }
    }
  }
}
