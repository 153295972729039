.space {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .dark-text {
    color: @primary-color;
  }
}
.create-space-modal {
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  background: @white-color;
  box-sizing: border-box;
  justify-content: center;
  align-self: center;
  width: 100%;
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cms-form-space-field {
      .ant-form-item {
        margin-bottom: 24px;
        width: 360px;
      }
    }
    .ant-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &-btn {
    display: flex;
    justify-content: flex-end;
    .space-next-button {
      border-radius: 12px;
      padding: 0.5rem 1.5rem;
    }
  }
  .cms-space-create-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem;
    background: @white-color;
    border: 1px solid @stroke-color;
    box-sizing: border-box;
    border-radius: 8px;
    &-text {
      display: flex;
      flex-direction: column;
      &-title {
        font-weight: 500;
        color: @primary-color;
        line-height: 24px;
      }
      &-desc {
        line-height: 24px;
        color: @content-text-color;
      }
    }
  }
}
