.ltd-plan-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  .cms-subs-plan-wrapper {
    @media (max-width: 768px) {
      width: 100%;
    }
    margin: auto;
    .cms-subs-plan {
      @media (max-width: 456px) {
        width: 100%;
      }
      width: 300px;
    }
  }
}
