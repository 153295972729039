.cms-reminders {
  display: flex;
  flex-direction: column;
  &-filters {
    display: flex;
    position: absolute;
    left: 0;
    margin-top: -8px;
    border-top: 1px solid @stroke-color;
    width: 100%;
    height: calc(100vh - 116px);
    &-upcoming {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.125rem 0.75rem;
      border-radius: 200px;
      max-height: 24px;
      border: 1px solid @stroke-color;
      margin-right: 4px;
      cursor: pointer;
      &-icon {
        margin-right: 6px;
      }
      &-text {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: @h6-font-size;
        line-height: 20px;
        color: @content-text-color;
      }
    }
    .cms-active-filter-text {
      background: @stroke-color;
      .cms-reminders-filters-upcoming-text {
        font-weight: 500;
      }
    }
    .ant-tabs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: inherit;
      .ant-tabs-nav {
        background: @white-color;
        max-height: 40px;
      }
      .ant-tabs-nav-list {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .ant-tabs-tab {
          flex: 1;
          display: flex;
          justify-content: center;
          .ant-tabs-tab-btn {
            color: @black-color;
          }
          &:hover {
            .ant-tabs-tab-btn {
              color: @brand-color;
            }
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      background: @brand-color;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @brand-color !important;
      font-weight: 600;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 0;
    }
  }
  .no-group {
    padding: 0 0 1.5rem 0;
  }
  &-contact-link {
    display: flex;
    align-items: center;
    border: 0;
    max-height: 32px;
    max-width: 133px;
    width: 100%;
  }
}
.cms-no-reminder-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.5rem 0 15.125rem 0;
}