.all-tag-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  position: absolute;
  width: 100%;
  max-width: 75%;
  height: auto;
  left: 84px;
  top: 39px;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.12);
  border-radius: 8px;
  z-index: 2;
  &-details {
    display: flex;
    flex-direction: column;
    flex: auto;
    width: inherit;
  }
  &-name {
    display: flex;
    padding-bottom: 1rem;
    align-items: center;
    .cms-tag-avatar {
      font-size: @size-10 !important;
      border-radius: 8px;
      height: 24px;
      width: 24px;
    }
    &-tag {
      flex: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
    .all-tag-contact-name {
      padding-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      max-width: 120px;
    }
  }
}
.all-tag-merge-info {
  left: 100px;
  top: 130px;
}