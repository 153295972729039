.cms-tag-menu {
  display: flex;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: @white-color;
  }
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    svg {
      height: 12px;
      width: 12px;
      margin-right: 8px;
      transition: all 0.3s;
    }
    &:hover {
      color: red;
      background-color: @white-color;
      svg path{
        fill: red;
      }
    }
    &-selected {
      background-color: @white-color !important;
    }
  }
}
.cms-tag-deletion-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}