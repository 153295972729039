.cms-contact-source-wrapper {
  display: flex;
  flex-direction: column;
  &-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 532px;
    width: 100%;
    align-self: center;
    padding-top: 1.5rem;
    .cms-contact-source-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 0.75rem;
      .cms-contact-source-title {
        font-size: 16px;
        color: @content-text-color;
        font-weight: 500;
        line-height: 26px;
      }
    }
    .cms-add-contact-source-btn-wrapper {
      display: flex;
      padding-top: 1rem;
      align-self: flex-end;
      padding-bottom: 1.25rem;
      .cms-add-contact-source-btn {
        background: @brand-color;
        border-radius: 8px;
        color: @white-color;
        border: 0;
        &:hover {
          background: @royal-blue;
        }
      }
      .cms-add-contact-sources-btn-disable {
        background: @text-dark-theme;
        &:hover {
          background: @text-dark-theme;
        }
      }
    }
    .cms-plan-upgrade-block {
      display: flex;
      justify-content: space-between;
      height: 76px;
      background: @white-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 1rem;
      align-items: center;
      position: relative;
      .cms-plan-upgrade-content {
        .cms-plan-upgrade-text {
          display: flex;
          align-items: center;
          font-size: @h5-font-size;
          line-height: 24px;
          color: @primary-color;
        }
        .cms-plan-access-info {
          font-size: @h6-font-size;
          color: @content-text-color;
        }
      }
      .contact-source-popover {
        background: red;
      }
    }
    //! Need to work on below css
    .cms-plan-upgrade-block:before {
      content: "";
      position: absolute;
      border-left: 1px solid @stroke-color;
      border-bottom: 1px solid @white-color;
      width: 0px;
      height: 10px;
      left: 80%;
      top: -10px;
      background: @white-color;
      margin-left: 5px;
      z-index: 1;
      transform: skewX(-42deg);
    }
    .cms-plan-upgrade-block:after {
      content: "";
      position: absolute;
      border-right: 1px solid @stroke-color;
      width: 14px;
      height: 10px;
      left: 80%;
      top: -10px;
      background: @white-color;
      transform: skewX(42deg);
    }
  }
}
