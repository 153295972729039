.cms-service-image {
  display: flex;
  flex-direction: column;
  width: inherit;
  align-items: center;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .cms-service-wrapper {
    flex-flow: nowrap;
  }
  .mr-responsive {
    @media (max-width: 768px) {
      margin: 0 !important;
    }
  }
  .ant-radio-button-wrapper-checked {
    border: 2px solid @brand-color !important;
  }
  .import-card-radio-button {
    padding: 20px;
    height: 100px;
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
    }
    border: 1px solid @stroke-color;
    position: relative;
    &:hover {
      border: 2px solid @brand-color;
    }
    &-checked {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }
  .import-card-service-name {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 0;
    .ant-row {
      width: inherit;
    }
  }
  .cms-service-name {
    font-weight: 500;
    line-height: 20px;
    font-size: @h6-font-size;
    color: @content-text-color;
  }
}