.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .cms-signup-btn {
    justify-content: space-between;
    width: 100%;
    max-width: 456px;
    &-auth {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      width: 222px;
      height: 40px;
      background: @black-shade;
      border-radius: 8px;
      height: 100%;
      border: 0;
      &:hover {
        background: @content-text-color !important;
      }
    }

    .cms-microsoft-auth-button {
      background: @black-shade;
    }
    .cms-apple-auth-button {
      background: @black-color;
    }
    .auth-btn-text {
      font-weight: 500;
      font-size: @h6-font-size;
      line-height: 24px;
      color: @white-color;
    }
  }
  .forgot-password-link {
    display: flex;
    justify-content: flex-end;
    max-width: 456px;
    margin: auto;
    padding: 0px 0 6px 0;
  }
  .ant-card-bordered {
    border: 1px solid @stroke-color;
  }
  .ant-form-item-control {
    max-width: 456px;
    margin: auto;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    margin-bottom: unset;
  }
  .ant-form-item {
    margin-bottom: unset;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    width: 456px;
    margin: auto;
  }
  &-content {
    @media (max-width: 1200px) {
      width: 100%;
    }
    width: 456px;
    display: flex;
    margin: auto;
    flex-direction: column;
    &-top-section {
      display: flex;
      flex-direction: column;
      .google-text {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: @primary-color;
      }

      .logo-backdrop {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.688rem 2.063rem;
        width: 64px;
        height: 64px;
        background: @background-color;
        border-radius: 12px;
        .cms-cb-logo {
          background: transparent;
          border: 0;
          pointer-events: none;
          &-img {
            height: 40px;
            width: 29px;
          }
          &-google {
            height: 36px;
            width: 36px;
          }
        }
      }
      .google-sync-link {
        justify-content: center;
        .google-link {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: 0 0.5rem;
          max-width: 120px;
          width: 100%;
          &:before {
            content: "";
            border: 1px dashed @stroke-color;
            width: 100%;
            position: absolute;
            left: 0;
            z-index: 1;
          }
          svg {
            background-color: @white-color;
            z-index: 2;
          }
        }
      }
      .google-brand-btn {
        border: none;
        height: 40px;
        border-radius: 0;
        width: 100%;
        max-width: 456px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #4285f4;
      }
      .google-btn-wrapper {
        .ant-btn {
          padding: 2px;
        }
        svg:not(:root) {
          height: 36px;
          width: 36px;
          position: absolute;
        }
        .ant-btn > span {
          display: inline-block;
          margin: auto;
          font-family: "Roboto", sans-serif;
        }
      }
      .continue-google-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1rem;
        height: 40px;
        width: 100%;
        max-width: 456px;
        &-text {
          font-weight: 500;
          font-size: @h5-font-size;
          line-height: 24px;
          text-align: center;
          color: @white-color;
        }
        &:hover {
          background-color: @royal-blue;
        }
        @media (max-width: 768px) {
          max-width: 456px;
        }
      }
      .cms-upload-section-divider {
        padding: 0;
        margin-top: 36px;
        max-width: 100%;
        .cms-text-border {
          display: flex;
          align-items: center;
          text-align: center;
          font-weight: 600;
          font-size: @h6-font-size;
          line-height: 24px;
          color: @content-text-color;
        }
      }
    }
    &-bottom-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 28px;
      width: 100%;
      .cb-text {
        display: flex;
        align-self: flex-start;
        font-weight: 600;
        font-size: @h3-font-size;
        line-height: 28px;
        color: @primary-color;
        &-book {
          color: @brand-color;
        }
      }
      .cms-cont-input {
        display: flex;
        justify-content: center;
        max-width: 456px;
      }

      .cms-cont-email {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
        width: 100%;
        background: @primary-color;
        border-radius: 8px;
        border: 0;
        height: 40px;
        max-width: 456px;
        &-text {
          display: flex;
          align-items: center;
          text-align: center;
          font-weight: 500;
          font-size: @h5-font-size;
          line-height: 24px;
          color: @white-color;
        }
        &:hover {
          background: @content-text-color;
        }
        @media (max-width: 768px) {
          max-width: 456px;
        }
      }
    }
    .google-content {
      display: flex;
      align-items: center;
      align-self: flex-start;
      font-weight: 400;
      font-size: @h5-font-size;
      line-height: 22px;
      color: @content-text-color;
    }
    @media (max-width: 768px) {
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .login-title {
      font-family: "Inter";
      @media (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 413px) {
      width: 310px;
    }
    @media (max-width: 360px) {
      width: 250px;
    }
  }
}

.label-width {
  width: 100%;
}
