.cms-user-contact-form {
  .ant-form-item {
    margin-bottom: 1rem;
    &-control-input {
      min-height: auto;
    }
  }
  .ant-select-item-option {
    padding: 0.5rem;
  }
  .ant-form-item-label > label {
    font-size: 12px;
    height: 24px;
  }
  .cms-mname {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .cms-form-tags {
    .ant-select-selection-overflow-item .ant-select-selection-item {
      height: 24px;
      margin-top: 2px;
      margin-bottom: 2px;
      padding: 0.1rem 0.2rem 0.1rem 0.75rem;
      .ant-select-selection-item-content {
        .cms-new-tag {
          display: none;
        }
      }
    }
    &-options {
      padding: 0 16px;
    }
  }
  .ant-select-item-option-content {
    display: flex;
    color: #585e75;
  }
  .ant-select-item-option-state {
    opacity: 0;
  }
  .cms-new-tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    height: 20px;
    background: @border-color-theme;
    border-radius: 8px;
    margin: 0px 8px;
    border-color: unset;
    border: 0;
    pointer-events: none;
    cursor: initial;
  }
  .cms-contact-detail {
    width: 100%;
    height: auto;
    background: @white-color;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;

    &-header {
      display: flex;
      align-items: center;
      padding-top: 1rem;
      &-p-icon {
        padding-right: 0.5rem;
      }
    }
    &-form-details {
      padding-top: 1rem;

      .cms-form-align {
        align-items: center;
      }

      .cms-input-dropdown {
        .ant-input-group-addon {
          width: 100px;
        }
      }
      .cms-remove-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: @danger-color;
        cursor: pointer;
      }
      .cms-add-row {
        align-items: center;
        padding: 6px 12px;
        height: 34px;
        border: 1px solid @stroke-color;
        border-radius: 8px;
        margin-top: -0.4rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        &-add-icon {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          font-size: 20px;
        }
      }
      .cms-date-select {
        width: 19%;
      }
      .ant-picker {
        width: 100%;
        &-input {
          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
      .address-select-before {
        height: inherit;
        .ant-select-selector {
          display: flex;
          align-items: center;
          padding-top: 2.4rem;
          padding-bottom: 2rem;
          height: 100%;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .address-input {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
      .city-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .right-radius-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .pt-2 {
      padding-top: 1rem;
    }
    .cms-contact-form-label-wrapper {
      display: flex;
      margin-bottom: 5px;
      .cms-contact-form-label {
        display: block;
        color: @heading-color;
        font-size: @h6-font-size;
      }
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: @h6-font-size;
  }
  .form-mb-10 {
    margin-top: -10px;
  }
  .cms-remove-image-btn {
    position: absolute;
    left: 40px;
    z-index: 2;
    top: 0;
  }
  .ant-input:placeholder-shown,
  .ant-picker-input > input:placeholder-shown {
    font-family: "Inter", sans-serif;
    font-size: @h6-font-size;
    line-height: 20px;
    color: @text-dark-theme;
  }
  .ant-input,
  .ant-input-group-addon {
    border-color: @stroke-color;
    background-color: @white-color;
  }
  .cms-flex-end {
    display: flex;
    justify-content: flex-end;
  }
  .cms-tag-input-icon {
    position: absolute;
    left: 0;
    top: 26px;
    padding: 9px 11px;
    z-index: 4;
    svg {
      height: 14px;
      width: 14px;
    }
    @media screen and (max-width: 575px) {
      top: 34px;
    }
  }
  .ant-select-multiple .ant-select-selector {
    padding-left: 32px;
  }
}
.cms-field-focus {
  border-color: @brand-color !important;
  box-shadow: none !important;
}
