.cms-no-group-tag {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1.75rem;
  width: 100%;
  &-icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0.75rem;
    background: @background-color;
    border-radius: 24px;
    height: 48px;
    width: 48px;
    svg {
      height: 24px;
      width: 24px;
    }
  }
  &-text {
    color: @content-text-color;
    line-height: 22px;
  }
}
.no-group-tag-height {
  height: calc(100vh - 240px);
}