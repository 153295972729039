.settings-nav {
  display: flex;
  height: calc(100vh - 1px);
  overflow: auto;
  flex-direction: column;
  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.25rem 1.25rem 1.25rem 0.75rem;
    border-bottom: 1px solid @stroke-color;
    max-height: 60px;
    &-icon {
      margin-right: 6px;
      display: flex;
      align-items: center;
      svg {
        path {
          fill: @primary-color;
        }
      }
    }
    &-text {
      color: @primary-color;
      font-size: @font-size-lg;
      font-weight: @font-weight-medium;
    }
  }
  &-space-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 55px);
    overflow-x: hidden;
    padding: 12px;
    .setting-menu-list-wrapper {
      .ant-list-items {
        .ant-list-item {
          padding: 0 0 1rem 0;
          border-bottom: 0;
          &-meta {
            &-avatar {
              margin-right: 10.25px;
            }
            &-content {
              .ant-list-item-meta-title {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
