.cms-warning-msg-wrapper {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  background: @bg-warning-yellow;
  border: 1px solid @warning-border-btn-yellow;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
  &-content {
    display: flex;
    align-items: center;
    font-size: @h5-font-size;
    line-height: 24px;
    color: @content-text-color;
  }
}