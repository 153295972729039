.cms-docs {
  display: flex;
  padding-top: 1rem;
  align-items: baseline;
  justify-content: space-between;
  .cms-doc-list {
    display: flex;
    align-items: center;
    padding-bottom: 1.125rem;
    .cms-doc-name-info {
      display: flex;
      z-index: 1;
      .ant-input-group-addon {
        background-color: @background-color;
      }
      .cms-doc-name {
        font-size: @h6-font-size;
        margin: 0 0.4rem 0 0.8rem;
      }
      .cms-edit-doc-name {
        cursor: pointer;
      }
    }
    .cms-name-separator {
      width: 4px;
      height: 4px;
      background: @text-dark-theme;
      border-radius: 8px;
      margin: 0 0.6rem;
    }
    .cms-doc-username {
      font-size: @size-10;
      color: @content-text-color;
    }
  }
}
.cms-docs-border {
  border-bottom: 1px solid @stroke-color;
}
.cms-document-list-action {
  display: flex;
  justify-content: flex-end;
  .cms-doc-download-icon {
    padding: 0 1rem;
    &:hover {
      svg path {
        fill: @brand-color;
      }
    }
  }
  .cms-doc-delete-icon {
    svg path {
      fill: @text-dark-theme;
    }
    &:hover {
      svg path {
        fill: @danger-color;
      }
    }
  }
  .cms-doc-external-icon {
    &:hover {
      svg path {
        fill: @brand-color;
      }
    }
  }
}
.cms-pointer {
  cursor: pointer;
  z-index: 1
}
.cms-disable-edit {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}