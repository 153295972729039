.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../assets/images/onboard-bg.png");
  @media (max-width: 768px) {
    background-image: none;
  }
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  background-color: @white-color;
  background-size: contain;
  @media (max-width: 768px) {
    background-color: @background-color;
    padding: 16px;
  }
  padding-bottom: 30px;
  &-layout {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(~"100vh - 72px");
    position: relative;
    z-index: 4;
    &-logo {
      position: relative;
      z-index: 4;
    }
    @media (max-width: 768px) {
      padding-top: 0;
      height: auto;
    }
  }
}
.onboarding:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .3);
  top: 0;
  left: 0;
  z-index: 2;
}
