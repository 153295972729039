.activity-log-tab {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 1rem;
  border-radius: 8px;
  max-width: 100%;
  justify-content: center;
  .activity-log-wrapper {
    display: flex;
    justify-content: space-between;
    .cms-activity-log-dropdown {
      svg {
        fill: @content-text-color;
      }
    }
  }
  .activity-log-content {
    display: flex;
    align-items: center;
    padding-bottom: 0.625rem;
    flex-wrap: wrap;
    &-name {
      display: flex;
      align-items: center;
      background: @white-color;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: @h5-font-size;
      padding: 0.125rem 0.375rem;
      .cms-activity-log-avatar {
        height: 18px;
        width: 18px;
        border-radius: 100px;
        font-size: @size-10;
        margin-right: 0.25rem;
      }
    }
    &-note {
      display: flex;
      align-items: center;
      background: @background-color;
      border-radius: 4px;
      border: 0;
      padding: 0.125rem 0.375rem;
      margin-right: 6px;
      pointer-events: none;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        width: auto;
      }
      .cms-activity-user-name {
        font-size: @h5-font-size;
        color: @content-text-color;
        margin-left: 6px;
      }
      &-icon {
        height: 13.5px;
        width: 13.5px;
      }
    }
    .cms-text-space {
      margin-right: 3px;
    }
    .cms-mLeft {
      margin-left: 3px;
    }
    .cms-avatar-mLeft {
      margin-left: 6px;
    }
  }
  .cms-activity-log-time {
    display: flex;
    font-size: 10px;
    color: @content-text-color;
    padding-bottom: 1rem;
  }
  .ant-divider-horizontal {
    margin: 0;
  }
  .ant-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.75rem;
    box-sizing: border-box;
    box-shadow: rgb(88 94 117 / 14%) 0px 16px 26px;
    border-radius: 8px;
  }
  .cms-activity-log-divider {
    border-top: 1px solid @stroke-color;
  }
}
.cms-log-field {
  display: flex;
  align-items: center;
  .cms-log-field-name {
    padding-right: 0.5rem;
    &-multiple {
      display: flex;
      flex-direction: column;
      max-width: 200px;
      width: 100%;
      &-value {
        margin-bottom: 8px;
      }
    }
  }
  .cms-log-field-value {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0.125rem 0.325rem;
    height: 28px;
    background: @background-color;
    border-radius: 4px;
    color: @primary-color;
  }
}
