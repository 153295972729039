.duplicate-card {
  transition: all 0.3s ease-in;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid @grey-4;
    &-left-section {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: @h6-font-size;
      line-height: 20px;
      color: @content-text-color;
    }
    &-right-section {
      display: flex;
      &-ignore-btn {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: @h6-font-size;
        line-height: 20px;
        text-align: right;
        color: @content-text-color;
        margin-right: 12px;
        cursor: pointer;
        &:hover {
          color: @primary-color;
        }
      }
      &-merge-btn {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: @h6-font-size;
        line-height: 20px;
        text-align: right;
        color: @brand-color;
        cursor: pointer;
        &-icon {
          margin-left: 8px;
        }
        &:hover {
          color: @royal-blue;
          svg path {
            fill: @royal-blue;
          }
        }
      }
    }
  }
}

.duplicate-contact-modal-wrapper {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
      .cms-duplicate-contact-modal-wrapper {
        display: flex;
        flex-direction: column;
        &-upper-section {
          display: flex;
          background: @background-color;
          border: 1px solid @stroke-color;
          border-radius: 8px 8px 0 0;
          box-sizing: border-box;
          width: 100%;
          height: 166px;
          padding: 1rem;
          &-contact-info {
            display: flex;
            flex-direction: column;
            width: 100%;
            .duplicate-contact-name {
              display: flex;
              align-items: center;
              margin-bottom: 22px;
              &-detail {
                display: flex;
                flex-direction: column;
                margin-left: 12px;
                &-username {
                  display: flex;
                  font-weight: 500;
                  font-size: @h4-font-size;
                  line-height: 26px;
                  align-items: center;
                  color: @primary-color;
                }
                &-post {
                  display: flex;
                  align-items: center;
                  font-size: 12px;
                  line-height: 20px;
                  color: @content-text-color;
                }
              }
            }
            .duplicate-contact-group-tag {
              display: flex;
              flex-direction: column;
              &-list-tag {
                display: flex;
              }
              &-list-group {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .group-tag-pt {
                  padding-top: 0.5rem;
                }
                .document-notes-section {
                  display: flex;
                  justify-content: flex-end;
                  width: 100%;
                  &-doc {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0.25rem 0.5rem;
                    width: 46px;
                    height: 24px;
                    background: @white-color;
                    border-radius: 8px;
                    svg path {
                      fill: @content-text-color;
                    }
                    .attach-icon {
                      height: 16px;
                      width: 16px;
                    }
                  }
                  .mr-8 {
                    margin-right: 8px;
                  }
                  &-center {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
        &-lower-section {
          display: flex;
          flex-direction: column;
          padding: 1.313rem 1.313rem 0.75rem 1.313rem;
        }
      }
    }
  }
}

.fade-out {
  transform: translate(0px, -10px);
  opacity: 0;
}