.cms-no-access {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  .no-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      background: linear-gradient(
        218.52deg,
        @background-color -10.29%,
        @white-color 103.98%
      );
      box-shadow: 0px 22.3396px 36.3019px rgba(88, 94, 117, 0.14);
      border-radius: 200px;
    }
    &-text {
      color: @text-dark-theme;
      font-size: 16px;
      padding-top: 1.5rem;
      text-align: center;
      width: 205px;
    }
    &-btn-wrapper {
      display: flex;
      justify-content: center;
      padding: 2rem 3rem;
    }
    &-create-access-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.4rem 1rem;
      background: @brand-color;
      border-radius: 8px;
      color: @white-color;
      &:hover {
        background-color: @white-color;
        color: @brand-color;
      }
      .no-group-svg {
        fill: @white-color;
        margin-right: 0.5rem;
      }
    }
  }
}
.cms-space-lock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  &-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    width: 450px;
    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      background: linear-gradient(
        218.52deg,
        @background-color -10.29%,
        @white-color 103.98%
      );
      box-shadow: 0px 22.3396px 36.3019px rgba(88, 94, 117, 0.14);
      border-radius: 200px;
    }
    .no-group-btn-wrapper {
      text-align: center;
      font-size: @h5-font-size;
      color: @content-text-color;
      align-self: stretch;
      padding: 0.5rem 0 1.25rem 0;
    }
    .cms-page-lock-note {
      padding: 0.5rem 0;
    }
    .no-subscription-msg {
      color: @content-text-color;
      font-weight: 500;
    }
    .cms-space-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      align-items: center;
      padding: 0.25rem 0.5rem;
      border: 1px solid @stroke-color;
      box-sizing: border-box;
      border-radius: 8px;
      .cms-space-avatar {
        border: 1px solid @stroke-color;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 1px 5px;
        margin-right: 8px;
        flex-shrink: 0;
        span {
          font-size: @size-10;
        }
      }
      .cms-space-name {
        display: flex;
        align-items: center;
        color: @content-text-color;
        font-weight: bold;
      }
    }
  }
}
