.cms-contact-loader {
  display: flex;
  height: calc(100vh - 124px);
  justify-content: center;
  flex-direction: column;
}
.cms-multiple-delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0.75rem;
  background: @white-color;
  border: 1px solid @stroke-color;
  height: 52px;
  @media (max-width: 1024px) {
    display: none;
  }
  .divider-vertical {
    margin-right: unset;
  }
  .ant-space-item {
    display: flex;
  }
  .ant-dropdown-open {
    background-color: @background-color;
    border-radius: 240px;
    width: 25px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .cms-select-checkbox {
    .ant-checkbox {
      &-inner {
        height: 13.5px;
        width: 13.5px;
      }
    }
    .ant-checkbox.ant-checkbox-checked {
      border: 1px solid @brand-color;
      .ant-checkbox-inner {
        background: @brand-color;
        border: 0;
      }
    }
    .ant-checkbox-checked::after {
      border: 0;
      border-radius: unset;
    }
  }
  .select-text {
    font-size: @h5-font-size;
    color: @content-text-color;
  }
  &-section-delete {
    &-div {
      display: flex;
      justify-content: center;
      svg {
        path {
          fill: @danger-color;
        }
      }
      .delete-text {
        color: @danger-color;
      }
    }
    .delete-text {
      font-weight: 500;
      color: @danger-color;
    }
  }
}
