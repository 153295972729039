.add-contact-group-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  position: absolute;
  height: auto;
  top: 36px;
  right: 0;
  z-index: 2;
  background: @white-color;
  border: 1px solid @stroke-color;
  box-sizing: border-box;
  box-shadow: 0px 12px 30px rgba(88, 94, 117, 0.12);
  border-radius: 8px;
  align-items: flex-start;
  &-contact {
    display: flex;
    flex: auto;
    padding-bottom: 10px;
    cursor: pointer;
    svg {
      path:not(:first-child) {
        fill: @content-text-color;
      }
    }
  }
  &-contact:hover {
    display: flex;
    flex: auto;
    padding-bottom: 10px;
    cursor: pointer;
    color: @primary-color;
    svg {
      path:not(:first-child) {
        fill: @primary-color;
      }
    }
  }
  &-group {
    display: flex;
    cursor: pointer;
    svg {
      path:not(:first-child) {
        fill: @content-text-color;
      }
    }
  }
  &-group:hover {
    display: flex;
    flex: auto;
    cursor: pointer;
    color: @primary-color;
    svg {
      path:not(:first-child) {
        fill: @primary-color;
      }
    }
  }
  &-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
}
