.space-list-wrapper {
  .ant-list-items {
    .ant-list-item {
      border-radius: 4px;
      padding: 0.75rem @major-padding 0.5rem;
      border-bottom: none;
      cursor: pointer;
      &:hover {
        .ant-list-item-meta-title {
          color: @primary-color;
        }
      }
      &-meta {
        align-items: flex-start;
        &-content {
          display: flex;
          justify-content: space-between;
          .cms-group-title {
            display: flex;
          }
          .ant-list-item-meta-title {
            color: @content-text-color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .selected-list {
      .ant-list-item-meta {
        &-avatar {
          svg {
            path {
              fill: @brand-color;
            }
          }
        }
        &-content {
          .ant-list-item-meta-title {
            color: @brand-color;
            font-weight: 700;
          }
        }
        &:hover {
          .ant-list-item-meta-title {
            color: @brand-color;
          }
        }
      }
    }
  }
  &-avatar {
    border-radius: 2px;
    height: 18px;
    width: 18px;
    font-size: @size-10;
    font-weight: 600;
  }
}
