.cms-payment-container {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .sigup-form {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 456px;
  max-width: 100%;
  .cms-address-input {
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: calc(50% - 8px);
    }
  }
  .cms-form-groups {
    @media (max-width: 768px) {
      flex-direction: column;
    }
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .payment-container-content-top-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 28px;
    width: 100%;
    .cb-text {
      display: flex;
      align-self: flex-start;
      font-weight: 600;
      font-size: @h3-font-size;
      line-height: 28px;
      color: @primary-color;
    }
    .google-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .location-icon {
      fill: red;
    }
  }
  .cms-email-login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background: @primary-dark;
    border-radius: 8px;
    border: 0;
    width: 100%;
    &:hover {
      background: @content-text-color;
    }
    &-text {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: @h5-font-size;
      line-height: 24px;
      color: @white-color;
    }
  }
  .cms-login-title {
    font-weight: 600;
    font-size: @h3-font-size;
    line-height: 28px;
    color: @primary-color;
  }
  .cms-no-account {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: @h5-font-size;
    line-height: 24px;
    color: @content-text-color;
    margin-top: 12px;
  }
}
