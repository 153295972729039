.incorrect-data-list-wrapper {
  .ant-tag {
    border-radius: 0px;
    border: none;
    background-color: @background-color;
    padding: 4px 8px;
  }
  .issues-icon {
    margin-right: 4px;
  }
}
